<div class="alert alert-info" role="alert" translate *ngIf="tooManyResults">
  message.searchresulttoolarge
</div>

<div class="card mb-5">
  <div class="card-body">
    <h5 class="mb-4" translate>label.fahrzeugdaten</h5>
    <hr />
    <div class="row">
      <div class="col-12 col-md-6 row">
        <label for="markeTyp" class="col-5 col-lg-4" translate="label.marketyp"></label>
        <label id="markeTyp" class="col-7 col-lg-8 font-weight-bold">{{
          result?.fahrzeugForDisplay?.markeTyp
        }}</label>
      </div>
      <div class="col-12 col-md-6 row">
        <label for="stammnummer" class="col-5 col-lg-4" translate="label.stammnummer"></label>
        <label id="stammnummer" class="col-7 col-lg-8 font-weight-bold">{{
          result?.fahrzeugForDisplay?.stammnummer
        }}</label>
      </div>
      <div class="col-12 col-md-6 row">
        <label for="fahrzeugart" class="col-5 col-lg-4" translate="label.fahrzeugart"></label>
        <label id="fahrzeugart" class="col-7 col-lg-8 font-weight-bold">{{
          result?.fahrzeugForDisplay?.fzArt | translateFahrzeugart
        }}</label>
      </div>
      <div class="col-12 col-md-6 row">
        <label
          for="fahrgestellnummer"
          class="col-5 col-lg-4"
          translate="label.fahrgestellnummer"></label>
        <label id="fahrgestellnummer" class="col-7 col-lg-8 font-weight-bold">{{
          result?.fahrzeugForDisplay?.fahrgestellnummer
        }}</label>
      </div>
    </div>
  </div>
</div>

<h4 class="mb-4">
  {{ (sucheForSchaden ? 'label.gesuchter.schaden' : 'label.gesuchtes.fahrzeug') | translate }}
</h4>
<div class="table-responsive">
  <table class="table table-bordered table-sm table-striped">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th sortable="schadenNummer" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadennummer</span>
        </th>
        <th sortable="schadenDatum" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadendatum</span>
        </th>
        <th sortable="versicherung" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.gesellschaft</span>
        </th>
        <th sortable="schadenart" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadenart</span>
        </th>
        <th sortable="regulierung" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.regulierung</span>
        </th>
        <th sortable="schadensumme" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadensumme</span>
        </th>
        <th sortable="fahrzeugRolle" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.fzrolle</span>
        </th>
        <th sortable="land" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.land</span>
        </th>
        <th sortable="kennzeichen" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.kennzeichen</span>
        </th>
        <th sortable="kmstand" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.kilometerstand</span>
        </th>
        <th sortable="score" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.score</span>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let row of rows; index as i">
        <td class="text-nowrap">
          <i class="fa fa-edit cursor-pointer" (click)="displaySchadenDetails(i)"></i>
        </td>
        <td class="text-nowrap">{{ row.schadenmeldung.schadenNummer }}</td>
        <td class="text-nowrap">{{ row.schadenmeldung.schadenDatum | date: 'dd.MM.yyyy' }}</td>
        <td class="text-nowrap">{{ row.schadenmeldung.versicherung?.name }}</td>
        <td class="text-nowrap">{{ row.schadenmeldung.schadenart | translateSchadenart }}</td>
        <td class="text-nowrap">{{ row.gesuchtesFahrzeug?.regulierung | translateRegulierung }}</td>
        <td class="text-nowrap">{{ row.gesuchtesFahrzeug?.schadensumme }}</td>
        <td class="text-nowrap">{{ row.fahrzeugRolle }}</td>
        <td class="text-nowrap">{{ row.gesuchtesFahrzeug?.land | translateLand }}</td>
        <td class="text-nowrap">{{ row.gesuchtesFahrzeug?.kennzeichen }}</td>
        <td class="text-nowrap">{{ row.gesuchtesFahrzeug?.kmstand }}</td>
        <td class="text-nowrap">{{ row.schadenmeldung.score }}</td>
      </tr>
    </tbody>
  </table>
</div>
