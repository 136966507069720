import { Component, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router, RouterOutlet } from '@angular/router';
import { ConfirmModalComponent } from './core/confirm-modal/confirm-modal.component';
import { ConfirmTemplateDirective } from './core/confirm-modal/confirm-template.directive';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { AuthorizationService } from './core/authentication/authorization.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { BenutzerService } from './core/benutzer/benutzer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    ConfirmTemplateDirective,
    ConfirmModalComponent,
    AsyncPipe,
    NgIf,
    TranslateModule,
  ],
})
export class AppComponent implements OnInit {
  title = 'ccinfo-frontend';
  languages: Map<string, string> = new Map([
    ['de', 'Deutsch'],
    ['fr', 'Französisch'],
  ]);
  authorized = false;
  initialized = false;

  constructor(
    private translate: TranslateService,
    private readonly benutzerService: BenutzerService,
    private readonly authzService: AuthorizationService,
    router: Router,
  ) {
    this.benutzerService.benutzer$.subscribe((benutzer) => {
      this.authorized = benutzer != null && benutzer.rollen.length > 0;
      this.initialized = benutzer != null;
    });
    this.authzService.authority$.subscribe((rolle) => {
      if (rolle == null && location.search.search('rollenauswahl') === -1) {
        router.navigate(['rollenauswahl'], {
          queryParams: { rollenauswahl: 'true', redirectUri: router.url },
        });
      }
    });
  }

  public ngOnInit(): void {
    this.initTranslateService();
  }

  private initTranslateService() {
    this.translate.setDefaultLang('de');
    const language = this.getLanguageToUse();
    this.translate.use(language);
    this.languages.forEach((value, key) => {
      return this.translate.setTranslation(
        key,
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require(`../i18n/locale-${key}.json`) as NodeRequire,
      );
    });
  }

  private getLanguageToUse() {
    const browserLanguage = this.translate.getBrowserLang();
    if (!browserLanguage) {
      return 'de';
    }
    return browserLanguage;
  }
}
