<div class="card mb-5">
  <div class="card-body">
    <h5 class="mb-4" translate>label.fahrzeugdaten</h5>
    <hr />
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <label for="stammnummer" class="col-5 col-lg-4" translate="label.stammnummer"></label>
          <label id="stammnummer" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.stammnummer
          }}</label>
        </div>

        <div class="row">
          <label
            for="fahrgestellnummer"
            class="col-5 col-lg-4"
            translate="label.fahrgestellnummer"></label>
          <label id="fahrgestellnummer" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.fahrgestellnummer
          }}</label>
        </div>

        <div class="row">
          <label for="markeTyp" class="col-5 col-lg-4" translate="label.marketyp"></label>
          <label id="markeTyp" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.markeTyp
          }}</label>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="row">
          <label for="fahrzeugart" class="col-5 col-lg-4" translate="label.fahrzeugart"></label>
          <label id="fahrzeugart" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.fzArt | translateFahrzeugart
          }}</label>
        </div>

        <div class="row">
          <label for="erstIvDatum" class="col-5 col-lg-4" translate="label.erstivdatum"></label>
          <label id="erstIvDatum" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.erstIVDatum | date: 'dd.MM.yyyy'
          }}</label>
        </div>

        <div class="row">
          <label class="col-5 col-lg-4" translate="label.landkennzeichen"></label>
          <div class="col-7 col-lg-8 font-weight-bold">
            <label>{{ fahrzeug?.land | translateLand }}</label>
            <label>&nbsp;/&nbsp;</label>
            <label>{{ fahrzeug?.kennzeichen }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<h4 class="mb-4" translate>label.schadenhistory</h4>
<div class="table-responsive">
  <table class="table table-bordered table-sm table-striped">
    <thead>
      <tr>
        <th class="align-top">
          <span class="text-nowrap" sortable="schadenDatum" (sort)="onSort($event)" translate
            >label.schadenDatum</span
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="dateFilter.schadenDatum.value"
            name="schadenDatumFilter"
            (ngModelChange)="onSearch()" />
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="versicherung" (sort)="onSort($event)" translate
            >label.vgName</span
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="tableFilter.versicherung"
            name="versicherungFilter"
            (ngModelChange)="onSearch()" />
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="schadenNummer" (sort)="onSort($event)" translate
            >label.schadenNr</span
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="tableFilter.schadenNummer"
            name="schadenNummerFilter"
            (ngModelChange)="onSearch()" />
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="schadenArt" (sort)="onSort($event)" translate
            >label.schadenArt</span
          >
          <select
            name="schadenartFilter"
            class="form-control"
            [(ngModel)]="tableFilter.schadenArt"
            (change)="onSearch()">
            <option [ngValue]="undefined"></option>
            <option *ngFor="let schadenart of alleSchadenarten" [ngValue]="schadenart">
              {{ schadenart | translateSchadenart }}
            </option>
          </select>
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="schadenHoeheFz" (sort)="onSort($event)" translate
            >label.schadenHoeheFz</span
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="tableFilter.schadenHoeheFz"
            name="schadenHoeheFzFilter"
            (ngModelChange)="onSearch()" />
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="gutachten" (sort)="onSort($event)" translate
            >label.gutachten</span
          >
          <select
            name="gutachtenFilter"
            class="form-control"
            [(ngModel)]="tableFilter.gutachten"
            (change)="onSearch()">
            <option [ngValue]="undefined"></option>
            <option [ngValue]="1">
              {{ 1 | translateGutachten }}
            </option>
            <option [ngValue]="0">
              {{ 0 | translateGutachten }}
            </option>
          </select>
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="regulierung" (sort)="onSort($event)" translate
            >label.regulierung</span
          >
          <select
            name="regulierungFilter"
            class="form-control"
            [(ngModel)]="tableFilter.regulierung"
            (change)="onSearch()">
            <option [ngValue]="undefined"></option>
            <option *ngFor="let regulierung of alleRegulierungen" [ngValue]="regulierung">
              {{ regulierung | translateRegulierung }}
            </option>
          </select>
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="kmStand" (sort)="onSort($event)" translate
            >label.kmstand</span
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="tableFilter.kmStand"
            name="kmStandFilter"
            (ngModelChange)="onSearch()" />
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="deckungsArt" (sort)="onSort($event)" translate
            >label.deckungsart</span
          >
          <select
            name="deckungsArtFilter"
            class="form-control"
            [(ngModel)]="tableFilter.deckungsArt"
            (change)="onSearch()">
            <option [ngValue]="undefined"></option>
            <option *ngFor="let deckungsArt of alleDeckungsarten" [ngValue]="deckungsArt">
              {{ deckungsArt | translateDeckungsart }}
            </option>
          </select>
        </th>
        <th class="align-top">
          <span class="text-nowrap" sortable="scored" (sort)="onSort($event)" translate
            >label.scored</span
          >
          <select
            name="scoredFilter"
            class="form-control"
            [(ngModel)]="tableFilter.scored"
            (change)="onSearch()">
            <option [ngValue]="undefined"></option>
            <option [ngValue]="true">
              {{ true | yesNo }}
            </option>
            <option [ngValue]="false">
              {{ false | yesNo }}
            </option>
          </select>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let schadenHistory of history">
        <td class="text-nowrap">{{ schadenHistory.schadenDatum | date: 'dd.MM.yyyy' }}</td>
        <td class="text-nowrap">{{ schadenHistory.versicherung }}</td>
        <td class="text-nowrap">{{ schadenHistory.schadenNummer }}</td>
        <td class="text-nowrap">{{ schadenHistory.schadenArt | translateSchadenart }}</td>
        <td class="text-nowrap">{{ schadenHistory.schadenHoeheFz }}</td>
        <td class="text-nowrap">{{ schadenHistory.gutachten | translateGutachten }}</td>
        <td class="text-nowrap">{{ schadenHistory.regulierung | translateRegulierung }}</td>
        <td class="text-nowrap">{{ schadenHistory.kmStand }}</td>
        <td class="text-nowrap">{{ schadenHistory.deckungsArt | translateDeckungsart }}</td>
        <td class="text-nowrap">{{ schadenHistory.scored | yesNo }}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-12 col-sm-4 col-lg-2">
    <button type="submit" class="btn btn-outline-primary form-control" (click)="back()" translate>
      label.zurueck
    </button>
  </div>
  <div class="col-12 col-sm-8 col-lg-10"></div>
</div>
