/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VersicherungDto } from './versicherungDto';
import { SchadenmeldungDto } from './schadenmeldungDto';


export interface KonversationDto { 
    id?: number;
    letzteNachricht?: string;
    von?: VersicherungDto;
    an?: VersicherungDto;
    schadenmeldung?: SchadenmeldungDto;
    status?: KonversationDto.StatusEnum;
    deletedInDays?: number;
    erledigt?: boolean;
}
export namespace KonversationDto {
    export type StatusEnum = 'NEU' | 'BEARBEITET' | 'OFFEN' | 'ARCHIVIERT';
    export const StatusEnum = {
        NEU: 'NEU' as StatusEnum,
        BEARBEITET: 'BEARBEITET' as StatusEnum,
        OFFEN: 'OFFEN' as StatusEnum,
        ARCHIVIERT: 'ARCHIVIERT' as StatusEnum
    };
}


