import { Component, Input } from '@angular/core';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-full-screen-spinner',
  templateUrl: './fullscreen-spinner.component.html',
  styleUrls: ['./fullscreen-spinner.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass],
})
export class FullscreenSpinnerComponent {
  // For so many milliseconds, the spinner will be transparent
  @Input()
  public transparentForDurationMs = 0;

  public transparent = false;

  private _working = false;
  private timeoutId: any;

  @Input()
  public set working(working: boolean) {
    if (!this._working && working) {
      this.updateTransparency();
    } else if (this._working && !working) {
      this.resetTimeout();
    }

    this._working = working;
  }

  public get working() {
    return this._working;
  }

  private updateTransparency() {
    if (this.transparentForDurationMs > 0) {
      this.transparent = true;
      const that = this;
      // set to non-transparent after the configured duration
      this.timeoutId = setTimeout(() => {
        that.transparent = false;
      }, this.transparentForDurationMs);
    } else {
      this.transparent = false;
    }
  }

  private resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }
}
