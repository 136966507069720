<div class="container-fluid">
  <form>
    <div class="table-responsive">
      <table id="benutzertable" class="table table-bordered table-sm table-striped">
        <thead>
          <tr>
            <th sortable="vorname" (sort)="onSort($event)" translate>tableheader.vorname</th>
            <th sortable="nachname" (sort)="onSort($event)" translate>tableheader.nachname</th>
            <th translate>tableheader.rollen</th>
            <th sortable="identityBroker" (sort)="onSort($event)" translate>
              tableheader.identity.broker
            </th>
            <th sortable="email" (sort)="onSort($event)" translate>tableheader.email</th>
            <th sortable="letztesLogin" (sort)="onSort($event)" translate>
              tableheader.letzterlogin
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let benutzer of benutzerListe">
            <td>{{ benutzer.vorname }}</td>
            <td>{{ benutzer.nachname }}</td>
            <td>
              <span *ngFor="let rolle of benutzer.rollen; let last = last">
                {{ rolle | translateRolle }}{{ last ? '' : ', ' }}
              </span>
            </td>
            <td *ngIf="benutzer.versicherung.authTyp === authTyp.IDP">
              <i class="fa fa-check"></i>
            </td>
            <td *ngIf="benutzer.versicherung.authTyp !== authTyp.IDP">-</td>
            <td>
              <a href="mailto:{{ benutzer.email }}"> {{ benutzer.email }}</a>
            </td>
            <td>{{ benutzer.letztesLogin | date: 'dd.MM.yyyy' }}</td>
            <td class="text-nowrap">
              <a
                [routerLink]="['/benutzer-update', benutzer.id]"
                title="{{ 'label.benutzerdatenmodifizieren' | translate }}"
                class="mr-2 text-dark">
                <i class="fa fa-edit"></i>
              </a>
              <a
                title="{{ 'label.benutzerdatenloeschen' | translate }}"
                (click)="removeBenutzer(benutzer)">
                <i class="far fa-trash-alt"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="col-12 mt-3 d-flex justify-content-end">
        <button type="button" class="btn btn-primary" routerLink="/benutzer-erstellen" translate="">
          {{ 'label.neuenbenutzeranlegen' }}
        </button>
      </div>
    </div>
  </form>
  <app-full-screen-spinner
    [working]="loading"
    [transparentForDurationMs]="50"></app-full-screen-spinner>
</div>
