import { Component, ElementRef, ErrorHandler, OnInit, ViewChild } from '@angular/core';
import { KonversationService } from './konversation.service';
import {
  AttachmentDto,
  KonversationDto,
  NachrichtDto,
  PostboxApiService,
  SchadenmeldungDto,
} from '../../core/api';
import { Router } from '@angular/router';
import { FileSystemFileEntry, NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { concat, Observable, Observer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SchadenDetailNavigationSource } from '../../schaden-detail/schaden-detail-navigation-source';
import { SchadenDetailService } from '../../schaden-detail/schaden-detail.service';
import { PendingChanges } from '../../core/guards/pending-changes.interface';
import { AppConfig } from '../../../app-config';
import { FullscreenSpinnerComponent } from '../../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { NachrichtComponent } from './nachricht/nachricht.component';
import { ScrollToBottomDirective } from '../../core/directives/scroll-to-bottom.directive';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';

@Component({
  selector: 'app-konversation',
  templateUrl: './konversation.component.html',
  styleUrls: ['./konversation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    FormsModule,
    ScrollToBottomDirective,
    NgFor,
    NgClass,
    NachrichtComponent,
    NgxFileDropModule,
    FullscreenSpinnerComponent,
    DatePipe,
  ],
})
export class KonversationComponent implements OnInit, PendingChanges {
  public loading = false;
  private numberOfUploadsInProgress = 0;

  public konversation: KonversationDto;
  public nachrichten: NachrichtDto[];

  public firstNachricht: NachrichtDto;
  public neueNachricht: NachrichtDto = this.emptyNachricht();

  private attachmentsLoading = {};

  private MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024;

  @ViewChild('scrollbereich', { static: true })
  scrollbereich: ElementRef<HTMLElement>;

  constructor(
    private konversationService: KonversationService,
    private router: Router,
    private httpClient: HttpClient,
    private postboxApiService: PostboxApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private errorHandler: ErrorHandler,
    private schadenDetailService: SchadenDetailService,
    private appConfig: AppConfig,
  ) {}

  public ngOnInit(): void {
    this.konversation = this.konversationService.getKonversation();
    this.nachrichten = this.konversationService.getNachrichten();

    if (!this.nachrichten || this.nachrichten.length === 0) {
      this.router.navigate(['/postbox']);
    } else {
      this.firstNachricht = this.nachrichten[0];
    }
  }

  public showSchadenmeldung(schadenmeldung: SchadenmeldungDto) {
    this.schadenDetailService.setSchadenDetails(
      [schadenmeldung],
      SchadenDetailNavigationSource.KONVERSATION,
    );
    this.schadenDetailService.selectSchadenDetails(0);
    this.router.navigate(['/schaden-details']);
  }

  public dropped(files: NgxFileDropEntry[]) {
    const uploads = [];
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        uploads.push(this.uploadFile(droppedFile));
      }
    }

    if (uploads.length > 0) {
      this.loading = true;
      this.numberOfUploadsInProgress = uploads.length;
      concat(...uploads).subscribe(
        () => {
          this.numberOfUploadsInProgress--;
          if (this.numberOfUploadsInProgress === 0) {
            this.loading = false;
          }
        },
        () => {
          this.numberOfUploadsInProgress--;
          if (this.numberOfUploadsInProgress === 0) {
            this.loading = false;
          }
        },
      );
    }
  }

  private uploadFile(uploadedFile: NgxFileDropEntry): Observable<void> {
    return new Observable((observer: Observer<void>) => {
      const fileEntry = uploadedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (file.size > this.MAX_FILE_SIZE_BYTES) {
          // the backend restricts this too, but the response is faster if we restrict it in the frontend too
          this.toastr.error(
            this.translate.instant('message.fileuploadmaxsizeexceeded', {
              0: file.name,
              maxFileSizeBytes: this.bytesToMb(this.MAX_FILE_SIZE_BYTES),
            }),
            undefined,
            {
              extendedTimeOut: 0,
              timeOut: 0,
              enableHtml: true,
              tapToDismiss: true,
              closeButton: true,
            },
          );
          observer.next();
          observer.complete;
          return;
        }

        const formData = new FormData();
        formData.append('file', file, uploadedFile.relativePath);
        formData.append('konversationId', '' + this.konversation.id);
        let nachrichtIdParam = '';
        if (this.neueNachricht.id) {
          nachrichtIdParam = '?nachrichtId=' + this.neueNachricht.id;
        }

        this.httpClient
          .post(
            this.appConfig.backendUrl +
              '/api/postbox/konversationen/' +
              this.konversation.id +
              '/uploadFile' +
              nachrichtIdParam,
            formData,
            {
              withCredentials: true,
            },
          )
          .subscribe(
            (nachricht: NachrichtDto) => {
              nachricht.nachrichtText = this.neueNachricht.nachrichtText;
              this.neueNachricht = nachricht;
              observer.next();
              observer.complete();
            },
            (error) => {
              observer.next();
              observer.complete();
              this.errorHandler.handleError(error);
            },
          );
      });
    });
  }

  public sendNachricht() {
    this.loading = true;
    this.postboxApiService
      .sendNachricht(this.konversation.id, {
        nachrichtText: this.neueNachricht.nachrichtText,
        nachrichtToSendId: this.neueNachricht.id,
      })
      .subscribe(
        (nachricht) => {
          this.nachrichten.push(nachricht);
          this.neueNachricht = this.emptyNachricht();
          this.loading = false;
          setTimeout(() => {
            window.scrollTo(0, window.innerHeight);
            this.scrollbereich.nativeElement.scrollTop =
              this.scrollbereich.nativeElement.scrollHeight -
              this.scrollbereich.nativeElement.clientHeight;
          });
          this.toastr.success(this.translate.instant('message.postboxnachrichterstellt'));
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
  }

  public displayOnTheLeft(nachricht: NachrichtDto): boolean {
    return nachricht.von?.versicherung?.id === this.konversation.von?.id;
  }

  public downloadAttachment(nachricht: NachrichtDto, attachment: AttachmentDto) {
    if (this.attachmentsLoading[attachment.id]) {
      return;
    }

    this.attachmentsLoading[attachment.id] = true;
    this.konversationService
      .downloadAttachment(this.konversation, nachricht, attachment)
      .then(() => {
        this.attachmentsLoading[attachment.id] = false;
      });
  }

  public downloadAllAttachments(nachricht: NachrichtDto) {
    nachricht.attachments.forEach((a) => this.downloadAttachment(nachricht, a));
  }

  public downloadAllAttachmentsOfAllNachrichten() {
    this.nachrichten?.forEach((nachricht) => {
      nachricht.attachments.forEach((a) => this.downloadAttachment(nachricht, a));
    });
  }

  public deleteAttachment(attachmentToDelete: AttachmentDto) {
    this.loading = true;
    this.postboxApiService
      .deleteAttachment(this.konversation.id, this.neueNachricht.id, attachmentToDelete.id)
      .subscribe(
        () => {
          this.neueNachricht.attachments.splice(
            this.neueNachricht.attachments.findIndex((a) => a.id === attachmentToDelete.id),
            1,
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
  }

  public cancel() {
    if (this.neueNachricht.id) {
      this.loading = true;
      this.postboxApiService.deleteNachricht(this.konversation.id, this.neueNachricht.id).subscribe(
        () => {
          this.neueNachricht = this.emptyNachricht();
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
    } else {
      this.neueNachricht = this.emptyNachricht();
    }
  }

  public attachmentLoading(attachment: AttachmentDto) {
    return !!this.attachmentsLoading[attachment.id];
  }

  public getIconForFileName(attachment) {
    let dateiname = attachment.dateiname;
    dateiname = dateiname.toLowerCase();

    if (dateiname.endsWith('.doc') || dateiname.endsWith('.docx')) {
      return 'icon-word.gif';
    } else if (dateiname.endsWith('.xls') || dateiname.endsWith('.xlsx')) {
      return 'icon-excel.gif';
    } else if (dateiname.endsWith('.txt')) {
      return 'icon-text.gif';
    } else if (dateiname.endsWith('.pdf')) {
      return 'icon-pdf.gif';
    } else if (
      dateiname.endsWith('.gif') ||
      dateiname.endsWith('.jpg') ||
      dateiname.endsWith('.jpeg') ||
      dateiname.endsWith('.png') ||
      dateiname.endsWith('.tiff') ||
      dateiname.endsWith('.psd') ||
      dateiname.endsWith('.bmp')
    ) {
      return 'icon-image.gif';
    }
    return 'icon-undefined.gif';
  }

  public get allAttachments() {
    const attachments = [];
    this.nachrichten?.forEach((n) => {
      n.attachments?.forEach((a) => {
        attachments.push({
          nachricht: n,
          attachment: a,
        });
      });
    });
    return attachments;
  }

  public get nachrichtenWithContent() {
    return this.nachrichten?.filter((n) => !!n.nachrichtText);
  }

  public hasPendingChanges(): boolean {
    if (
      this.neueNachricht != undefined &&
      ((this.neueNachricht.nachrichtText != '' && this.neueNachricht.nachrichtText != undefined) ||
        (this.neueNachricht.attachments != undefined && this.neueNachricht.attachments.length > 0))
    ) {
      return true;
    }
    return false;
  }

  public back(): void {
    this.router.navigate(['/postbox']);
  }

  private bytesToMb(bytes: number): number {
    return bytes / 1024 / 1024;
  }

  private emptyNachricht() {
    return new (class implements NachrichtDto {
      nachrichtText: '';
    })();
  }
}
