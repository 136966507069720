import { Component, OnInit } from '@angular/core';
import { SchadenDetailService } from './schaden-detail.service';
import {
  AbfrageApiService,
  BenutzerDto,
  FahrzeugDto,
  SchadenmeldungApiService,
  SchadenmeldungDto,
} from '../core/api';
import { compare } from '../core/directives/sortable-header';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ConfirmService } from '../core/confirm-modal/confirm.service';
import { BewertungDetailsService } from '../bewertung-details/bewertung-details.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DossierAnfordernComponent } from '../dossier-anfordern/dossier-anfordern.component';
import { TranslateWithPrefixPipe } from '../core/pipes/translate-with-prefix.pipe';
import { TranslateSchadenartPipe } from '../core/pipes/translate-schadenart.pipe';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { FahrzeugComponent } from './fahrzeug/fahrzeug.component';
import { FormsModule } from '@angular/forms';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import RollenEnum = BenutzerDto.RollenEnum;
import { BenutzerService } from '../core/benutzer/benutzer.service';
import { AuthorizationService } from '../core/authentication/authorization.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-schaden-detail',
  templateUrl: './schaden-detail.component.html',
  styleUrls: ['./schaden-detail.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    FormsModule,
    NgClass,
    FahrzeugComponent,
    NgFor,
    FullscreenSpinnerComponent,
    DatePipe,
    TranslateSchadenartPipe,
    TranslateWithPrefixPipe,
  ],
})
export class SchadenDetailComponent implements OnInit {
  public schadenmeldung: SchadenmeldungDto;
  private currentUser$ = this.benutzerService.benutzer$;
  private currentRole$ = this.authorizationService.authority$;
  public loading = false;
  public showDeleteEntry = false;
  public showAnfordernButton = false;

  constructor(
    private schadenDetailService: SchadenDetailService,
    private schadenmeldungApiService: SchadenmeldungApiService,
    private benutzerService: BenutzerService,
    private authorizationService: AuthorizationService,
    private abfrageApiService: AbfrageApiService,
    private bewertungDetailsService: BewertungDetailsService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private confirmService: ConfirmService,
    private modalService: NgbModal,
  ) {
    combineLatest([this.currentUser$, this.currentRole$]).subscribe(
      ([currentUser, currentRole]) => {
        this.initSchadenmeldung();

        this.showDeleteEntry =
          currentUser &&
          (currentRole === RollenEnum.SVV ||
            (currentRole === RollenEnum.VGADMIN &&
              this.schadenmeldung.versicherung.id === currentUser.versicherung.id));

        this.showAnfordernButton =
          currentRole &&
          currentRole === RollenEnum.BVM &&
          currentUser.versicherung.id != this.schadenmeldung.versicherung.id;
      },
    );
  }

  public ngOnInit(): void {
    this.initSchadenmeldung();
  }

  private initSchadenmeldung() {
    this.schadenmeldung = this.schadenDetailService.getSelectedSchadenDetail();
    if (this.schadenmeldung) {
      this.schadenmeldung.gegnerischeFahrzeuge = this.schadenmeldung.gegnerischeFahrzeuge?.sort(
        (a, b) => {
          return compare(a.nummer, b.nummer);
        },
      );
    } else {
      this.back();
    }
  }

  public showDossierAnfordern() {
    const dialog = this.modalService.open(DossierAnfordernComponent, {
      size: 'lg',
    });
    dialog.componentInstance.schadenmeldung = this.schadenmeldung;
  }

  public showBewertungDetails(fahrzeug: FahrzeugDto) {
    this.loading = true;
    this.abfrageApiService.searchFahrzeugHistory(fahrzeug.id).subscribe(
      (history) => {
        this.loading = false;
        this.bewertungDetailsService.setResults(fahrzeug, history);
        this.router.navigate(['/bewertung-details']);
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  public deleteEntry() {
    this.confirmService
      .confirm({
        title: 'title.datensatzloeschenbestaetigen',
        message: 'message.datensatzloeschenbestaetigen',
        acceptText: 'label.datensatzloeschen',
      })
      .then(
        () => {
          this.schadenmeldungApiService
            .deleteSchadenmeldung(this.schadenmeldung.id)
            .subscribe(() => {
              this.onSchadenmeldungDeleted();
            });
        },
        () => {
          // nothing to do, but prevent the default error handler from firing
        },
      );
  }

  public onFahrzeugDeleted(updatedSchadenmeldung: SchadenmeldungDto) {
    if (updatedSchadenmeldung) {
      this.schadenDetailService.updateSelectedSchadenmeldung(updatedSchadenmeldung);
      this.initSchadenmeldung();
    } else {
      this.onSchadenmeldungDeleted();
    }
  }

  private onSchadenmeldungDeleted() {
    this.schadenDetailService.deleteSelectedSchadenmeldung();
    this.showDeletedMessage();
    this.schadenDetailService.back();
  }

  private showDeletedMessage() {
    if (!this.schadenDetailService.getSelectedSchadenDetail()) {
      this.toastr.success(this.translate.instant('message.schadenmeldungengeloescht'));
    } else {
      this.toastr.success(this.translate.instant('message.schadenmeldunggeloescht'));
    }
  }

  public back() {
    this.schadenDetailService.back();
  }

  public hasPrevious() {
    return this.schadenDetailService.hasPrevious();
  }

  public previous() {
    this.schadenmeldung = this.schadenDetailService.previous();
  }

  public hasNext() {
    return this.schadenDetailService.hasNext();
  }

  public next() {
    this.schadenmeldung = this.schadenDetailService.next();
  }

  public get scoringErfolgreich() {
    return this.schadenmeldung?.status === SchadenmeldungDto.StatusEnum.ISPSCORINGERFOLGREICH;
  }
}
