import { Directive, HostListener } from '@angular/core';
import { StammnummerFormatter } from '../core/util/stammnummer-formatter';
import { NgControl } from '@angular/forms';

/**
 * Reformats a stammnummer with dots (123456789 -> 123.456.789) when pasting or drag&dropping text. Prevents the user from enternig
 * the dot manually because it will be added by reformating.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[reformatStammnummer]',
  standalone: true,
})
export class ReformatStammnummerDirective {
  constructor(private ngControl: NgControl) {}

  // trim and reformat with dots when pasting a text
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const inputString = event.clipboardData.getData('text/plain');

    const trimmedInput = inputString ? inputString.trim() : inputString;
    if (trimmedInput) {
      this.ngControl.control.setValue(StammnummerFormatter.reformat(trimmedInput));
    }
  }

  // prevent the dot from being entered by the user
  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent) {
    if (e.key === '.') {
      e.preventDefault();
    }
  }
}
