<div class="container-lg">
  <div class="row" *ngIf="(rolle$ | async) === Rolle.BVM">
    <div class="col-12 col-md-1"></div>
    <div class="card mb-5 col-12 col-md-10">
      <div class="card-body">
        <h5 class="mb-4" translate>label.standardreports</h5>
        <hr class="mb-4" />
        <div class="row">
          <div class="col-12 col-md-1"></div>
          <div class="col-12 col-md-2 mb-3">
            <button
              type="button"
              class="btn btn-primary form-control"
              (click)="searchTopTen()"
              translate>
              label.top10
            </button>
          </div>
          <div class="col-12 col-md-2"></div>
          <div class="col-12 col-md-2 mb-3">
            <button
              type="button"
              class="btn btn-primary form-control"
              (click)="searchTopTwentyFife()"
              translate>
              label.top25
            </button>
          </div>
          <div class="col-12 col-md-2"></div>
          <div class="col-12 col-md-2">
            <button
              type="button"
              class="btn btn-primary form-control"
              (click)="searchTopFifty()"
              translate>
              label.top50
            </button>
          </div>
          <div class="col-12 col-md-1"></div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-1"></div>
  </div>

  <form>
    <div class="row">
      <div class="col-12 col-md-1"></div>
      <div class="card mb-5 col-12 col-md-10">
        <div class="card-body">
          <h5 class="mb-4" translate>label.individualabfrage</h5>

          <hr class="mb-4" />

          <div class="form-group form-row">
            <span class="col-12 col-md-5">
              <label for="schadennummer" class="col-form-label col-form-label-lg" translate
                >label.schadennummer</label
              >
              <app-hint>
                <p class="mb-0" translate>label.platzhaltersucheerklaerung</p>
              </app-hint>
            </span>
            <div class="col-12 col-md-7">
              <input
                name="schadennummer"
                type="text"
                class="form-control form-control-lg"
                id="schadennummer"
                maxLength="20"
                autocomplete="off"
                autofocus
                [(ngModel)]="schadennummer"
                trim />
            </div>
          </div>

          <div class="form-group form-row">
            <span class="col-12 col-md-5">
              <label for="stammnummer" class="col-form-label col-form-label-lg" translate
                >label.stammnummer</label
              >
            </span>
            <div class="col-12 col-md-7">
              <input
                name="stammnummer"
                type="text"
                class="form-control form-control-lg col-12"
                id="stammnummer"
                maxLength="11"
                autocomplete="off"
                [ngModel]="stammnummer"
                (ngModelChange)="onModelChange($event)"
                (change)="addLeadingZeros()"
                reformatStammnummer />
            </div>
          </div>

          <div class="form-group form-row mb-5">
            <span class="col-12 col-md-5">
              <label for="fahrgestellnummer" class="col-form-label col-form-label-lg" translate
                >label.fahrgestellnummer</label
              >
            </span>
            <div class="col-12 col-md-7">
              <input
                name="fahrgestellnummer"
                type="text"
                class="form-control form-control-lg col-12"
                id="fahrgestellnummer"
                maxLength="28"
                autocomplete="off"
                [(ngModel)]="fahrgestellnummer"
                trim />
            </div>
          </div>

          <div class="form-group form-row" *ngIf="(rolle$ | async) == Rolle.SVV">
            <span class="col-12 col-md-11">
              <label
                for="fahrgestellnummer"
                class="col-form-label col-form-label-lg font-weight-bold"
                translate
                >label.erweitert</label
              >
              <app-hint>
                <p class="mb-0" translate>label.hinweispflichtfelder</p>
              </app-hint>
            </span>
          </div>
          <hr />

          <div class="form-group form-row mb-5" *ngIf="(rolle$ | async) == Rolle.SVV">
            <label
              class="col-form-label col-form-label-lg col-12 col-md-5"
              for="schadendatumvon"
              translate
              >label.schadendatum</label
            >
            <div class="col-12 col-md-7">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    id="schadendatumvonDatePickerToggle"
                    (click)="schadendatumvonDatePicker.toggle()">
                    <i class="fa fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="{{ 'label.schadendatumvon' | translate }}"
                  class="form-control form-control-lg"
                  name="schadendatumvon"
                  id="schadendatumvon"
                  ngbDatepicker
                  #schadendatumvonDatePicker="ngbDatepicker"
                  [(ngModel)]="schadendatumVon"
                  autocomplete="off" />
              </div>
            </div>

            <div class="col-12 col-md-5"></div>
            <div class="col-12 col-md-7">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span
                    class="input-group-text"
                    id="schadendatumbisDatePickerToggle"
                    (click)="schadendatumbisDatePicker.toggle()">
                    <i class="fa fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  type="text"
                  placeholder="{{ 'label.schadendatumbis' | translate }}"
                  class="form-control form-control-lg"
                  name="schadendatumbis"
                  id="schadendatumbis"
                  ngbDatepicker
                  #schadendatumbisDatePicker="ngbDatepicker"
                  [(ngModel)]="schadendatumBis"
                  autocomplete="off" />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-12 col-md-6"></div>
            <div class="col-6 col-md-3">
              <button
                type="button"
                class="btn btn-outline-secondary form-control"
                (click)="clearSearch()"
                translate>
                label.sucheleeren
              </button>
            </div>
            <div class="col-6 col-md-3">
              <button
                type="submit"
                class="btn btn-primary form-control"
                (click)="search()"
                translate>
                label.abfragestarten
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-1"></div>
    </div>
  </form>
</div>
<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
