import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Translate the "regulierung". It can be an enum value (if it comes from our database in Schadenmeldung) or a numeric
 * value (if it comes from riskshield in SchadenmeldungHistory).
 */
@Pipe({
  name: 'translateRegulierung',
  pure: false,
  standalone: true,
})
export class TranslateRegulierungPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(regulierung?: number | string): unknown {
    return !!regulierung ? this.translate.instant('enum.regulierungenum.' + regulierung) : '';
  }
}
