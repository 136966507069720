import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DossierAnfordernDto, PostboxApiService, SchadenmeldungDto } from '../core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-dossier-anfordern',
  templateUrl: './dossier-anfordern.component.html',
  styleUrls: ['./dossier-anfordern.component.scss'],
  standalone: true,
  imports: [TranslateModule, FormsModule, FullscreenSpinnerComponent],
})
export class DossierAnfordernComponent implements OnInit {
  @Input()
  public schadenmeldung: SchadenmeldungDto;

  public loading = false;

  public dto: DossierAnfordernDto = new (class implements DossierAnfordernDto {
    anfrageAndereText = '';
    nachrichtText = '';
  })();

  constructor(
    private postboxApiService: PostboxApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public modal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    this.dto.schadenmeldungId = this.schadenmeldung.id;
  }

  public clearAndereText() {
    if (!this.dto.anfrageAndere) {
      this.dto.anfrageAndereText = '';
    }
  }

  public dossierAnfordern() {
    this.loading = true;
    this.postboxApiService.dossierAnfordern(this.dto).subscribe(
      () => {
        this.loading = false;
        this.modal.close();
        this.toastr.success(this.translate.instant('message.postboxanfrageerstellt'));
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }
}
