import { Component, OnInit } from '@angular/core';
import { BenutzerDto } from '../core/api';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateRollePipe } from '../core/pipes/translate-rolle.pipe';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { AsyncPipe, NgFor } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BenutzerService } from '../core/benutzer/benutzer.service';
import { AuthorizationService } from '../core/authentication/authorization.service';
import RollenEnum = BenutzerDto.RollenEnum;
import { first } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-abfragen',
  templateUrl: './rollenauswahl.component.html',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgFor,
    FullscreenSpinnerComponent,
    TranslateRollePipe,
    AsyncPipe,
  ],
})
export class RollenauswahlComponent implements OnInit {
  public loading = false;
  private readonly redirectUri: string;
  public benutzer$ = this.benutzerService.benutzer$;
  public rolle$ = this.authorizationService.authority$;

  public constructor(
    private benutzerService: BenutzerService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.redirectUri = this.route.snapshot.queryParamMap.get('redirectUri');
  }

  ngOnInit(): void {
    this.benutzer$
      .pipe(
        filter((b) => b != null),
        first(),
      )
      .subscribe((benutzer) => {
        if (benutzer.rollen.length == 1) {
          this.aktiveRolleChanged(benutzer.rollen[0]);
          this.redirectAfterRoleSelection();
        }
      });
  }

  redirectAfterRoleSelection() {
    if (this.redirectUri && !this.redirectUri.includes('rollenauswahl')) {
      this.router.navigate([this.redirectUri]);
    } else {
      this.router.navigate(['abfragen']);
    }
  }

  aktiveRolleChanged(neueRolle: RollenEnum): void {
    this.authorizationService.setCurrentAuthority(neueRolle);
  }

  setAktiveRolle() {
    this.toastr.success(this.translate.instant('message.aktive.rolle.saved'));
    this.redirectAfterRoleSelection();
  }
}
