import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Can be used to translate an enum if the enum value can be null (because {{'enum.' + benutzer.status | translate}}
 * leads to 'enum.null' being displayed if the status is null).
 * Usage:
 * {{benutzer.status | translateWithPrefix: 'enum.benutzerstatus.'}}
 */
@Pipe({
  name: 'translateWithPrefix',
  pure: false,
  standalone: true,
})
export class TranslateWithPrefixPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(key?: string, prefix?: string): string {
    return !!key ? this.translate.instant(prefix + key) : '';
  }
}
