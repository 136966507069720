import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, OperatorFunction, ReplaySubject, timer } from 'rxjs';
import { BenutzerApiService, BenutzerDto } from '../api';
import { retry } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class BenutzerService {
  public readonly benutzer$: BehaviorSubject<BenutzerDto> = new BehaviorSubject<BenutzerDto>(null);

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly benutzerApiService: BenutzerApiService,
  ) {
    this.authenticationService.addLoginListener(() => this.reload());
    this.reload();
  }

  reload(): void {
    this.benutzerApiService
      .getSelf()
      .pipe(this.backoff(20, 250))
      .subscribe((dto: BenutzerDto) => {
        this.benutzer$.next(dto);
      });
  }

  backoff<T>(maxTries: number, initialDelay: number): OperatorFunction<T, T> {
    return retry({
      count: maxTries,
      delay: (error, retryCount) => timer(initialDelay * retryCount ** 2),
    });
  }
}
