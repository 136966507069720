import { Component, Input, OnInit } from '@angular/core';
import { NachrichtDto } from '../../../core/api';
import { NgClass, NgIf, DatePipe } from '@angular/common';

@Component({
  selector: 'app-nachricht',
  templateUrl: './nachricht.component.html',
  styleUrls: ['./nachricht.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, DatePipe],
})
export class NachrichtComponent implements OnInit {
  @Input()
  public nachricht: NachrichtDto;

  @Input()
  public displayOnTheLeft = true;

  constructor() {}

  ngOnInit(): void {}
}
