import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateRolle',
  pure: false,
  standalone: true,
})
export class TranslateRollePipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(rolle?: string): string {
    return !!rolle ? this.translate.instant('enum.rolle.' + rolle) : '';
  }
}
