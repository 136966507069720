import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

/**
 * Necessary to format the displayed date of the datepicker as dd.MM.yyyy
 * as described in https://github.com/ng-bootstrap/ng-bootstrap/issues/2072
 */
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  public parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('.');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { day: this.toInteger(dateParts[0]), month: null, year: null };
      } else if (
        dateParts.length === 2 &&
        this.isNumber(dateParts[0]) &&
        this.isNumber(dateParts[1])
      ) {
        return {
          day: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]),
          year: null,
        };
      } else if (
        dateParts.length === 3 &&
        this.isNumber(dateParts[0]) &&
        this.isNumber(dateParts[1]) &&
        this.isNumber(dateParts[2])
      ) {
        return {
          day: this.toInteger(dateParts[0]),
          month: this.toInteger(dateParts[1]),
          year: this.parseYear(dateParts[2]),
        };
      }
    }
    return null;
  }

  public format(date: NgbDateStruct): string {
    return date
      ? // tslint:disable-next-line: max-line-length
        `${this.isNumber(date.day) ? this.padNumber(date.day) : ''}.${this.isNumber(date.month) ? this.padNumber(date.month) : ''}.${date.year}`
      : '';
  }

  private isNumber(value) {
    return !isNaN(value);
  }

  private toInteger(value) {
    return parseInt(value, 10);
  }

  private padNumber(value: number) {
    if (value < 10) {
      return '0' + value;
    }
    return value;
  }

  private parseYear(year: string) {
    let yearAsNumber = this.toInteger(year);
    if (yearAsNumber < 100) {
      return 2000 + yearAsNumber;
    }
    return yearAsNumber;
  }
}
