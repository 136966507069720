import { enableProdMode, importProvidersFrom, ErrorHandler } from '@angular/core';
import { AppConfig } from './app-config';

import { AppComponent } from './app/app.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ScrollToBottomDirective } from './app/core/directives/scroll-to-bottom.directive';
import { ReformatStammnummerDirective } from './app/abfragen/reformat-stammnummer.directive';
import { SafePipe } from './app/core/pipes/safe.pipe';
import { ConfirmState } from './app/core/confirm-modal/confirm-state';
import { TranslateWithPrefixPipe } from './app/core/pipes/translate-with-prefix.pipe';
import { TranslateRollePipe } from './app/core/pipes/translate-rolle.pipe';
import { TranslateLandPipe } from './app/core/pipes/translate-land.pipe';
import { TranslateFahrzeugartPipe } from './app/core/pipes/translate-fahrzeugart.pipe';
import { TranslateRegulierungPipe } from './app/core/pipes/translate-regulierung.pipe';
import { TranslateDeckungsartPipe } from './app/core/pipes/translate-deckungsart.pipe';
import { TranslateSchadenartPipe } from './app/core/pipes/translate-schadenart.pipe';
import { NgbDateCustomParserFormatter } from './app/core/util/ngb-date-custom-parser-formatter';
import {
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandlerService } from './app/core/error/error-handler.service';
import { SafeHtmlPipe } from './app/core/pipes/safe-html.pipe';
import { JsonPipe, DatePipe } from '@angular/common';
import { AuthenticationService } from './app/core/authentication/authentication.service';
import { Configuration, ApiModule } from './app/core/api';
import { TranslateModule } from '@ngx-translate/core';
import { APP_ROUTES } from './app/app.routes';
import { provideRouter, withDisabledInitialNavigation, RouterModule } from '@angular/router';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

void fetch('/ccinfo/assets/app-config.json')
  .then((response) => {
    if (!response.ok) {
      throw new Error('Failed to load configuration from server.');
    }
    return response.text();
  })
  .then((config: string) => {
    const appConfig = JSON.parse(config) as AppConfig;
    if (appConfig.production) {
      enableProdMode();
    }

    bootstrapApplication(AppComponent, {
      providers: [
        // Use of withInterceptorsFromDi() is discouraged, but we need it for angular-oauth2-oidc's interceptor.
        // Once the library adds a functional interceptor, we can add that instead.
        // see https://github.com/manfredsteyer/angular-oauth2-oidc/issues/1296
        provideHttpClient(withInterceptorsFromDi()),
        provideOAuthClient({
          resourceServer: {
            allowedUrls: [appConfig.backendUrl + '/api'],
            sendAccessToken: true,
          },
        }),
        { provide: AppConfig, useValue: appConfig },
        // defer initial navigation until the OIDC authorization code flow is complete
        // see https://jira.bedag.ch/browse/BDE-224 for background info
        provideRouter(APP_ROUTES, withDisabledInitialNavigation()),
        provideAnimations(),
        importProvidersFrom(
          TranslateModule.forRoot({ defaultLanguage: 'de' }),
          BrowserModule,
          FormsModule,
          BrowserAnimationsModule,
          ToastrModule.forRoot({
            positionClass: 'toast-top-center',
          }),
          ApiModule.forRoot(() => {
            return new Configuration({ withCredentials: true, basePath: appConfig.backendUrl });
          }),
          TranslateModule,
          RouterModule,
          NgbDatepickerModule,
          NgbPopoverModule,
          NgSelectModule,
          NgxFileDropModule,
        ),
        {
          provide: Configuration,
          useValue: new Configuration({ basePath: appConfig.backendUrl }),
        },
        AuthenticationService,
        JsonPipe,
        SafeHtmlPipe,
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        TranslateSchadenartPipe,
        TranslateDeckungsartPipe,
        TranslateRegulierungPipe,
        TranslateFahrzeugartPipe,
        TranslateLandPipe,
        TranslateRollePipe,
        TranslateWithPrefixPipe,
        ConfirmState,
        DatePipe,
        SafePipe,
        ReformatStammnummerDirective,
        ScrollToBottomDirective,
      ],
    });
  })
  .catch((err) => console.error(err));
