import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo',
  pure: false,
  standalone: true,
})
export class YesNoPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(yesNo?: boolean): string {
    return !!yesNo
      ? this.translate.instant('booleanlabelconverter.true')
      : this.translate.instant('booleanlabelconverter.false');
  }
}
