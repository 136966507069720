<div class="container-lg">
  <form (ngSubmit)="setAktiveRolle()">
    <div class="row">
      <div class="col-12 col-md-1"></div>
      <div class="card mb-5 col-12 col-md-10">
        <div class="card-body">
          <h5 class="mb-4" translate>title.rollenauswahl</h5>

          <hr class="mb-4" />

          <div class="form-group form-row">
            <span class="col-12 col-md-6">
              <label for="rollen" class="col-form-label col-lg-3" translate>label.rollen</label>
            </span>
            <div class="col-12 col-md-6">
              <select
                id="rollen"
                name="rollen"
                [ngModel]="rolle$ | async"
                (ngModelChange)="aktiveRolleChanged($event)"
                class="form-control form-control-sm">
                <option *ngFor="let rolle of (benutzer$ | async)?.rollen" [value]="rolle">
                  {{ rolle | translateRolle }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-row d-flex justify-content-end">
            <div class="col-6">
              <button
                type="submit"
                [disabled]="!(rolle$ | async)"
                class="btn btn-primary form-control"
                translate>
                label.aktive.rolle.speichern
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-1"></div>
    </div>
  </form>
</div>
<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
