export class StringStandardizer {
  public static standardize(text: string) {
    if (!text) {
      return '';
    } else {
      return this.replaceSpecialCharacters(text.toLowerCase());
    }
  }

  private static replaceSpecialCharacters(value: string): string {
    let newValue: string = value;
    if (newValue) {
      newValue = newValue.replace(/[à|ä|â|á]/gi, 'a');
      newValue = newValue.replace(/[é|è|ê|ë]/gi, 'e');
      newValue = newValue.replace(/[ï|î|ì|í]/gi, 'i');
      newValue = newValue.replace(/ñ/gi, 'n');
      newValue = newValue.replace(/[ö|ò|ó|ô]/gi, 'o');
      newValue = newValue.replace(/[ü|û|ù|ú]/gi, 'u');
    }
    return newValue;
  }
}
