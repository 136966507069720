/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachmentDto';
import { KontaktInfoDto } from './kontaktInfoDto';


export interface NachrichtDto { 
    id?: number;
    creationDate?: string;
    von?: KontaktInfoDto;
    kontakt?: string;
    anfrageExpertenbericht?: boolean;
    anfrageFoto?: boolean;
    anfrageAndere?: boolean;
    anfrageAndereText?: string;
    nachrichtText?: string;
    attachments?: Array<AttachmentDto>;
}

