import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { AbfrageResultatService } from '../abfrage-resultat/abfrage-resultat.service';
import { TopLimit } from '../core/enum/TopLimit';
import { StammnummerFormatter } from '../core/util/stammnummer-formatter';
import { BenutzerDto } from '../core/api';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { ReformatStammnummerDirective } from './reformat-stammnummer.directive';
import { TrimDirective } from '../core/directives/trim.directive';
import { HintComponent } from '../core/hint/hint.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import RollenEnum = BenutzerDto.RollenEnum;
import { AsyncPipe, NgIf } from '@angular/common';
import { AuthorizationService } from '../core/authentication/authorization.service';

@Component({
  selector: 'app-abfragen',
  templateUrl: './abfragen.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    HintComponent,
    TrimDirective,
    ReformatStammnummerDirective,
    NgbInputDatepicker,
    FullscreenSpinnerComponent,
    AsyncPipe,
    NgIf,
  ],
})
export class AbfragenComponent implements OnInit {
  public Rolle: typeof RollenEnum = RollenEnum;

  public schadennummer: string = '';
  public stammnummer: string = '';
  public fahrgestellnummer: string = '';

  public schadendatumVon: NgbDate;
  public schadendatumBis: NgbDate;

  public loading = false;

  public readonly rolle$ = this.authzService.authority$;

  public constructor(
    private abfrageResultatService: AbfrageResultatService,
    private readonly authzService: AuthorizationService,
  ) {}

  public ngOnInit(): void {
    this.schadennummer = this.abfrageResultatService.getSchadennummer();
    this.stammnummer = StammnummerFormatter.reformat(this.abfrageResultatService.getStammnummer());
    this.fahrgestellnummer = this.abfrageResultatService.getFahrgestellnummer();
    this.schadendatumVon = this.abfrageResultatService.getSchadendatumVon();
    this.schadendatumBis = this.abfrageResultatService.getSchadendatumBis();
  }

  public clearSearch() {
    this.schadennummer = '';
    this.stammnummer = '';
    this.fahrgestellnummer = '';
    this.schadendatumVon = undefined;
    this.schadendatumBis = undefined;
    this.abfrageResultatService.reset();
  }

  public search() {
    this.loading = true;
    this.abfrageResultatService
      .searchIndividual(
        this.fahrgestellnummer,
        this.schadennummer,
        StammnummerFormatter.removeDots(this.stammnummer),
        this.schadendatumVon,
        this.schadendatumBis,
      )
      .then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
  }

  public onModelChange(newStammnummer) {
    this.stammnummer = StammnummerFormatter.reformat(newStammnummer);
  }

  public addLeadingZeros() {
    this.stammnummer = StammnummerFormatter.reformatWithLeadingZeros(this.stammnummer);
  }

  public searchTopTen() {
    this.searchTop(TopLimit.TEN);
  }

  public searchTopTwentyFife() {
    this.searchTop(TopLimit.TWENTY_FIVE);
  }

  public searchTopFifty() {
    this.searchTop(TopLimit.FIFTY);
  }

  private searchTop(limit: TopLimit) {
    this.loading = true;
    this.abfrageResultatService.searchTop(limit).then(
      () => {
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }
}
