<div class="container-lg">
  <div class="row">
    <div class="card offset-md-2 col-8">
      <div class="card-body">
        <h5 class="mb-4">{{ 'title.auswertungen' | translate }}</h5>
        <hr class="mb-4" />
        <form #auswertungenForm="ngForm" (ngSubmit)="submitForm()">
          <div class="form-group row">
            <label for="nutzungszahlen" class="col-form-label col-lg-3">{{
              'label.nutzungszahlen' | translate
            }}</label>
            <div class="col-lg-9">
              <select
                id="nutzungszahlen"
                name="nutzungszahlen"
                [(ngModel)]="auswertungDto.auswertung"
                class="form-control form-control-sm"
                required>
                <option *ngFor="let auswertung of auswertungen" [value]="auswertung">
                  {{ 'enum.auswertung.' + auswertung | translate }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="versicherung" class="col-form-label col-lg-3">{{
              'label.gesellschaft' | translate
            }}</label>
            <div class="col-lg-9">
              <select
                id="versicherung"
                name="versicherung"
                [(ngModel)]="selectedVersicherung"
                class="form-control form-control-sm"
                required>
                <option [value]="'all'">{{ 'auswertung.alle' | translate }}</option>
                <option *ngFor="let versicherung of versicherungen" [ngValue]="versicherung">
                  {{ versicherung.name }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-group row"
            ngModelGroup="monthSelector"
            monthSelectorValidator="monthSelector"
            #monthSelector="ngModelGroup">
            <label class="col-form-label col-lg-3 d">{{ 'label.datum' | translate }}</label>
            <div class="col-9">
              <div class="error" *ngIf="!monthSelector.valid">
                {{ 'message.datum.error' | translate }}
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <div class="d-inline-block month-selector">
                    <select
                      id="monthFrom"
                      name="monthFrom"
                      [(ngModel)]="selectedMonthFrom"
                      [ngStyle]="{ border: monthSelector.valid ? '' : '2px solid red' }"
                      class="form-control form-control-sm"
                      required>
                      <option></option>
                      <option *ngFor="let key of monateKeys" [value]="key">
                        {{ 'enum.monat.short.' + monate[key] | translate }}
                      </option>
                    </select>
                  </div>
                  <div class="d-inline-block">
                    <select
                      id="yearFrom"
                      name="yearFrom"
                      [(ngModel)]="selectedYearFrom"
                      [ngStyle]="{ border: monthSelector.valid ? '' : '2px solid red' }"
                      class="form-control form-control-sm"
                      required>
                      <option></option>
                      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
                <div>-</div>
                <div>
                  <div class="d-inline-block month-selector">
                    <select
                      id="monthTo"
                      name="monthTo"
                      [(ngModel)]="selectedMonthTo"
                      [ngStyle]="{ border: monthSelector.valid ? '' : '2px solid red' }"
                      class="form-control form-control-sm"
                      required>
                      <option></option>
                      <option *ngFor="let key of monateKeys" [value]="key">
                        {{ 'enum.monat.short.' + monate[key] | translate }}
                      </option>
                    </select>
                  </div>
                  <div class="d-inline-block">
                    <select
                      id="yearTo"
                      name="yearTo"
                      [(ngModel)]="selectedYearTo"
                      [ngStyle]="{ border: monthSelector.valid ? '' : '2px solid red' }"
                      class="form-control form-control-sm"
                      required>
                      <option></option>
                      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row pt-4 d-flex justify-content-end">
            <div class="col-3">
              <button
                type="button"
                class="btn btn-outline-secondary form-control"
                (click)="resetForm(auswertungenForm)">
                {{ 'label.zuruecksetzen' | translate }}
              </button>
            </div>
            <div class="col-3">
              <button
                type="submit"
                [disabled]="auswertungenForm.invalid"
                class="btn btn-primary form-control">
                {{ 'label.generieren' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
