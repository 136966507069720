import { Component } from '@angular/core';
import { BenutzerApiService, BenutzerDto } from '../../core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BenutzerFormComponent } from '../benutzer-form/benutzer-form.component';

@Component({
  selector: 'app-edit-benutzer',
  templateUrl: './edit-benutzer.component.html',
  styleUrls: ['./edit-benutzer.component.scss'],
  standalone: true,
  imports: [BenutzerFormComponent],
})
export class EditBenutzerComponent {
  benutzer: BenutzerDto;

  constructor(
    private benutzerApiService: BenutzerApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.benutzer = route.snapshot.data['user'];
  }

  updateBenutzer(benutzer: BenutzerDto): void {
    this.benutzerApiService.saveBenutzer(benutzer).subscribe(
      (res) => {
        this.toastr.success(
          this.translate.instant('message.benutzergespeichert', {
            benutzerid: benutzer.email,
          }),
        );
        this.router.navigate(['/benutzerverwaltung']);
      },
      (error) => {
        throw error;
      },
    );
  }
}
