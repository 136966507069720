import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Translate the "schadenart". It can be an enum value (if it comes from our database in Schadenmeldung) or a numeric
 * value (if it comes from riskshield in SchadenmeldungHistory).
 */
@Pipe({
  name: 'translateSchadenart',
  pure: false,
  standalone: true,
})
export class TranslateSchadenartPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(schadenArt?: number | string): unknown {
    return !!schadenArt ? this.translate.instant('enum.schadenartenum.' + schadenArt) : '';
  }
}
