import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { JsonPipe } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  private translate: TranslateService;
  private toastr: ToastrService;
  private jsonPipe: JsonPipe;
  private ngZone: NgZone;
  private router: Router;

  // because the error handler is loaded before everything else, we cannot inject normal services and need to use the Injector manually
  // https://medium.com/@amcdnl/global-error-handling-with-angular2-6b992bdfb59c
  constructor(private injector: Injector) {
    super();
  }

  public override handleError(error: any): void {
    this.initializeDependencies();

    // displays errors in the console
    super.handleError(error);

    const rootCause = this.extractRootCause(error);
    if (rootCause instanceof HttpErrorResponse) {
      // the global error handler runs outside of the angular zone. therefore toasts are not displayed if we don't run it inside the angular zone
      this.ngZone.run(() => {
        this.handleHttpError(rootCause as HttpErrorResponse);
      });
    }
  }

  private initializeDependencies(): void {
    if (!this.translate) {
      this.translate = this.injector.get(TranslateService);
    }
    if (!this.toastr) {
      this.toastr = this.injector.get(ToastrService);
    }
    if (!this.jsonPipe) {
      this.jsonPipe = this.injector.get(JsonPipe);
    }
    if (!this.ngZone) {
      this.ngZone = this.injector.get(NgZone);
    }
    if (!this.router) {
      this.router = this.injector.get(Router);
    }
  }

  private handleHttpError(error: HttpErrorResponse): void {
    if (error.status === 422) {
      this.handle422(error);
    } else if (error.status === 403) {
      this.handle403();
    } else if (error.status === 401) {
      this.handle401();
    } else if (error.status === 410) {
      this.handle410();
    } else if (error.status === 400) {
      this.handle400(error);
    } else {
      this.handleOtherHttpError(error);
    }
  }

  private handle403() {
    this.toastr.error(this.translate.instant('error.http.403'), undefined, {
      enableHtml: false,
      tapToDismiss: true,
      disableTimeOut: true,
    });
  }

  private handle400(errorResponse: HttpErrorResponse): void {
    const body = errorResponse.error;
    const message = this.translate.instant(body.messageKey, body.replacementValues);
    this.toastr.clear();
    this.toastr.error(message, undefined, {
      extendedTimeOut: 0,
      timeOut: 6000,
      enableHtml: true,
    });
  }

  private handle401() {
    this.router.navigate(['/login']);
  }

  private handle410() {
    this.router.navigate(['/forced-logout']);
  }

  private handle422(errorResponse: HttpErrorResponse): void {
    const body = errorResponse.error;
    const message = this.translate.instant(body.messageKey, body.replacementValues);
    this.toastr.warning(message, undefined, {
      enableHtml: true,
      tapToDismiss: true,
      disableTimeOut: true,
    });
  }

  private handleOtherHttpError(errorResponse: HttpErrorResponse): void {
    const message = this.translate.instant('error.http.generic');
    this.toastr.error(message, undefined, {
      enableHtml: true,
      tapToDismiss: true,
      disableTimeOut: true,
    });
  }

  private extractRootCause(error: any): any {
    if (error.rejection) {
      return error.rejection;
    }
    return error;
  }
}
