import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateLand',
  pure: false,
  standalone: true,
})
export class TranslateLandPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(land?: string): string {
    return !!land ? this.translate.instant('enum.landenum.' + land) : '';
  }
}
