import { Component, OnInit } from '@angular/core';
import { BenutzerApiService, VersicherungApiService, VersicherungDto } from '../core/api';
import TypEnum = VersicherungDto.TypEnum;
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import AuthTypEnum = VersicherungDto.AuthTypEnum;

@Component({
  selector: 'app-organisationen',
  templateUrl: './organisationen.component.html',
  styleUrls: ['./organisationen.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgFor,
    NgIf,
    NgClass,
    RouterLink,
    FullscreenSpinnerComponent,
  ],
})
export class OrganisationenComponent implements OnInit {
  public loading = false;

  public versicherungen: VersicherungDto[] = [];
  public selectedVersicherung: VersicherungDto;
  public versicherungsTypen: TypEnum[] = Object.entries(TypEnum).map(([, enumValue]) => enumValue);
  public gsCodeHistoryAsString: string;
  public readonly AuthTypEnum = AuthTypEnum;
  public readonly authTypes: AuthTypEnum[] = Object.entries(AuthTypEnum).map(
    ([, enumValue]) => enumValue,
  );
  constructor(
    private versicherungApiService: VersicherungApiService,
    private benutzerApiService: BenutzerApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.loadVersicherungen();
  }

  public cancel() {
    this.loadVersicherungen();
  }

  public newVersicherung() {
    const neueVersicherung: VersicherungDto = new (class implements VersicherungDto {})();
    neueVersicherung.authTyp = AuthTypEnum.TWOFA;
    neueVersicherung.inactiveGsCodes = [];
    neueVersicherung.fileImportEncoding = 'csISOLatin1';
    neueVersicherung.aktiv = false;
    neueVersicherung.typ = this.versicherungsTypen?.find(
      (t) => t === TypEnum.VERSICHERUNGSGESELLSCHAFT,
    );
    this.versicherungen.push(neueVersicherung);
    this.selectVersicherung(neueVersicherung);
  }

  public save() {
    if (this.gsCodeHistoryAsString && this.gsCodeHistoryAsString.length > 0) {
      this.selectedVersicherung.inactiveGsCodes = this.gsCodeHistoryAsString.split(',');
    } else {
      this.selectedVersicherung.inactiveGsCodes = [];
    }
    this.loading = true;
    this.versicherungApiService.saveVersicherung(this.selectedVersicherung).subscribe(
      (updatedVersicherung) => {
        this.selectVersicherung(updatedVersicherung);
        // loading will be set to false by loadVersicherungen (resetView). Not setting it to false here to avoid flickering
        this.toastr.success(
          this.translate.instant('message.oegespeichert', { oe: updatedVersicherung.name }),
        );
        this.resetView();
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  private resetView() {
    this.versicherungen = [];
    this.selectVersicherung(null);
    this.loadVersicherungen();
  }

  public selectVersicherung(versicherung: VersicherungDto) {
    this.selectedVersicherung = versicherung;
    this.gsCodeHistoryAsString = this.selectedVersicherung?.inactiveGsCodes?.join(',');
  }

  public compareFn(a, b): boolean {
    if (a != undefined && b != undefined) {
      return a.id === b.id;
    }
    return false;
  }

  private loadVersicherungen() {
    this.loading = true;
    const previouslySelectedVersicherungId = this.selectedVersicherung?.id;
    this.benutzerApiService.getVersicherungen().subscribe(
      (versicherungen: VersicherungDto[]) => {
        this.versicherungen = versicherungen;
        const previousVersicherung = versicherungen.find(
          (v) => v.id === previouslySelectedVersicherungId,
        );
        this.selectVersicherung(previousVersicherung);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }
}
