export const executeFileDownload = (file: any, fileName: string) => {
  const contentType = file.headers.get('Content-Type');
  const blob = new Blob([file.body], { type: contentType });
  const link = document.createElement('a');
  // @ts-ginore
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(link.href);
  link.remove();
};
