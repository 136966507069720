/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VersicherungDto } from './versicherungDto';


export interface FahrzeugDto { 
    id?: number;
    score?: number;
    verdaechtigstes?: boolean;
    regulierung?: FahrzeugDto.RegulierungEnum;
    schadensumme?: number;
    kmstand?: number;
    kennzeichen?: string;
    land?: FahrzeugDto.LandEnum;
    fzArt?: string;
    kantonsKuerzel?: string;
    markeTyp?: string;
    fahrgestellnummer?: string;
    stammnummer?: string;
    nummer?: number;
    deckungsArt?: FahrzeugDto.DeckungsArtEnum;
    erstIVDatum?: string;
    anreicherungsmeldung?: FahrzeugDto.AnreicherungsmeldungEnum;
    status?: FahrzeugDto.StatusEnum;
    versicherung?: VersicherungDto;
    versicherungsname?: string;
    hasHistory?: boolean;
}
export namespace FahrzeugDto {
    export type RegulierungEnum = 'CASH' | 'REPARATUR' | 'TOTALSCHADEN' | 'UNBEKANNT' | 'KEINE_VERSICHERUNGSLEISTUNG';
    export const RegulierungEnum = {
        CASH: 'CASH' as RegulierungEnum,
        REPARATUR: 'REPARATUR' as RegulierungEnum,
        TOTALSCHADEN: 'TOTALSCHADEN' as RegulierungEnum,
        UNBEKANNT: 'UNBEKANNT' as RegulierungEnum,
        KEINEVERSICHERUNGSLEISTUNG: 'KEINE_VERSICHERUNGSLEISTUNG' as RegulierungEnum
    };
    export type LandEnum = 'SCHWEIZ' | 'AUSLAND';
    export const LandEnum = {
        SCHWEIZ: 'SCHWEIZ' as LandEnum,
        AUSLAND: 'AUSLAND' as LandEnum
    };
    export type DeckungsArtEnum = 'MFH' | 'MFK' | 'PRIVATHAFTPFLICHT' | 'NGF' | 'UNTERNEHMENSSACHVERSICHERUNG';
    export const DeckungsArtEnum = {
        MFH: 'MFH' as DeckungsArtEnum,
        MFK: 'MFK' as DeckungsArtEnum,
        PRIVATHAFTPFLICHT: 'PRIVATHAFTPFLICHT' as DeckungsArtEnum,
        NGF: 'NGF' as DeckungsArtEnum,
        UNTERNEHMENSSACHVERSICHERUNG: 'UNTERNEHMENSSACHVERSICHERUNG' as DeckungsArtEnum
    };
    export type AnreicherungsmeldungEnum = 'KEIN_EINDEUTIGES_FAHRZEUG' | 'ANDERE_VERSICHERUNG' | 'MEHRERE_MOEGLICHE_FAHRZEUGE' | 'ERFOLGREICH_ANGEREICHERT' | 'FAHRZEUGDATEN_VERAENDERT' | 'ANREICHERUNG_DURCH_KENNZEICHEN' | 'ANREICHERUNG_DURCH_STAMMNR' | 'ANREICHERUNG_DURCH_FAHRGESTELLNR' | 'UNBEKANNTE_VERSICHERUNG' | 'AUSLAENDISCHES_FAHRZEUG' | 'TECHNISCHES_PROBLEM';
    export const AnreicherungsmeldungEnum = {
        KEINEINDEUTIGESFAHRZEUG: 'KEIN_EINDEUTIGES_FAHRZEUG' as AnreicherungsmeldungEnum,
        ANDEREVERSICHERUNG: 'ANDERE_VERSICHERUNG' as AnreicherungsmeldungEnum,
        MEHREREMOEGLICHEFAHRZEUGE: 'MEHRERE_MOEGLICHE_FAHRZEUGE' as AnreicherungsmeldungEnum,
        ERFOLGREICHANGEREICHERT: 'ERFOLGREICH_ANGEREICHERT' as AnreicherungsmeldungEnum,
        FAHRZEUGDATENVERAENDERT: 'FAHRZEUGDATEN_VERAENDERT' as AnreicherungsmeldungEnum,
        ANREICHERUNGDURCHKENNZEICHEN: 'ANREICHERUNG_DURCH_KENNZEICHEN' as AnreicherungsmeldungEnum,
        ANREICHERUNGDURCHSTAMMNR: 'ANREICHERUNG_DURCH_STAMMNR' as AnreicherungsmeldungEnum,
        ANREICHERUNGDURCHFAHRGESTELLNR: 'ANREICHERUNG_DURCH_FAHRGESTELLNR' as AnreicherungsmeldungEnum,
        UNBEKANNTEVERSICHERUNG: 'UNBEKANNTE_VERSICHERUNG' as AnreicherungsmeldungEnum,
        AUSLAENDISCHESFAHRZEUG: 'AUSLAENDISCHES_FAHRZEUG' as AnreicherungsmeldungEnum,
        TECHNISCHESPROBLEM: 'TECHNISCHES_PROBLEM' as AnreicherungsmeldungEnum
    };
    export type StatusEnum = 'EINGESTELLT' | 'CLS_ANREICHERUNG_OK' | 'CLS_ANREICHERUNG_FEHLERHAFT' | 'AUS_ISP_GELOESCHT';
    export const StatusEnum = {
        EINGESTELLT: 'EINGESTELLT' as StatusEnum,
        CLSANREICHERUNGOK: 'CLS_ANREICHERUNG_OK' as StatusEnum,
        CLSANREICHERUNGFEHLERHAFT: 'CLS_ANREICHERUNG_FEHLERHAFT' as StatusEnum,
        AUSISPGELOESCHT: 'AUS_ISP_GELOESCHT' as StatusEnum
    };
}


