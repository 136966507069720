import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { BenutzerDto } from '../api';
import RollenEnum = BenutzerDto.RollenEnum;

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  private readonly currentAuthorityKey = 'currentAuthorityKey';

  public readonly authority$: BehaviorSubject<RollenEnum> = new BehaviorSubject(null);

  constructor(authenticationService: AuthenticationService) {
    authenticationService.addLoginListener(() => this.reload());
    authenticationService.addLogoutListener(() =>
      sessionStorage.removeItem(this.currentAuthorityKey),
    );
    this.reload();
  }

  private reload(): void {
    const jsonCurrentAuthority = sessionStorage.getItem(this.currentAuthorityKey);
    this.authority$.next(
      jsonCurrentAuthority === null ? null : (JSON.parse(jsonCurrentAuthority) as RollenEnum),
    );
  }

  public setCurrentAuthority(authority: RollenEnum) {
    sessionStorage.setItem(this.currentAuthorityKey, JSON.stringify(authority));
    this.authority$.next(authority);
  }
}
