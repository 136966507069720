import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from './authentication.service';

export const AUTH_GUARD: CanActivateFn = (route, state) => {
  const authService = inject(AuthenticationService);
  const loggedIn = authService.isLoggedIn();
  if (!loggedIn) {
    authService.login(state.url);
  }
  return loggedIn;
};
