export class StammnummerFormatter {
  public static reformat(newStammnummer) {
    if (newStammnummer) {
      const normalizedStammnummer = this.removeDots(newStammnummer);
      const stammnummerToFormat = this.restrictMaxLength(normalizedStammnummer, 9);
      return this.reformatWithDots(stammnummerToFormat);
    }
    return newStammnummer;
  }

  public static reformatWithLeadingZeros(stammnummer) {
    if (stammnummer && stammnummer.length > 0) {
      const normalizedStammnummer = this.removeDots(stammnummer);
      if (normalizedStammnummer.length < 9) {
        return this.reformatWithDots(this.pad(normalizedStammnummer, 9));
      }
    }
    return stammnummer;
  }

  public static removeDots(input) {
    return input?.replace(/\./g, '');
  }

  private static restrictMaxLength(input, maxLength) {
    if (input.length > maxLength) {
      return input.substring(0, maxLength);
    }
    return input;
  }

  private static reformatWithDots(input) {
    let st = '';
    for (let i = 0; i < input.length; i++) {
      st += input[i];
      if ((i + 1) % 3 === 0 && i < 7) {
        st += '.';
      }
    }
    return st;
  }

  private static pad(num, size) {
    let s = '000000000' + num;
    return s.substring(s.length - size);
  }
}
