import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-forced-logout',
  templateUrl: './forced-logout.component.html',
  standalone: true,
  imports: [TranslateModule, RouterLink],
})
export class ForcedLogoutComponent {}
