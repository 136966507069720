import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[monthSelectorValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MonthSelectorValidator, multi: true }],
  standalone: true,
})
export class MonthSelectorValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    const monthFrom = value?.monthFrom;
    const yearFrom = value?.yearFrom;
    const monthTo = value?.monthTo;
    const yearTo = value?.yearTo;

    if (monthFrom && yearFrom && monthTo && yearTo) {
      const dateFrom = new Date(parseInt(yearFrom), parseInt(monthFrom), 1);
      const dateTo = new Date(parseInt(yearTo), parseInt(monthTo), 1);
      if (dateFrom > dateTo) {
        return { valid: false };
      }
    }
    return null;
  }
}
