import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BenutzerDto,
  FahrzeugDto,
  SchadenmeldungApiService,
  SchadenmeldungDto,
} from '../../core/api';
import { AbfrageResultatService } from '../../abfrage-resultat/abfrage-resultat.service';
import { Router } from '@angular/router';
import { ConfirmService } from '../../core/confirm-modal/confirm.service';
import { TranslateLandPipe } from '../../core/pipes/translate-land.pipe';
import { TranslateWithPrefixPipe } from '../../core/pipes/translate-with-prefix.pipe';
import { TranslateFahrzeugartPipe } from '../../core/pipes/translate-fahrzeugart.pipe';
import { TranslateRegulierungPipe } from '../../core/pipes/translate-regulierung.pipe';
import { TranslateDeckungsartPipe } from '../../core/pipes/translate-deckungsart.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf, DatePipe, AsyncPipe } from '@angular/common';
import RollenEnum = BenutzerDto.RollenEnum;
import AnreicherungsmeldungEnum = FahrzeugDto.AnreicherungsmeldungEnum;
import { AuthorizationService } from '../../core/authentication/authorization.service';

@Component({
  selector: 'app-fahrzeug',
  templateUrl: './fahrzeug.component.html',
  styleUrls: ['./fahrzeug.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    TranslateModule,
    DatePipe,
    TranslateDeckungsartPipe,
    TranslateRegulierungPipe,
    TranslateFahrzeugartPipe,
    TranslateWithPrefixPipe,
    TranslateLandPipe,
    AsyncPipe,
  ],
})
export class FahrzeugComponent {
  public Rolle: typeof RollenEnum = RollenEnum;

  @Input()
  public schadenmeldung: SchadenmeldungDto;

  @Input()
  public fahrzeug: FahrzeugDto;

  @Input()
  public label: string;

  @Input()
  public gegnerischesFahrzeug: boolean = false;

  @Input()
  public collapsible: boolean = false;

  @Input()
  public collapsed: boolean = false;

  @Output()
  public onFahrzeugDeleted = new EventEmitter<SchadenmeldungDto>();

  @Output()
  public onShowBewertungDetails = new EventEmitter<FahrzeugDto>();

  rolle$ = this.authzService.authority$;

  public constructor(
    private readonly abfrageResultatService: AbfrageResultatService,
    private readonly authzService: AuthorizationService,
    private readonly router: Router,
    private readonly schadenmeldungApiService: SchadenmeldungApiService,
    private readonly confirmService: ConfirmService,
  ) {}

  public neueSuche() {
    this.abfrageResultatService.reset();
    this.abfrageResultatService.setStammnummer(this.fahrzeug.stammnummer);
    this.abfrageResultatService.setFahrgestellnummer(this.fahrzeug.fahrgestellnummer);
    this.router.navigate(['/abfragen']);
  }

  public showBewertungDetails() {
    this.onShowBewertungDetails.emit(this.fahrzeug);
  }

  public deleteFahrzeug() {
    this.confirmService
      .confirm({
        title: 'title.fahrzeugloeschenbestaetigen',
        message: 'message.fahrzeugloeschenbestaetigen',
        acceptText: 'label.fahrzeugloeschen',
      })
      .then(
        () => {
          this.schadenmeldungApiService
            .deleteFahrzeug(this.schadenmeldung.id, this.fahrzeug.id)
            .subscribe((updatedSchadenmeldung) => {
              this.onFahrzeugDeleted.emit(updatedSchadenmeldung);
            });
        },
        () => {
          // nothing to do, but prevent the default error handler from firing
        },
      );
  }

  public get erfolgreichBewertet() {
    return this.schadenmeldung?.status === SchadenmeldungDto.StatusEnum.ISPSCORINGERFOLGREICH;
  }

  public get anreicherungsmeldungFehlerhaft() {
    return (
      this.fahrzeug &&
      this.fahrzeug.anreicherungsmeldung &&
      this.isFehlerhaft(this.fahrzeug.anreicherungsmeldung)
    );
  }

  private isFehlerhaft(anreicherungsmeldung: AnreicherungsmeldungEnum) {
    return (
      anreicherungsmeldung === AnreicherungsmeldungEnum.KEINEINDEUTIGESFAHRZEUG ||
      anreicherungsmeldung === AnreicherungsmeldungEnum.ANDEREVERSICHERUNG ||
      anreicherungsmeldung === AnreicherungsmeldungEnum.MEHREREMOEGLICHEFAHRZEUGE ||
      anreicherungsmeldung === AnreicherungsmeldungEnum.TECHNISCHESPROBLEM ||
      anreicherungsmeldung === AnreicherungsmeldungEnum.AUSLAENDISCHESFAHRZEUG
    );
  }
}
