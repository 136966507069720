<div
  class="card nachricht mb-4"
  [ngClass]="{ 'left-side': displayOnTheLeft, 'right-side alert-info': !displayOnTheLeft }">
  <div class="card-body">
    <h5 class="card-title">
      <b>
        <ng-container *ngIf="!nachricht.von">{{ nachricht.kontakt }}</ng-container>
        <ng-container *ngIf="nachricht.von"
          >{{ nachricht.von.vorname }} {{ nachricht.von.nachname }}
          {{ nachricht.von.telefonNr ? '- ' + nachricht.von.telefonNr : '' }}</ng-container
        >
      </b>
      - {{ nachricht.creationDate | date: 'dd.MM.yyyy HH:mm:ss' }}
    </h5>
    <p class="card-text linebreak">{{ nachricht.nachrichtText }}</p>
  </div>
</div>
