import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateGutachten',
  pure: false,
  standalone: true,
})
export class TranslateGutachtenPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(gutachten?: number): unknown {
    return !!gutachten
      ? this.translate.instant('booleanlabelconverter.true')
      : this.translate.instant('booleanlabelconverter.false');
  }
}
