import { Component, OnInit } from '@angular/core';
import { Monat } from '../core/enum/Monat';
import { VersicherungDto } from '../core/api';
import { AuswertungDTO } from '../core/api';
import { AuswertungenApiService } from '../core/api';
import { DatePipe, NgFor, NgIf, NgStyle } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import AuswertungEnum = AuswertungDTO.AuswertungEnum;
import { executeFileDownload } from '../core/util/file-save.service';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { MonthSelectorValidator } from '../core/validator/month-selector-validator';

@Component({
  selector: 'app-auswertungen',
  templateUrl: './auswertungen.component.html',
  styleUrls: ['./auswertungen.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgFor,
    MonthSelectorValidator,
    NgIf,
    NgStyle,
    FullscreenSpinnerComponent,
    TranslateModule,
  ],
})
export class AuswertungenComponent implements OnInit {
  versicherungen: Array<VersicherungDto>;
  auswertungen: Array<AuswertungEnum>;

  monate: Object;
  monateKeys: Array<string>;

  limit = 10;

  selectedVersicherung: VersicherungDto | 'all';
  selectedMonthFrom: number;
  selectedMonthTo: number;

  years: Array<number>;
  selectedYearFrom: number;
  selectedYearTo: number;

  auswertungDto: AuswertungDTO;
  loading = false;

  constructor(
    private auswertungService: AuswertungenApiService,
    private datePipe: DatePipe,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.auswertungService.getOrganisationen().subscribe((resp) => {
      this.versicherungen = resp;
    });
    this.auswertungDto = {
      auswertung: undefined,
      versicherung: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    };
    this.initMonths();
    this.initYears();
    this.initAuswertungen();
    this.initDefaultEntries();
  }

  initMonths() {
    this.monate = Monat;
    this.monateKeys = Object.keys(Monat).filter((f) => !isNaN(Number(f)));
  }

  initYears() {
    var referenceDate = new Date();
    var actualYear = referenceDate.getFullYear();
    var fromYears = new Array<number>();
    fromYears.push(actualYear);

    for (let i = this.limit; i > 0; i--) {
      fromYears.push(actualYear - i);
    }

    this.years = fromYears.sort().reverse();
  }

  initAuswertungen() {
    this.auswertungen = Object.values(AuswertungEnum);
  }

  initDefaultEntries() {
    var actualDate = new Date();
    var referenceDate = new Date();
    referenceDate.setMonth(actualDate.getMonth() - 1);
    this.selectedMonthFrom = referenceDate.getMonth();
    this.selectedMonthTo = referenceDate.getMonth();
    this.selectedYearFrom = referenceDate.getFullYear() - this.limit;
    this.selectedYearTo = referenceDate.getFullYear();
  }

  private formatDate(year: number, month: number): string {
    const date = new Date(year, month, 1);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  resetForm(form: NgForm) {
    this.initDefaultEntries();
    form.resetForm({
      monthSelector: {
        monthTo: this.selectedMonthTo,
        monthFrom: this.selectedMonthTo,
        yearTo: this.selectedYearTo,
        yearFrom: this.selectedYearFrom,
      },
    });
  }

  submitForm() {
    this.loading = true;
    this.auswertungDto.dateFrom = this.formatDate(this.selectedYearFrom, this.selectedMonthFrom);
    this.auswertungDto.dateTo = this.formatDate(this.selectedYearTo, this.selectedMonthTo);
    // @ts-ignore
    this.auswertungDto.versicherung =
      this.selectedVersicherung === 'all' ? null : this.selectedVersicherung;

    this.auswertungService.downloadAuswertung(this.auswertungDto, 'response' as 'body').subscribe({
      next: (res) => {
        this.loading = false;
        executeFileDownload(res, this.getAuswertungCsvName());
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  private getAuswertungCsvName(): string {
    let reportName = 'report_' + this.getTranslatedAuswertungName();
    let timestamp: string = this.datePipe.transform(new Date(), 'yyyyMMdd_HHmm');
    return timestamp + '_' + reportName + '.csv';
  }

  private getTranslatedAuswertungName(): string {
    if (!this.auswertungDto) {
      return '';
    }

    if (this.auswertungDto.auswertung === AuswertungEnum.REGULIERUNG) {
      return this.translateService.instant('auswertung.regulierung.filename');
    } else if (this.auswertungDto.auswertung === AuswertungEnum.DATENQUALITAET) {
      return this.translateService.instant('auswertung.datenqualitaet.filename');
    } else {
      return '';
    }
  }
}
