<div class="container-lg">
  <div class="row">
    <div class="card mb-4 col-12">
      <div class="card-body">
        <h5 class="mb-4" translate>label.filter</h5>
        <hr class="mb-4" />
        <div class="row">
          <div class="col-12 col-md-7 mb-3">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="activeAndOrArchived"
                id="activeOnly"
                [(ngModel)]="loadArchivedKonversationen"
                [value]="false"
                (change)="loadKonversationen()" />
              <label class="form-check-label" for="activeOnly" translate
                >label.meldungenaktiv</label
              >
            </div>

            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="activeAndOrArchived"
                id="activeAndArchived"
                [(ngModel)]="loadArchivedKonversationen"
                [value]="true"
                (change)="loadKonversationen()" />
              <label class="form-check-label" for="activeAndArchived" translate
                >label.meldungenarchiviert</label
              >
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="eigeneOnly"
                [(ngModel)]="loadEigeneOnly"
                (ngModelChange)="loadKonversationen()" />
              <label class="form-check-label" for="eigeneOnly" translate
                >label.nurpersoenlichekorrespondenz</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <h4 class="mb-4" translate>label.inbox</h4>
    <div class="table-responsive">
      <table class="table table-bordered table-sm table-striped">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th sortable="id" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.id</span>
            </th>
            <th sortable="letzteNachricht" [direction]="'desc'" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.datumderletztennachricht</span>
            </th>
            <th sortable="vonVersicherung" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.gesellschaft</span>
            </th>
            <th sortable="schadenNummer" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.schadennummer</span>
            </th>
            <th sortable="status" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.status</span>
            </th>
            <th sortable="deletedInDays" (sort)="onSort($event)">
              <span class="text-nowrap" translate>tableheader.wirdgeloeschtin</span>
            </th>
            <th>
              <span class="text-nowrap">&nbsp;</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let konversation of konversationen"
            [ngClass]="{ 'text-danger': konversationDeletedSoon(konversation) }">
            <td class="text-nowrap align-middle text-body">
              <i
                class="fa fa-edit cursor-pointer"
                title="{{ 'tooltip.detail' | translate }}"
                (click)="showDetails(konversation)"></i>
            </td>
            <td class="text-nowrap align-middle">{{ konversation.id }}</td>
            <td class="text-nowrap align-middle">
              {{ konversation.letzteNachricht | date: 'dd.MM.yyyy' }}
            </td>
            <td class="text-nowrap align-middle">{{ konversation.von.name }}</td>
            <td class="text-nowrap align-middle">
              <a
                class="pl-0 cursor-pointer"
                (click)="showSchadenmeldung(konversation.schadenmeldung)"
                >{{ konversation.schadenmeldung?.schadenNummer }}</a
              >
            </td>
            <td class="text-nowrap align-middle">
              {{ 'enum.postboxstatus.' + konversation.status | translate }}
            </td>
            <td class="text-nowrap align-middle">
              {{ konversation.deletedInDays }}&nbsp;{{ 'label.postboxtagen' | translate }}
            </td>
            <td class="text-nowrap align-middle">
              <input
                type="checkbox"
                [(ngModel)]="konversation.erledigt"
                [disabled]="konversation.status === KonversationStatus.ARCHIVIERT" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row d-flex justify-content-end">
    <div class="col-12 col-sm-6 col-lg-4 mt-2 pr-0">
      <button
        type="submit"
        class="btn btn-primary form-control"
        [disabled]="!anyErledigt"
        (click)="erledigen()"
        title="{{ 'tooltip.archivieren' | translate }}"
        translate>
        label.archivieren
      </button>
    </div>
  </div>

  <div class="row">
    <div class="alert alert-info mt-4 col-12" role="alert" translate>
      label.postboxkorrespondenzhinweis
    </div>
  </div>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
