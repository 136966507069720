import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import { take } from 'rxjs/operators';
import { map } from 'rxjs';

export const ROLE_GUARD: CanActivateFn = (route, state) => {
  const authzService = inject(AuthorizationService);
  const router = inject(Router);
  const { allowedRoles } = route.data;

  return authzService.authority$.pipe(
    take(1),
    map((rolle) => {
      if (allowedRoles && !allowedRoles.includes(rolle)) {
        router.navigate(['access-denied']);
        return false;
      } else {
        return true;
      }
    }),
  );
};
