/**
 * Represents the regulierung as returned by riskshield (SchadenmeldungHistory). Only necessary so we know all the values
 * we can display in the table filter.
 */
export enum Regulierung {
  CASH = 1,
  REPARATUR = 2,
  TOTALSCHADEN = 3,
  UNBEKANNT = 4,
}
