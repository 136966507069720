import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmState } from './confirm-state';
import { ConfirmOptions } from './confirm-options';

@Injectable({
  providedIn: 'root',
})
export class ConfirmService {
  constructor(
    private modalService: NgbModal,
    private state: ConfirmState,
  ) {}

  public confirm(options: ConfirmOptions): Promise<any> {
    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template, { centered: true });
    return this.state.modal.result;
  }
}
