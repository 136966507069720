import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router, RouterLink } from '@angular/router';
import { BenutzerDto } from '../api';
import { TranslateRollePipe } from '../pipes/translate-rolle.pipe';
import { FullscreenSpinnerComponent } from '../util/fullscreen-spinner/fullscreen-spinner.component';
import { FormsModule } from '@angular/forms';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { BenutzerService } from '../benutzer/benutzer.service';
import { AuthorizationService } from '../authentication/authorization.service';
import RollenEnum = BenutzerDto.RollenEnum;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    TranslateModule,
    NgIf,
    FormsModule,
    NgFor,
    FullscreenSpinnerComponent,
    TranslateRollePipe,
    AsyncPipe,
  ],
})
export class HeaderComponent {
  @Input() public authorized: boolean;
  public loading = false;
  public benutzer$ = this.benutzerService.benutzer$;
  public rolle$ = this.authorizationService.authority$;
  private aktuellerBenutzer: BenutzerDto;
  public aktiveRolle: RollenEnum;

  constructor(
    public readonly authenticationService: AuthenticationService,
    public readonly authorizationService: AuthorizationService,
    private readonly benutzerService: BenutzerService,
    private readonly translate: TranslateService,
    private readonly router: Router,
  ) {
    this.rolle$.subscribe((rolle) => {
      this.aktiveRolle = rolle;
    });
    this.benutzer$.subscribe((benutzer) => {
      this.aktuellerBenutzer = benutzer;
    });
  }

  public onAktiveRolleChange($event: RollenEnum): void {
    this.authorizationService.setCurrentAuthority($event);
    this.router.navigate(['abfragen']);
  }

  public changeLanguage(newLanguage: string) {
    this.translate.use(newLanguage);
  }

  public goToAdministration() {
    if (!this.aktuellerBenutzer) {
      this.router.navigate(['/login']);
    } else if (this.aktiveRolle == RollenEnum.SVV || this.aktiveRolle == RollenEnum.VGADMIN) {
      this.router.navigate(['/administration-uebersicht']);
    } else {
      this.router.navigate(['/konto-verwalten']);
    }
  }

  public logout() {
    this.doLogout();
  }

  private doLogout() {
    this.loading = true;
    this.authenticationService.logout();
  }
}
