import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppConfig } from '../../../app-config';

import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly loginSubject: Subject<OAuthEvent> = new Subject<OAuthEvent>();
  private readonly logoutSubject: Subject<void> = new Subject<void>();

  constructor(
    private readonly oAuthService: OAuthService,
    private router: Router,
    appConfig: AppConfig,
  ) {
    oAuthService.configure(AuthenticationService.buildConfig(appConfig.authConfig));
    oAuthService.setupAutomaticSilentRefresh();

    this.oAuthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => this.router.initialNavigation());

    this.oAuthService.events
      .pipe(
        filter((e) => e.type === 'token_received'),
        take(1),
      )
      .subscribe((e) => {
        this.loginSubject.next(e);
      });

    // we have an invalid nonce, when there is a break in the flow (ex. pw change)
    // there we need to restart the login flow completely
    this.oAuthService.events
      .pipe(
        filter((e) => e.type === 'invalid_nonce_in_state'),
        take(1),
      )
      .subscribe((e) => {
        this.oAuthService.initLoginFlow();
      });

    this.addLoginListener(() => this.redirectAfterLogin());
  }

  private redirectAfterLogin() {
    const redirectPath = this.oAuthService.state;
    if (redirectPath) {
      void this.router.navigateByUrl(decodeURIComponent(redirectPath), { replaceUrl: true });
    }
  }

  private static buildConfig(authConfig: AuthConfig): AuthConfig {
    const copy = { ...authConfig };
    copy.responseType = authConfig.responseType || 'code';
    copy.scope = authConfig.scope || 'openid profile email';
    copy.redirectUri = authConfig.redirectUri || `${window.location.origin}/index.html`;
    // If we are in the SSO flow, we need to add the kc_idp_hint to the query params
    if (window.location && window.location.pathname.startsWith('/idp')) {
      copy.customQueryParams = authConfig.customQueryParams || {
        kc_idp_hint: window.location.pathname.split('/').at(-1),
      };
    }
    return copy;
  }

  login(redirectPath?: string): void {
    this.oAuthService.initCodeFlow(redirectPath);
  }

  logout(): void {
    void this.oAuthService.revokeTokenAndLogout().finally(() => this.logoutSubject.next());
  }

  isLoggedIn(): boolean {
    return this.oAuthService.hasValidIdToken();
  }

  get username(): string {
    return (this.oAuthService.getIdentityClaims() as { [index: string]: string })['name'];
  }

  addLoginListener(listener: () => void): void {
    if (this.isLoggedIn()) {
      listener();
    }
    this.loginSubject.subscribe(listener);
  }

  addLogoutListener(listener: () => void): void {
    this.logoutSubject.subscribe(listener);
  }
}
