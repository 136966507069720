import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { StringStandardizer } from '../util/string-standardizer';

export type HeaderSortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: HeaderSortDirection } = { asc: 'desc', desc: '', '': 'asc' };
export const compare = (v1, v2) => {
  if (typeof v1 === 'string') {
    const s1 = StringStandardizer.standardize(v1);
    const s2 = StringStandardizer.standardize(v2);
    if (s1 && !s2) {
      return -1;
    } else if (!s1 && s2) {
      return 1;
    }
    return s1 < s2 ? -1 : s1 > s2 ? 1 : 0;
  } else if (typeof v1 === 'number' || typeof v2 === 'number') {
    const n1 = Number(v1);
    const n2 = Number(v2);
    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return n1 - n2;
    } else if (typeof v1 === 'number') {
      return -1;
    } else {
      return 1;
    }
  } else {
    if (v1 && !v2) {
      return -1;
    } else if (!v1 && v2) {
      return 1;
    }
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }
};

export const compareDate = (v1, v2) => {
  return !v1 && v2 ? 1 : v1 && !v2 ? -1 : compare(v1, v2);
};

export interface SortEvent {
  column: string;
  direction: HeaderSortDirection;
}

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'th[sortable],span[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '(click)': 'rotate()',
  },
  standalone: true,
})
export class SortableHeaderDirective implements OnInit {
  @Input()
  public sortable: string;

  @Input()
  public direction: HeaderSortDirection = '';

  @Output()
  public sort = new EventEmitter<SortEvent>();

  public ngOnInit() {
    if (this.direction) {
      this.sort.emit({ column: this.sortable, direction: this.direction });
    } else if (this.direction === undefined) {
      this.direction = '';
    }
  }

  public rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}
