import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminVersicherungDto, BenutzerDto, StammdatenApiService } from '../../core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import RollenEnum = BenutzerDto.RollenEnum;
import { Subscription } from 'rxjs';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SliderComponent } from '../../core/slider/slider.component';
import { BenutzerService } from '../../core/benutzer/benutzer.service';
import { AsyncPipe, NgIf } from '@angular/common';
import { AuthorizationService } from '../../core/authentication/authorization.service';

@Component({
  selector: 'app-administration-ubersicht',
  templateUrl: './administration-ubersicht.component.html',
  styleUrls: ['./administration-ubersicht.component.scss'],
  standalone: true,
  imports: [TranslateModule, SliderComponent, FormsModule, RouterLink, AsyncPipe, NgIf],
})
export class AdministrationUbersichtComponent implements OnInit, OnDestroy {
  public Rolle: typeof RollenEnum = RollenEnum;
  public loading = false;

  currentUser: BenutzerDto;
  vesicherung: AdminVersicherungDto = <AdminVersicherungDto>{};
  scorePoints: number;
  private subscription: Subscription;
  rolle$ = this.authzService.authority$;

  constructor(
    private benutzerService: BenutzerService,
    private authzService: AuthorizationService,
    private stammdatenService: StammdatenApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.benutzerService.benutzer$.subscribe((currentUser) => {
      if (currentUser) {
        this.currentUser = currentUser;
        this.stammdatenService
          .getAdminVersicherungDto(currentUser.versicherung.id)
          .subscribe((resp) => {
            this.vesicherung = resp;
            this.scorePoints = resp.scoreLimit;
          });
      }
    });
  }

  changeScores(event) {
    this.scorePoints = event;
  }

  validateRange() {
    let acctValue = this.scorePoints;

    if (!acctValue || isNaN(acctValue) || Number(acctValue) < 0) {
      this.scorePoints = 0;
    } else if (Number(acctValue) > 100) {
      this.scorePoints = 100;
    } else {
      this.scorePoints = acctValue;
    }
  }

  saveScoreLimit() {
    this.loading = true;
    this.stammdatenService.saveScoreLimit(this.scorePoints).subscribe(
      (res) => {
        this.loading = false;
        this.toastr.success(this.translate.instant('message.scoringlimitgespeichert'));
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
