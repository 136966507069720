export * from './abfrageApi.service';
import { AbfrageApiService } from './abfrageApi.service';
export * from './appConfigApi.service';
import { AppConfigApiService } from './appConfigApi.service';
export * from './auswertungenApi.service';
import { AuswertungenApiService } from './auswertungenApi.service';
export * from './benutzerApi.service';
import { BenutzerApiService } from './benutzerApi.service';
export * from './postboxApi.service';
import { PostboxApiService } from './postboxApi.service';
export * from './schadenmeldungApi.service';
import { SchadenmeldungApiService } from './schadenmeldungApi.service';
export * from './stammdatenApi.service';
import { StammdatenApiService } from './stammdatenApi.service';
export * from './versicherungApi.service';
import { VersicherungApiService } from './versicherungApi.service';
export * from './versionApi.service';
import { VersionApiService } from './versionApi.service';
export const APIS = [AbfrageApiService, AppConfigApiService, AuswertungenApiService, BenutzerApiService, PostboxApiService, SchadenmeldungApiService, StammdatenApiService, VersicherungApiService, VersionApiService];
