import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BewertungDetailsService } from './bewertung-details.service';
import { FahrzeugDto, SchadenHistoryDto } from '../core/api';
import { compare, SortableHeaderDirective, SortEvent } from '../core/directives/sortable-header';
import { Schadenart } from '../core/enum/Schadenart';
import { Regulierung } from '../core/enum/Regulierung';
import { Deckungsart } from '../core/enum/Deckungsart';
import { TableFilterService } from '../core/util/table-filter.service';
import { Router } from '@angular/router';
import { TranslateSchadenartPipe } from '../core/pipes/translate-schadenart.pipe';
import { TranslateDeckungsartPipe } from '../core/pipes/translate-deckungsart.pipe';
import { TranslateRegulierungPipe } from '../core/pipes/translate-regulierung.pipe';
import { TableFilterMatch } from '../core/util/table-filter-match';
import { TranslateLandPipe } from '../core/pipes/translate-land.pipe';
import { TranslateFahrzeugartPipe } from '../core/pipes/translate-fahrzeugart.pipe';
import { TranslateGutachtenPipe } from '../core/pipes/translate-gutachten.pipe';
import { YesNoPipe } from '../core/pipes/yes-no.pipe';
import { NgFor, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-bewertung-details',
  templateUrl: './bewertung-details.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    SortableHeaderDirective,
    FormsModule,
    NgFor,
    DatePipe,
    YesNoPipe,
    TranslateDeckungsartPipe,
    TranslateGutachtenPipe,
    TranslateSchadenartPipe,
    TranslateRegulierungPipe,
    TranslateFahrzeugartPipe,
    TranslateLandPipe,
  ],
})
export class BewertungDetailsComponent implements OnInit {
  public alleSchadenarten: (string | Schadenart)[] = Object.entries(Schadenart)
    .map(([, enumValue]) => enumValue)
    .filter((e) => typeof e === 'number');
  public alleRegulierungen: (string | Regulierung)[] = Object.entries(Regulierung)
    .map(([, enumValue]) => enumValue)
    .filter((e) => typeof e === 'number');
  public alleDeckungsarten: (string | Deckungsart)[] = Object.entries(Deckungsart)
    .map(([, enumValue]) => enumValue)
    .filter((e) => typeof e === 'number');

  public fahrzeug: FahrzeugDto;
  public alleHistory: SchadenHistoryDto[];
  public history: SchadenHistoryDto[];

  public tableFilter = {
    schadenNummer: undefined,
    schadenArt: undefined,
    schadenHoeheFz: undefined,
    gutachten: undefined,
    kmStand: undefined,
    deckungsArt: undefined,
    regulierung: undefined,
    versicherung: undefined,
    scored: undefined,
  };
  public dateFilter = {
    schadenDatum: {
      value: undefined,
      pattern: 'dd.MM.yyyy',
    },
  };
  private filterOptions = {
    schadenArt: {
      match: TableFilterMatch.EXACT,
    },
    gutachten: {
      match: TableFilterMatch.EXACT,
    },
    regulierung: {
      match: TableFilterMatch.EXACT,
    },
    deckungsArt: {
      match: TableFilterMatch.EXACT,
    },
    scored: {
      match: TableFilterMatch.EXACT,
    },
  };

  @ViewChildren(SortableHeaderDirective)
  private headers: QueryList<SortableHeaderDirective>;

  constructor(
    private bewertungDetailsService: BewertungDetailsService,
    private tableFilterService: TableFilterService,
    private translateSchadenartPipe: TranslateSchadenartPipe,
    private translateDeckungsartPipe: TranslateDeckungsartPipe,
    private translateRegulierungPipe: TranslateRegulierungPipe,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.fahrzeug = this.bewertungDetailsService.getSelectedFahrzeug();
    this.alleHistory = this.history = this.bewertungDetailsService.getSchadenHistory();
    if (!this.alleHistory || this.alleHistory.length === 0) {
      this.back();
    }
  }

  public onSearch() {
    this.history = this.tableFilterService.filter(
      this.alleHistory,
      this.tableFilter,
      this.dateFilter,
      this.filterOptions,
    );
  }

  public back() {
    this.router.navigate(['/schaden-details']);
  }

  public onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    });

    this.history = this.tableFilterService.filter(
      this.alleHistory.sort((a: SchadenHistoryDto, b: SchadenHistoryDto) => {
        let res = 0;
        switch (sortEvent.column) {
          case 'schadenArt':
            res = compare(
              this.translateSchadenartPipe.transform(a?.schadenArt),
              this.translateSchadenartPipe.transform(b?.schadenArt),
            );
            break;
          case 'regulierung':
            res = compare(
              this.translateRegulierungPipe.transform(a?.regulierung),
              this.translateRegulierungPipe.transform(b?.regulierung),
            );
            break;
          case 'deckungsArt':
            res = compare(
              this.translateDeckungsartPipe.transform(a?.deckungsArt),
              this.translateDeckungsartPipe.transform(b?.deckungsArt),
            );
            break;
          default:
            res = compare(a[sortEvent.column], b[sortEvent.column]);
            break;
        }
        return sortEvent.direction === 'asc' ? res : -res;
      }),
      this.tableFilter,
      this.dateFilter,
      this.filterOptions,
    );
  }
}
