/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VersicherungDto } from './versicherungDto';


export interface BenutzerDto { 
    id?: number;
    openId?: string;
    anrede?: BenutzerDto.AnredeEnum;
    nachname?: string;
    vorname?: string;
    email: string;
    telefonNr?: string;
    rollen?: Array<BenutzerDto.RollenEnum>;
    letztesLogin?: string;
    erstelltAm?: string;
    sprache?: BenutzerDto.SpracheEnum;
    versicherung?: VersicherungDto;
}
export namespace BenutzerDto {
    export type AnredeEnum = 'MR' | 'MS';
    export const AnredeEnum = {
        MR: 'MR' as AnredeEnum,
        MS: 'MS' as AnredeEnum
    };
    export type RollenEnum = 'BVM' | 'SACHBEARBEITER' | 'WEBSERVICE' | 'VG_ADMIN' | 'SVV';
    export const RollenEnum = {
        BVM: 'BVM' as RollenEnum,
        SACHBEARBEITER: 'SACHBEARBEITER' as RollenEnum,
        WEBSERVICE: 'WEBSERVICE' as RollenEnum,
        VGADMIN: 'VG_ADMIN' as RollenEnum,
        SVV: 'SVV' as RollenEnum
    };
    export type SpracheEnum = 'DE' | 'FR';
    export const SpracheEnum = {
        DE: 'DE' as SpracheEnum,
        FR: 'FR' as SpracheEnum
    };
}


