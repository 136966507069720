import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  numberAttribute,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class SliderComponent implements OnChanges, AfterContentChecked {
  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef<HTMLInputElement>;

  @ViewChild('selectedValue', { static: true })
  selectedValue: ElementRef<HTMLElement>;

  @Input({ transform: numberAttribute })
  minValue: number = 0;

  @Input({ transform: numberAttribute })
  maxValue: number = 100;

  @Input()
  value: number = 0;

  @Output()
  changeValueEvent = new EventEmitter<number>();

  pmax: number;
  pmin: number;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes['value'].currentValue ||
      isNaN(changes['value'].currentValue) ||
      Number(changes['value'].currentValue) < this.minValue
    ) {
      this.value = this.minValue;
    } else if (Number(changes['value'].currentValue) > this.maxValue) {
      this.value = this.maxValue;
    }
  }

  ngAfterContentChecked(): void {
    this.updatePosition();
  }

  input(event) {
    this.value = event.target.value;
    this.inputElement.nativeElement.value = this.value.toString();
    this.updatePosition();
    this.changeValueEvent.emit(this.value);
  }

  updatePosition(): void {
    this.pmin = document.getElementById('minValue').getBoundingClientRect().left;
    this.pmax = document.getElementById('maxValue').getBoundingClientRect().left;
    let f = (this.pmax - this.pmin) / (this.maxValue - this.minValue);
    let left = Math.round((this.value - this.minValue) * f + 10);
    let prec = ((this.value - this.minValue) * 100) / (this.maxValue - this.minValue);

    this.renderer.setStyle(this.selectedValue.nativeElement, 'left', left.toString() + 'px');
    this.renderer.setStyle(
      this.inputElement.nativeElement,
      'background',
      'linear-gradient(90deg,#979aca ' + prec + '%, #e9ecef ' + prec + '%)',
    );
  }
}
