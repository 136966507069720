import { Routes } from '@angular/router';
import { AbfragenComponent } from './abfragen/abfragen.component';
import { AUTH_GUARD } from './core/authentication/auth.guard';
import { AbfrageResultatComponent } from './abfrage-resultat/abfrage-resultat.component';
import { TopScoreResultatComponent } from './top-score-resultat/top-score-resultat.component';

import { SchadenDetailComponent } from './schaden-detail/schaden-detail.component';
import { BewertungDetailsComponent } from './bewertung-details/bewertung-details.component';
import { PostboxComponent } from './postbox/postbox.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { KonversationComponent } from './postbox/konversation/konversation.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { HilfeComponent } from './hilfe/hilfe.component';
import { BenutzerlisteComponent } from './benutzerverwaltung/benutzerliste/benutzerliste.component';
import { CreateBenutzerComponent } from './benutzerverwaltung/create-benutzer/create-benutzer.component';
import { EditBenutzerComponent } from './benutzerverwaltung/edit-benutzer/edit-benutzer.component';
import { OrganisationenComponent } from './organisationen/organisationen.component';
import { AdministrationUbersichtComponent } from './administration/administration-ubersicht/administration-ubersicht.component';
import { KontoVerwaltungComponent } from './benutzerverwaltung/konto-verwaltung/konto-verwaltung.component';
import { ForcedLogoutComponent } from './forced-logout/forced-logout.component';
import { BenutzerDto } from './core/api';
import RollenEnum = BenutzerDto.RollenEnum;
import { UserByIdResolver } from './core/resolvers/user-by-id.resolver';
import { AuswertungenComponent } from './auswertungen/auswertungen.component';
import { RollenauswahlComponent } from './rollenauswahl/rollenauswahl.component';
import { PendingChangesGuard } from './core/guards/pending-changes.guard';
import { TokenInfoComponent } from './token-info/token-info.component';
import { ROLE_GUARD } from './core/authentication/role.guard';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'abfragen',
  },
  {
    path: 'rollenauswahl',
    component: RollenauswahlComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'abfragen',
    component: AbfragenComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'resultate',
    component: AbfrageResultatComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'benutzerverwaltung',
    component: BenutzerlisteComponent,
    canActivate: [AUTH_GUARD, ROLE_GUARD],
    data: {
      allowedRoles: [RollenEnum.SVV, RollenEnum.VGADMIN],
    },
  },
  {
    path: 'benutzer-erstellen',
    component: CreateBenutzerComponent,
    canActivate: [AUTH_GUARD, ROLE_GUARD],
    data: {
      allowedRoles: [RollenEnum.SVV, RollenEnum.VGADMIN],
    },
  },
  {
    path: 'benutzer-update/:id',
    component: EditBenutzerComponent,
    canActivate: [AUTH_GUARD],
    resolve: {
      user: UserByIdResolver,
    },
  },
  {
    path: 'konto-verwalten',
    component: KontoVerwaltungComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'top-score-resultat',
    component: TopScoreResultatComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'schaden-details',
    component: SchadenDetailComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'bewertung-details',
    component: BewertungDetailsComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'organisationen',
    component: OrganisationenComponent,
    canActivate: [AUTH_GUARD],
    data: {
      allowedRoles: [RollenEnum.SVV],
    },
  },
  {
    path: 'postbox',
    component: PostboxComponent,
    canActivate: [AUTH_GUARD, ROLE_GUARD],
    data: {
      allowedRoles: [RollenEnum.BVM],
    },
  },
  {
    path: 'auswertungen',
    component: AuswertungenComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'konversation',
    component: KonversationComponent,
    canActivate: [AUTH_GUARD, ROLE_GUARD],
    data: {
      allowedRoles: [RollenEnum.BVM],
    },
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'administration-uebersicht',
    component: AdministrationUbersichtComponent,
    canActivate: [AUTH_GUARD, ROLE_GUARD],
    data: {
      allowedRoles: [RollenEnum.SVV, RollenEnum.VGADMIN],
    },
  },
  {
    path: 'token-info',
    component: TokenInfoComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'hilfe',
    component: HilfeComponent,
    canActivate: [AUTH_GUARD],
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'forced-logout',
    component: ForcedLogoutComponent,
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
  },
  {
    path: 'impressum',
    component: ImpressumComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'abfragen',
  },
];
