/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface VersicherungDto { 
    id?: number;
    name?: string;
    aktiv?: boolean;
    activeGsCode?: string;
    inactiveGsCodes?: Array<string>;
    typ?: VersicherungDto.TypEnum;
    authTyp?: VersicherungDto.AuthTypEnum;
    fileImportEncoding?: string;
    emailPostbox?: string;
}
export namespace VersicherungDto {
    export type TypEnum = 'VERSICHERUNGSGESELLSCHAFT' | 'SVV';
    export const TypEnum = {
        VERSICHERUNGSGESELLSCHAFT: 'VERSICHERUNGSGESELLSCHAFT' as TypEnum,
        SVV: 'SVV' as TypEnum
    };
    export type AuthTypEnum = 'IDP' | 'TWOFA';
    export const AuthTypEnum = {
        IDP: 'IDP' as AuthTypEnum,
        TWOFA: 'TWOFA' as AuthTypEnum
    };
}


