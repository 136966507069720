import { Component } from '@angular/core';
import { SafeHtmlPipe } from '../core/pipes/safe-html.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  standalone: true,
  imports: [TranslateModule, SafeHtmlPipe],
})
export class DisclaimerComponent {
  constructor() {}
}
