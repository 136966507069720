import { Component } from '@angular/core';
import { ConfirmState } from './confirm-state';
import { ConfirmOptions } from './confirm-options';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal-component',
  templateUrl: './confirm-modal.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class ConfirmModalComponent {
  public options: ConfirmOptions;

  constructor(private state: ConfirmState) {
    this.options = state.options;
  }

  public accept() {
    this.state.modal.close('accepted');
  }

  public dismiss() {
    this.state.modal.dismiss('dismissed');
  }
}
