import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbfrageResultatService } from '../abfrage-resultat/abfrage-resultat.service';
import { SchadenmeldungApiService, SchadenmeldungDto } from '../core/api';
import { Router } from '@angular/router';
import { compare, SortableHeaderDirective, SortEvent } from '../core/directives/sortable-header';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SchadenDetailService } from '../schaden-detail/schaden-detail.service';
import { SchadenDetailNavigationSource } from '../schaden-detail/schaden-detail-navigation-source';
import { TranslateFahrzeugartPipe } from '../core/pipes/translate-fahrzeugart.pipe';
import { TranslateSchadenartPipe } from '../core/pipes/translate-schadenart.pipe';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { FormsModule } from '@angular/forms';
import { NgFor, DatePipe } from '@angular/common';

@Component({
  selector: 'app-top-score-resultat',
  templateUrl: './top-score-resultat.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    SortableHeaderDirective,
    NgFor,
    FormsModule,
    FullscreenSpinnerComponent,
    DatePipe,
    TranslateSchadenartPipe,
    TranslateFahrzeugartPipe,
  ],
})
export class TopScoreResultatComponent implements OnInit {
  public result: SchadenmeldungDto[];
  public loading = false;

  @ViewChildren(SortableHeaderDirective)
  private headers: QueryList<SortableHeaderDirective>;

  constructor(
    private abfrageResultatService: AbfrageResultatService,
    private schadenmeldungApiService: SchadenmeldungApiService,
    private schadenDetailService: SchadenDetailService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private translateFahrzeugartPipe: TranslateFahrzeugartPipe,
    private translateSchadenartPipe: TranslateSchadenartPipe,
  ) {}

  public ngOnInit(): void {
    this.initializeData();
  }

  private initializeData() {
    this.result = this.abfrageResultatService.getTopScoreResult();
    if (!this.result || this.result.length === 0) {
      this.toastr.warning(this.translate.instant('message.notopscoringresult'));
      this.router.navigate(['/abfragen']);
    }
  }

  public onChangeGeprueft(schadenmeldung: SchadenmeldungDto) {
    this.schadenmeldungApiService
      .updateGeprueft(schadenmeldung.id, schadenmeldung.geprueft)
      .subscribe(() => {
        this.toastr.success(this.translate.instant('message.aenderungen.gespeichert'));
      });
  }

  public get anyErledigt() {
    return !this.result || this.result.some((s) => s.erledigt);
  }

  public erledigen() {
    const erledigteSchadenmeldungen = this.result.filter((s) => s.erledigt).map((s) => s.id);
    this.loading = true;
    this.schadenmeldungApiService
      .updateErledigt({
        schadenmeldungIds: erledigteSchadenmeldungen,
      })
      .subscribe(
        () => {
          this.toastr.success(this.translate.instant('message.topscoreschaeden.erledigt'));
          this.abfrageResultatService.refreshTopResults().then(() => {
            this.initializeData();
            this.loading = false;
          });
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
  }

  public displaySchadenDetails(index: number) {
    this.schadenDetailService.setSchadenDetails(
      this.result,
      SchadenDetailNavigationSource.TOP_SCORE,
    );
    this.schadenDetailService.selectSchadenDetails(index);
    this.router.navigate(['/schaden-details']);
  }

  public onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    });

    this.result = this.result.sort((a, b) => {
      let res = 0;
      switch (sortEvent.column) {
        case 'score':
          res = compare(a.score, b.versicherung?.name);
          break;
        case 'schadenart':
          res = compare(
            this.translateSchadenartPipe.transform(a.schadenart),
            this.translateSchadenartPipe.transform(b.schadenart),
          );
          break;
        case 'fahrzeugArt':
          res = compare(
            this.translateFahrzeugartPipe.transform(a.versichertesFahrzeug?.fzArt),
            this.translateFahrzeugartPipe.transform(b.versichertesFahrzeug?.fzArt),
          );
          break;
        case 'kanton':
          res = compare(
            a.versichertesFahrzeug?.kantonsKuerzel,
            b.versichertesFahrzeug?.kantonsKuerzel,
          );
          break;
        case 'markeTyp':
          res = compare(a.versichertesFahrzeug?.markeTyp, b.versichertesFahrzeug?.markeTyp);
          break;
        default:
          res = compare(a[sortEvent.column], b[sortEvent.column]);
          break;
      }
      return sortEvent.direction === 'asc' ? res : -res;
    });
  }
}
