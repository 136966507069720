/**
 * Represents the schadenart as returned by riskshield (SchadenmeldungHistory). Only necessary so we know all the values
 * we can display in the table filter.
 */
export enum Schadenart {
  MFH_SCHADEN = 1,
  BOESWILLIGE_BESCHAEDIGUNG = 2,
  FEUER = 3,
  DIEBSTAHL = 4,
  GLASBRUCH = 5,
  KOLLISION_MIT_FAHRZEUG = 6,
  KOLLISION_OHNE_FAHRZEUG = 7,
  MARDERSCHADEN = 8,
  PARKSCHADEN = 9,
  TIERSCHADEN = 10,
  HAGELSCHADEN = 11,
  SCHNEESCHADEN = 12,
  UEBERSCHWEMMUNG_HOCHWASSER = 13,
  ERDRUTSCH_STEINSCHLAG_FELSSTURZ = 14,
  STURMWIND = 15,
  NGF = 16,
  PRIVATHAFTPFLICHT = 17,
  DIEBSTAHL_AUS_FAHRZEUG = 18,
  DIEBSTAHL_DES_FAHRZEUGES = 19,
}
