/**
 * Represents the deckungsart as returned by riskshield (SchadenmeldungHistory). Only necessary so we know all the values
 * we can display in the table filter.
 */
export enum Deckungsart {
  MFH = 1,
  MFK = 2,
  PRIVATHAFTPFLICHT = 3,
  NGF = 4,
  UNTERNEHMENSSACHVERSICHERUNG = 5,
}
