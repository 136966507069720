import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Trims strings when they are pasted or dropped to an input field.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[trim]',
  standalone: true,
})
export class TrimDirective {
  private inputElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
  ) {
    this.inputElement = el.nativeElement;
  }

  // trim when pasting a text
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const inputString = event.clipboardData.getData('text/plain');

    const trimmedInput = inputString ? inputString.trim() : inputString;
    if (trimmedInput) {
      this.ngControl.control.setValue(trimmedInput);
    }
  }

  // prevent invalid characters from being dropped (e.g. from another input field)
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    const inputString = event.dataTransfer.getData('text');
    this.inputElement.focus();

    const trimmedInput = inputString ? inputString.trim() : inputString;
    if (trimmedInput) {
      this.ngControl.control.setValue(trimmedInput);
    }
  }
}
