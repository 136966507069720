/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VersicherungDto } from './versicherungDto';


export interface AuswertungDTO { 
    auswertung: AuswertungDTO.AuswertungEnum;
    versicherung?: VersicherungDto;
    dateFrom: string;
    dateTo: string;
}
export namespace AuswertungDTO {
    export type AuswertungEnum = 'DATENQUALITAET' | 'REGULIERUNG';
    export const AuswertungEnum = {
        DATENQUALITAET: 'DATENQUALITAET' as AuswertungEnum,
        REGULIERUNG: 'REGULIERUNG' as AuswertungEnum
    };
}


