import { Injectable } from '@angular/core';
import { StringStandardizer } from './string-standardizer';
import { DatePipe } from '@angular/common';
import { TableFilterMatch } from './table-filter-match';

@Injectable({
  providedIn: 'root',
})
export class TableFilterService {
  public constructor(private datePipe: DatePipe) {}

  /**
   * Iterates over the array to filter and returns a new array containing only the elements which match the filter object.
   * The properties of the object which are filtered can be string (filtered case-insensitive) or arrays.
   * Example:
   * <pre>
   * var users = [
   *  { 'givenName': 'barney', 'roles': [Role.Expert] },
   *  { 'givenName': 'fred',   'roles': [Role.BranchOffice] }
   * ];
   * TableFilterService.filter(users, {
   *  givenName: 'arn'
   * });
   * // => returns objects for ['barney']
   * </pre>
   *
   * <pre>
   * var users = [
   *  { 'givenName': 'barney', 'roles': [Role.Expert] },
   *  { 'givenName': 'fred',   'roles': [Role.BranchOffice] }
   * ];
   * TableFilterService.filter(users, {
   *  roles: Role.BranchOffice
   * });
   * // => returns objects for ['fred']
   * </pre>
   *
   * <pre>
   *   // only match givenName if the string matches exactly. firstName matches if the value contains the search term
   *   var options = {
   *     givenName: {
   *       match: TableFilterMatch.EXACT
   *     },
   *     firstName: {
   *       match: TableFilterMatch.CONTAINING
   *     }
   *   };
   * </pre>
   * @param listToFilter A list of objects to filter
   * @param filter An object containing the filters
   * @param dateFilter An object containing date filters. Every property is expected to be an object with a value and a pattern property
   * @param options An object defining more fine-grained options per filter key. e.g. whether strings should be matched exactly or containing. Defaults to match containing.
   */
  public filter(listToFilter: object[], filter: object, dateFilter?: object, options?: object) {
    return listToFilter.filter((item) => {
      let match = true;
      if (dateFilter) {
        for (const key in dateFilter) {
          if (Object.prototype.hasOwnProperty.call(dateFilter, key)) {
            match = match && this.matchAsDate(item, key, dateFilter);
          }
        }
      }
      for (const key in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
          if (Array.isArray(item[key])) {
            match = match && this.matchAsArray(item, key, filter);
          } else if (typeof item[key] === 'boolean' || typeof filter[key] === 'boolean') {
            match = match && this.matchAsBoolean(item, key, filter);
          } else if (typeof item[key] === 'number' || typeof filter[key] === 'number') {
            match = match && this.matchAsNumber(item, key, filter, options);
          } else if (typeof item[key] === 'string' || typeof filter[key] === 'string') {
            match = match && this.matchAsString(item, key, filter, options);
          } else {
            match = match && this.matchAsUnknown(item, key, filter);
          }
        }
      }
      return match;
    });
  }

  private matchAsDate(item: object, key: string, dateFilter: object) {
    const filterText = dateFilter[key].value;
    const pattern = dateFilter[key].pattern;
    return (
      !filterText ||
      (item[key] && this.datePipe.transform(item[key], pattern).indexOf(filterText) > -1)
    );
  }

  private matchAsString(item: object, key: string, filter: object, options?: object): boolean {
    const noSearchTerm = !filter[key];
    const standardizedValue = StringStandardizer.standardize(item[key]).toLowerCase();
    const standardizedSearchTerm = StringStandardizer.standardize(filter[key]).toLowerCase();
    if (
      options &&
      options[key] &&
      options[key]['match'] &&
      options[key]['match'] === TableFilterMatch.EXACT
    ) {
      return noSearchTerm || standardizedValue === standardizedSearchTerm;
    } else {
      return noSearchTerm || standardizedValue.indexOf(standardizedSearchTerm) > -1;
    }
  }

  private matchAsNumber(item: object, key: string, filter: object, options?: object): boolean {
    const noSearchTerm = filter[key] === undefined || filter[key] === null;
    if (
      options &&
      options[key] &&
      options[key]['match'] &&
      options[key]['match'] === TableFilterMatch.EXACT
    ) {
      return noSearchTerm || item[key] === filter[key];
    } else {
      return noSearchTerm || item[key]?.toString().indexOf(filter[key]?.toString()) > -1;
    }
  }

  private matchAsBoolean(item: object, key: string, filter: object): boolean {
    return filter[key] === undefined || filter[key] === null || item[key] === filter[key];
  }

  private matchAsArray(item: object, key: string, filter: object): boolean {
    return !filter[key] || (item[key] && item[key].indexOf(filter[key]) > -1);
  }

  private matchAsUnknown(item: object, key: string, filter: object): boolean {
    if (Array.isArray(filter[key])) {
      return !item[key] || filter[key].indexOf(item[key]) > -1;
    } else {
      return !filter[key] || item[key] === filter[key];
    }
  }
}
