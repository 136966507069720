import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { JsonPipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app-config';

@Component({
  selector: 'app-token-info',
  templateUrl: './token-info.component.html',
  standalone: true,
  imports: [FormsModule, JsonPipe],
  styleUrls: ['./token-info.component.scss'],
})
export class TokenInfoComponent implements OnInit {
  protected authProviderId: string;
  protected userInfoUrl: string;
  protected accessToken: string;
  protected idToken: string;
  protected userInfo: object;
  protected accessTokenExternal: string;
  protected idTokenExternal: string;
  protected userInfoExternal: object;

  constructor(
    private authService: OAuthService,
    private appConfig: AppConfig,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.accessToken = this.parseJwt(this.authService.getAccessToken());
    this.idToken = this.parseJwt(this.authService.getIdToken());
    this.authService.loadUserProfile().then((profile) => {
      this.userInfo = profile;
    });
  }

  loadExternal() {
    const accessToken = this.authService.getAccessToken();
    const bearerToken = 'Bearer ' + accessToken;
    const headers = new HttpHeaders({
      Authorization: bearerToken,
    });
    this.httpClient
      .get(this.authService.issuer + '/broker/' + this.authProviderId + '/token', {
        headers: headers,
      })
      .subscribe((data) => {
        this.accessTokenExternal = this.parseJwt(data['access_token']);
        this.idTokenExternal = this.parseJwt(data['id_token']);
        const userInfoHeaders = new HttpHeaders({
          Authorization: 'Bearer ' + data['access_token'],
        });
        this.httpClient.get(this.userInfoUrl, { headers: userInfoHeaders }).subscribe((data) => {
          this.userInfoExternal = data;
        });
      });
  }

  parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
