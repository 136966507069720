<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-5 col-xl-4 offset-xl-1">
      <!-- Konversationsverlauf -->
      <div class="card mb-2">
        <div class="card-body">
          <h5 class="mb-4">
            {{ 'label.konversationsverlauf' | translate }}&nbsp;{{ konversation?.id }}
          </h5>
          <hr />
          <div class="row">
            <div class="col-12">
              {{ 'label.referenz' | translate }}:&nbsp;<a
                class="pl-2 cursor-pointer"
                (click)="showSchadenmeldung(konversation?.schadenmeldung)"
                >{{ konversation?.schadenmeldung?.schadenNummer }}</a
              >
            </div>

            <div class="col-5">
              {{ konversation?.von.name }}
            </div>
            <div class="col-2 text-center">|</div>
            <div class="col-5 text-right">
              {{ konversation?.an.name }}
            </div>

            <div class="col-12" *ngIf="firstNachricht">
              {{ 'label.anfrage' | translate }}&nbsp;{{ 'label.vom' | translate }}&nbsp;{{
                firstNachricht.creationDate | date: 'dd.MM.yyyy HH:mm:ss'
              }}
            </div>

            <div class="col-12" *ngIf="firstNachricht && firstNachricht.von">
              {{ firstNachricht.von.vorname }}&nbsp;{{ firstNachricht.von.nachname }}&nbsp;{{
                firstNachricht.von.telefonNr ? ' - ' + firstNachricht.von.telefonNr : ''
              }}
            </div>
            <div class="col-12" *ngIf="firstNachricht && !firstNachricht.von">
              {{ firstNachricht.kontakt }}
            </div>
          </div>
          <hr />

          <div class="row" *ngIf="firstNachricht">
            <div class="col-12" translate>label.anfrage</div>
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="anfrageExpertenbericht"
                  [disabled]="true"
                  [(ngModel)]="firstNachricht.anfrageExpertenbericht" />
                <label class="form-check-label" for="anfrageExpertenbericht" translate>
                  label.expertise
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="anfrageFoto"
                  [disabled]="true"
                  [(ngModel)]="firstNachricht.anfrageFoto" />
                <label class="form-check-label" for="anfrageFoto" translate> label.fotos </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="anfrageAndere"
                  [disabled]="true"
                  [(ngModel)]="firstNachricht.anfrageAndere" />
                <label class="form-check-label" for="anfrageAndere" translate> label.andere </label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-2">
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="true"
                  [ngModel]="firstNachricht.anfrageAndereText"></textarea>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group mb-2">
                <textarea
                  class="form-control"
                  rows="3"
                  [disabled]="true"
                  [ngModel]="firstNachricht.nachrichtText"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Nachrichten details -->
    <div #scrollbereich class="col-12 col-md-7 col-xl-6 messages-list" scrollToBottom>
      <div class="row" *ngFor="let nachricht of nachrichtenWithContent">
        <ng-container *ngIf="nachricht.id !== firstNachricht.id">
          <div class="col-8" [ngClass]="{ 'offset-4 text-right': !displayOnTheLeft(nachricht) }">
            <app-nachricht
              [nachricht]="nachricht"
              [displayOnTheLeft]="displayOnTheLeft(nachricht)"></app-nachricht>
          </div>
        </ng-container>
      </div>
      <p
        *ngIf="
          !nachrichtenWithContent ||
          nachrichtenWithContent.length === 0 ||
          (nachrichtenWithContent.length === 1 &&
            nachrichtenWithContent[0].id === firstNachricht?.id)
        ">
        {{ 'message.no.nachrichten' | translate }}
      </p>
    </div>
  </div>
  <div class="row mt-2">
    <!-- Bestehende Attachments -->
    <div class="col-12 col-md-5 col-xl-4 offset-xl-1">
      <div class="card mb-2" *ngIf="allAttachments.length > 0">
        <div class="card-body">
          <h5 class="mb-4" translate>label.dokumente</h5>
          <hr />
          <ul class="list-group list-group-flush">
            <li
              class="py-2 list-group-item list-group-item-action"
              *ngFor="let item of allAttachments">
              <img src="assets/images/{{ getIconForFileName(item.attachment) }}" />&nbsp;
              {{ item.attachment.dateiname }}
              <i
                class="fa fa-download pl-3 cursor-pointer"
                (click)="downloadAttachment(item.nachricht, item.attachment)"></i>
              <i
                class="fas fa-spinner fa-spin fa-fw ml-3"
                *ngIf="attachmentLoading(item.attachment)"></i>
            </li>
            <li
              class="py-2 list-group-item list-group-item-action cursor-pointer"
              *ngIf="allAttachments.length > 1"
              (click)="downloadAllAttachmentsOfAllNachrichten()"
              translate>
              label.download.all.attachments
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-7 col-xl-6">
      <div class="card mb-2">
        <div class="card-body">
          <textarea
            class="form-control"
            rows="4"
            [(ngModel)]="neueNachricht.nachrichtText"
            placeholder="{{ 'label.nachrichtverfassen' | translate }}"
            autofocus></textarea>

          <div class="row mt-2">
            <div class="col-11">
              <ngx-file-drop
                dropZoneLabel="{{ 'label.drop.document.here' | translate }}"
                browseBtnLabel="{{ 'label.browse.files' | translate }}"
                (onFileDrop)="dropped($event)"
                [showBrowseBtn]="true"
                browseBtnClassName="btn btn-secondary ml-2"
                dropZoneClassName="file-drop-drop-zone-gray"
                contentClassName="file-drop-content-gray">
              </ngx-file-drop>
            </div>

            <div class="col-1">
              <div class="row">
                <div class="col-12">
                  <button
                    class="btn btn-primary float-right"
                    (click)="sendNachricht()"
                    [disabled]="
                      !neueNachricht?.nachrichtText &&
                      (!neueNachricht?.attachments || neueNachricht?.attachments?.length === 0)
                    "
                    title="{{ 'label.nachrichtsenden' | translate }}">
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </div>
                <div class="col-12">
                  <button
                    class="btn btn-outline-secondary float-right mt-3"
                    (click)="cancel()"
                    [disabled]="
                      !neueNachricht?.nachrichtText &&
                      (!neueNachricht?.attachments || neueNachricht?.attachments?.length === 0)
                    "
                    title="{{ 'label.abbrechen' | translate }}">
                    <i class="fa fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-12">
              <ul
                class="list-group list-group-flush"
                *ngIf="neueNachricht?.attachments?.length > 0">
                <li
                  class="py-2 list-group-item list-group-item-action"
                  *ngFor="let attachment of neueNachricht.attachments">
                  <img src="assets/images/{{ getIconForFileName(attachment) }}" />&nbsp;
                  {{ attachment.dateiname }}
                  <i
                    class="fa fa-download pl-3 cursor-pointer"
                    (click)="downloadAttachment(neueNachricht, attachment)"></i>
                  <i
                    class="fa fa-trash pl-3 cursor-pointer"
                    (click)="deleteAttachment(attachment)"></i>
                  <i
                    class="fas fa-spinner fa-spin fa-fw ml-3"
                    *ngIf="attachmentLoading(attachment)"></i>
                </li>
                <li
                  class="py-2 list-group-item list-group-item-action cursor-pointer"
                  *ngIf="neueNachricht?.attachments?.length > 1"
                  (click)="downloadAllAttachments(neueNachricht)"
                  translate>
                  label.download.all.attachments
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <button
        type="submit"
        class="btn btn-outline-secondary form-control col-2 float-right"
        (click)="back()"
        translate>
        label.zurueck
      </button>
    </div>
  </div>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
