<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="mb-4" translate>label.scoringadministration</h5>
          <div class="row d-flex align-items-end">
            <div class="col-10">
              <app-slider
                minValue="0"
                maxValue="100"
                [value]="scorePoints"
                (changeValueEvent)="changeScores($event)"></app-slider>
            </div>
            <div class="col-2">
              <input
                name="scorepoints"
                [(ngModel)]="scorePoints"
                (change)="validateRange()"
                class="form-control form-control-sm"
                type="text" />
            </div>
          </div>
          <button type="button" class="btn btn-primary mt-4" (click)="saveScoreLimit()" translate>
            label.limitespeichern
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card mb-4" *ngIf="(rolle$ | async) == Rolle.SVV">
        <div class="card-body">
          <h5 class="mb-4" translate>label.organisationseinheiten</h5>
          <button
            type="button"
            class="btn btn-primary"
            [routerLink]="['/organisationen']"
            translate>
            label.adminoe
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="card mb-4">
        <div class="card-body">
          <h5 class="mb-4" translate>label.technischerbenutzer</h5>
          <div class="form-group row">
            <label class="col-form-label col-12 col-lg-3" translate
              >label.technischerbenutzer</label
            >
            <div class="col-12 col-lg-6">
              <input
                name="vorname"
                value="{{ vesicherung.wsUserId }}"
                class="form-control form-control-sm"
                type="text"
                disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
