<nav class="navbar fixed-bottom navbar-light bg-light footer">
  <span
    >&#169; 2021 by SVV Solution AG&#160;&#160;|&#160;&#160;<a
      [routerLink]="['/disclaimer']"
      translate
      >label.disclaimer</a
    >&#160;&#160;|&#160;&#160;<a [routerLink]="['/impressum']" translate>label.impressum</a
    ><span *ngIf="version">&nbsp;|&nbsp;{{ version }}</span></span
  >
</nav>
