import { Injectable } from '@angular/core';
import { AbfrageApiService, IndividualSearchResultDto, SchadenmeldungDto } from '../core/api';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TopLimit } from '../core/enum/TopLimit';

@Injectable({
  providedIn: 'root',
})
export class AbfrageResultatService {
  private individualResult: IndividualSearchResultDto;
  private topScoreResult: SchadenmeldungDto[];
  private previousTopLimitSearch: TopLimit;
  private sucheForSchaden = false;

  private schadennummer: string = '';
  private stammnummer: string = '';
  private fahrgestellnummer: string = '';
  private schadendatumVon: NgbDate = undefined;
  private schadendatumBis: NgbDate = undefined;

  public constructor(
    private abfrageApiService: AbfrageApiService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.reset();
  }

  public searchIndividual(
    fahrgestellnummer: string,
    schadennummer: string,
    stammnummer: string,
    schadendatumVon: NgbDate,
    schadendatumBis: NgbDate,
  ): Promise<boolean> {
    this.schadennummer = schadennummer;
    this.stammnummer = stammnummer;
    this.fahrgestellnummer = fahrgestellnummer;
    this.schadendatumVon = schadendatumVon;
    this.schadendatumBis = schadendatumBis;
    return new Promise<boolean>((resolve) => {
      this.abfrageApiService
        .searchIndividual({
          fahrgestellnummer: fahrgestellnummer,
          schadennummer: schadennummer,
          stammnummer: stammnummer,
          schadendatumVon: this.toNgbDateToJSON(schadendatumVon),
          schadendatumBis: this.toNgbDateToJSON(schadendatumBis),
        })
        .subscribe(
          (result: IndividualSearchResultDto) => {
            resolve(true);
            this.individualResult = result;
            this.sucheForSchaden = !stammnummer && !fahrgestellnummer;
            this.router.navigate(['/resultate']);
          },
          (error) => {
            resolve(false);
            throw error;
          },
        );
    });
  }

  public searchTop(limit: TopLimit): Promise<boolean> {
    this.previousTopLimitSearch = limit;
    return new Promise<boolean>((resolve) => {
      this.abfrageApiService.searchTop(limit).subscribe(
        (result) => {
          resolve(true);
          this.topScoreResult = result;
          this.router.navigate(['/top-score-resultat']);
        },
        (error) => {
          resolve(false);
          throw error;
        },
      );
    });
  }

  public refreshTopResults() {
    return this.searchTop(this.previousTopLimitSearch);
  }

  public reset() {
    this.schadennummer = '';
    this.stammnummer = '';
    this.fahrgestellnummer = '';
    this.schadendatumVon = undefined;
    this.schadendatumBis = undefined;
  }

  public getIndividualSearchResult() {
    return this.individualResult;
  }

  public isSucheForSchaden() {
    return this.sucheForSchaden;
  }

  public getTopScoreResult() {
    return this.topScoreResult;
  }

  public getSchadennummer() {
    return this.schadennummer;
  }

  public setSchadennummer(schadennummer: string) {
    this.schadennummer = schadennummer;
  }

  public getStammnummer() {
    return this.stammnummer;
  }

  public setStammnummer(stammnummer: string) {
    this.stammnummer = stammnummer;
  }

  public getFahrgestellnummer() {
    return this.fahrgestellnummer;
  }

  public setFahrgestellnummer(fahrgestellnummer: string) {
    this.fahrgestellnummer = fahrgestellnummer;
  }

  public getSchadendatumVon() {
    return this.schadendatumVon;
  }

  public getSchadendatumBis() {
    return this.schadendatumBis;
  }

  private toNgbDateToJSON(date: NgbDate): string {
    if (date) {
      let yearString = '' + date.year;
      let monthString = date.month < 10 ? '0' + date.month : '' + date.month;
      let dayString = date.day < 10 ? '0' + date.day : '' + date.day;
      return '' + yearString + '-' + monthString + '-' + dayString + 'T00:00:00.000Z';
    } else {
      return undefined;
    }
  }
}
