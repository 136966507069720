<div class="container-lg">
  <div class="row mt-n3">
    <div class="col-12 col-md-2 mb-3">
      <button
        type="submit"
        class="btn btn-outline-primary form-control"
        (click)="back()"
        title="{{ 'tooltip.zurueckzurliste' | translate }}"
        translate>
        label.zurueck
      </button>
    </div>
    <div class="col-6 col-md-2 mb-3">
      <button
        type="submit"
        class="btn btn-outline-primary form-control"
        (click)="previous()"
        [disabled]="!hasPrevious()"
        title="{{ 'tooltip.vorherigerdatensatz' | translate }}">
        &#60;
      </button>
    </div>
    <div class="col-6 col-md-2 mb-3">
      <button
        type="submit"
        class="btn btn-outline-primary form-control"
        (click)="next()"
        [disabled]="!hasNext()"
        title="{{ 'tooltip.naechsterdatensatz' | translate }}">
        &#62;
      </button>
    </div>
    <div class="col-6 col-md-3"></div>
    <div class="col-6 col-md-3 mb-3">
      <button
        type="submit"
        class="btn btn-outline-primary form-control"
        *ngIf="showDeleteEntry"
        (click)="deleteEntry()"
        translate>
        label.datensatzloeschen
      </button>
      <button
        type="submit"
        class="btn btn-outline-primary form-control"
        *ngIf="showAnfordernButton"
        (click)="showDossierAnfordern()"
        translate>
        label.dossieranfordern
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card mb-30-px">
        <div class="card-body">
          <h6 class="mb-4" translate>label.schaden</h6>
          <hr />
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <label for="schadenNummer" class="col-5 col-lg-4" translate="label.nummer"></label>
                <label id="schadenNummer" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.schadenNummer
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row pad-to-card-content">
                <label
                  for="erstelltVon"
                  class="col-5 col-lg-4"
                  translate="label.erstelltvon"></label>
                <label id="erstelltVon" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.erstelltVon
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <label for="schadenDatum" class="col-5 col-lg-4" translate="label.datum"></label>
                <label id="schadenDatum" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.schadenDatum | date: 'dd.MM.yyyy'
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row pad-to-card-content">
                <label for="gutachten" class="col-5 col-lg-4" translate="label.gutachten"></label>
                <label id="gutachten" class="col-7 col-lg-8 font-weight-bold"
                  ><input type="checkbox" [ngModel]="schadenmeldung?.gutachten" [disabled]="true"
                /></label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <label for="schadenart" class="col-5 col-lg-4" translate="label.art"></label>
                <label id="schadenart" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.schadenart | translateSchadenart
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row pad-to-card-content">
                <label for="score" class="col-5 col-lg-4" translate="label.score"></label>
                <label id="score" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.score
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <label
                  for="gesellschaft"
                  class="col-5 col-lg-4"
                  translate="label.gesellschaft"></label>
                <label id="gesellschaft" class="col-7 col-lg-8 font-weight-bold">{{
                  schadenmeldung?.versicherung?.name
                }}</label>
              </div>
            </div>
            <div
              class="col-12 col-md-6"
              [ngClass]="{
                'text-success': scoringErfolgreich,
                'text-danger': !scoringErfolgreich
              }">
              <div class="row pad-to-card-content">
                <label class="col-12">{{
                  schadenmeldung?.status | translateWithPrefix: 'enum.schadenmeldungstatusenum.'
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <app-fahrzeug
        [fahrzeug]="schadenmeldung?.versichertesFahrzeug"
        [schadenmeldung]="schadenmeldung"
        (onFahrzeugDeleted)="onFahrzeugDeleted($event)"
        (onShowBewertungDetails)="showBewertungDetails($event)"
        label="{{ 'label.versichertesfahrzeug' | translate }}"></app-fahrzeug>
    </div>
    <div class="col-12 col-md-6">
      <app-fahrzeug
        *ngFor="let fahrzeug of schadenmeldung?.gegnerischeFahrzeuge"
        [schadenmeldung]="schadenmeldung"
        [fahrzeug]="fahrzeug"
        (onFahrzeugDeleted)="onFahrzeugDeleted($event)"
        (onShowBewertungDetails)="showBewertungDetails($event)"
        label="{{ 'label.gegnerischesfahrzeug' | translate }} {{ fahrzeug.nummer }}"
        [gegnerischesFahrzeug]="true"
        [collapsible]="true"
        [collapsed]="schadenmeldung?.gegnerischeFahrzeuge.length > 1"></app-fahrzeug>
    </div>
  </div>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
