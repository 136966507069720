export enum Monat {
  JANUAR,
  FEBRUAR,
  MAERZ,
  APRIL,
  MAI,
  JUNI,
  JULI,
  AUGUST,
  SEPTEMBER,
  OKTOBER,
  NOVEMBER,
  DEZEMBER,
}
