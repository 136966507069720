<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title">{{ options.title | translate }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{ options.message | translate }}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="dismiss()" translate>
    label.abbrechen
  </button>
  <button type="button" class="btn btn-outline-danger" (click)="accept()">
    {{ options.acceptText | translate }}
  </button>
</div>
