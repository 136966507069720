<div class="row">
  <div class="card offset-md-2 col-8">
    <div class="card-body">
      <h5 class="mb-4">{{ title() }}</h5>
      <hr class="mb-4" />
      <form #benutzerForm="ngForm" (ngSubmit)="submitForm()">
        <div class="form-group row" *ngIf="isSVV">
          <label for="gesellschaft" class="col-form-label col-lg-3"
            >{{ 'label.gesellschaft' | translate }}*</label
          >
          <div class="col-lg-9">
            <select
              id="gesellschaft"
              name="gesellschaft"
              [(ngModel)]="benutzer.versicherung"
              (ngModelChange)="onVgChangeResetRollen()"
              [compareWith]="compareFn"
              [disabled]="editModus"
              class="form-control form-control-sm"
              required>
              <option *ngFor="let versicherung of versicherungen" [ngValue]="versicherung">
                {{ versicherung.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row" *ngIf="benutzer?.versicherung?.authTyp">
          <label for="authTyp" class="col-form-label col-lg-3" translate>label.authTyp</label>
          <div class="col-lg-9">
            <input
              [disabled]="true"
              id="authTyp"
              name="authTyp"
              class="form-control form-control-sm"
              [value]="
                'enum.versicherungsauthtypenum.' + benutzer.versicherung.authTyp | translate
              " />
          </div>
        </div>

        <div class="form-group row">
          <label for="rollen" class="col-form-label col-lg-3"
            >{{ 'label.rollen' | translate }}*</label
          >
          <div class="col-lg-9">
            <!-- let's use a different select component, that is more handy for multiple selections than the html default one. -->
            <ng-select
              class="col-form-label-sm"
              [items]="rollen"
              appendTo="body"
              id="rollen"
              name="rollen"
              dropdownPosition="auto"
              [multiple]="true"
              [clearable]="false"
              [readonly]="rollenDisabled"
              [(ngModel)]="benutzer.rollen"
              required>
            </ng-select>
          </div>
        </div>
        <div class="form-group row">
          <label for="anrede" class="col-form-label col-lg-3"
            >{{ 'label.anrede' | translate }}*</label
          >
          <div class="col-lg-9">
            <select
              id="anrede"
              name="anrede"
              [(ngModel)]="benutzer.anrede"
              [compareWith]="compareFn"
              class="form-control form-control-sm"
              required>
              <option *ngFor="let anrede of anreden" [value]="anrede">
                {{ 'enum.anrede.' + anrede | translate }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <label for="vorname" class="col-form-label col-lg-3"
            >{{ 'label.vorname' | translate }}*</label
          >
          <div class="col-lg-9">
            <input
              id="vorname"
              name="vorname"
              [(ngModel)]="benutzer.vorname"
              [disabled]="isAuthTypeIdentityBroker"
              class="form-control form-control-sm"
              type="text"
              maxLength="30"
              required />
          </div>
        </div>
        <div class="form-group row">
          <label for="nachname" class="col-form-label col-lg-3"
            >{{ 'label.name' | translate }}*</label
          >
          <div class="col-lg-9">
            <input
              id="nachname"
              name="nachname"
              [(ngModel)]="benutzer.nachname"
              [disabled]="isAuthTypeIdentityBroker"
              class="form-control form-control-sm"
              type="text"
              maxlength="30"
              required />
          </div>
        </div>
        <div class="form-group row">
          <label for="email" class="col-form-label col-lg-3"
            >{{ 'label.email' | translate }}*</label
          >
          <div class="col-lg-9">
            <input
              id="email"
              name="email"
              [(ngModel)]="benutzer.email"
              [disabled]="editModus"
              class="form-control form-control-sm"
              type="email"
              maxlength="100"
              required
              email />
          </div>
        </div>
        <div class="form-group row">
          <label for="telefon" class="col-form-label col-lg-3">{{
            'label.telefonnr' | translate
          }}</label>
          <div class="col-lg-9">
            <input
              id="telefon"
              name="telefon"
              [(ngModel)]="benutzer.telefonNr"
              class="form-control form-control-sm"
              type="text"
              maxlength="25" />
          </div>
        </div>
        <div class="form-group row">
          <label for="sprache" class="col-form-label col-lg-3"
            >{{ 'label.sprache' | translate }}*</label
          >
          <div class="col-lg-9">
            <select
              id="sprache"
              name="sprache"
              [(ngModel)]="benutzer.sprache"
              [compareWith]="compareFn"
              class="form-control form-control-sm"
              required>
              <option *ngFor="let sprache of sprachen" [value]="sprache">
                {{ 'enum.spracheenum.' + sprache | translate }}
              </option>
            </select>
          </div>
        </div>
        <hr />
        <div class="form-group row d-flex justify-content-end">
          <div class="col-sm-3">
            <button
              type="button"
              class="btn btn-outline-secondary form-control"
              routerLink="/benutzerverwaltung">
              {{ 'label.abbrechen' | translate }}
            </button>
          </div>
          <div *ngIf="editModus" class="col-sm-3">
            <button type="button" (click)="removeBenutzer()" class="btn btn-primary form-control">
              {{ 'label.loeschen' | translate }}
            </button>
          </div>
          <div class="col-sm-3">
            <button
              type="submit"
              [disabled]="benutzerForm.invalid"
              class="btn btn-primary form-control">
              {{ 'label.speichern' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
