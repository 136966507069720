<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>title.postboxanfrage</h5>
  <button type="button" class="close" (click)="modal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form>
    <div class="row">
      <div class="col-12">
        <label class="col-form-label" translate>label.unterlagenankreuzen</label>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="anfrageExpertise"
            name="anfrageExpertise"
            [(ngModel)]="dto.anfrageExpertise" />
          <label class="form-check-label" for="anfrageExpertise" translate> label.expertise </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="anfrageFoto"
            name="anfrageFoto"
            [(ngModel)]="dto.anfrageFotos" />
          <label class="form-check-label" for="anfrageFoto" translate> label.fotos </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="anfrageAndere"
            name="anfrageAndere"
            [(ngModel)]="dto.anfrageAndere"
            (ngModelChange)="clearAndereText()" />
          <label class="form-check-label" for="anfrageAndere" translate> label.andere </label>
        </div>

        <div class="form-check pl-0">
          <textarea
            id="andereText"
            name="andereText"
            class="form-control"
            [(ngModel)]="dto.anfrageAndereText"
            [disabled]="!dto.anfrageAndere"
            rows="4"></textarea>
        </div>
      </div>
      <div class="col-12">
        <label class="col-form-label" translate>label.bemerkungen</label>

        <div class="form-check pl-0">
          <textarea
            id="nachrichtText"
            name="nachrichtText"
            class="form-control"
            [(ngModel)]="dto.nachrichtText"
            rows="4"></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary flex-grow-1"
    (click)="modal.dismiss()"
    translate>
    label.abbrechen
  </button>
  <button type="button" class="btn btn-primary flex-grow-1" (click)="dossierAnfordern()" translate>
    label.senden
  </button>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
