<h4 class="mb-4" translate>label.topscoreschaeden</h4>
<div class="table-responsive">
  <table class="table table-bordered table-sm table-striped">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th sortable="score" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.score</span>
        </th>
        <th sortable="schadenDatum" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadendatum</span>
        </th>
        <th sortable="kanton" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.kanton</span>
        </th>
        <th sortable="schadenart" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.schadenart</span>
        </th>
        <th sortable="fahrzeugArt" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.fahrzeugart</span>
        </th>
        <th sortable="markeTyp" (sort)="onSort($event)">
          <span class="text-nowrap" translate>tableheader.marketyp</span>
        </th>
        <th>
          <span class="text-nowrap" translate>tableheader.geprueft</span>
        </th>
        <th>
          <span class="text-nowrap" translate>tableheader.erledigt</span>
        </th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let schadenmeldung of result; index as i">
        <td class="text-nowrap">
          <i class="fa fa-edit cursor-pointer" (click)="displaySchadenDetails(i)"></i>
        </td>
        <td class="text-nowrap">{{ schadenmeldung.score }}</td>
        <td class="text-nowrap">{{ schadenmeldung.schadenDatum | date: 'dd.MM.yyyy' }}</td>
        <td class="text-nowrap">{{ schadenmeldung.versichertesFahrzeug?.kantonsKuerzel }}</td>
        <td class="text-nowrap">{{ schadenmeldung.schadenart | translateSchadenart }}</td>
        <td class="text-nowrap">
          {{ schadenmeldung.versichertesFahrzeug?.fzArt | translateFahrzeugart }}
        </td>
        <td class="text-nowrap">{{ schadenmeldung.versichertesFahrzeug?.markeTyp }}</td>
        <td class="text-nowrap text-center">
          <input
            type="checkbox"
            [(ngModel)]="schadenmeldung.geprueft"
            (change)="onChangeGeprueft(schadenmeldung)" />
        </td>
        <td class="text-nowrap text-center">
          <input type="checkbox" [(ngModel)]="schadenmeldung.erledigt" />
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-12 col-sm-8 col-lg-10"></div>
  <div class="col-12 col-sm-4 col-lg-2">
    <button
      type="submit"
      class="btn btn-primary form-control"
      [disabled]="!anyErledigt"
      (click)="erledigen()"
      translate>
      label.erledigen
    </button>
  </div>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
