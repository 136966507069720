import { Injectable } from '@angular/core';
import { FahrzeugDto, SchadenHistoryDto } from '../core/api';

@Injectable({
  providedIn: 'root',
})
export class BewertungDetailsService {
  private selectedFahrzeug: FahrzeugDto;
  private schadenHistory: SchadenHistoryDto[];

  public setResults(selectedFahrzeug: FahrzeugDto, schadenHistory: SchadenHistoryDto[]) {
    this.selectedFahrzeug = selectedFahrzeug;
    this.schadenHistory = schadenHistory;
  }

  public getSelectedFahrzeug() {
    return this.selectedFahrzeug;
  }

  public getSchadenHistory() {
    return this.schadenHistory;
  }
}
