/**
 * CC-Info backend api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VersicherungDto } from './versicherungDto';
import { FahrzeugDto } from './fahrzeugDto';


export interface SchadenmeldungDto { 
    id?: number;
    versicherung?: VersicherungDto;
    schadenart?: SchadenmeldungDto.SchadenartEnum;
    schadenDatum?: string;
    schadenNummer?: string;
    score?: number;
    gegnerischeFahrzeuge?: Array<FahrzeugDto>;
    versichertesFahrzeug?: FahrzeugDto;
    erledigt?: boolean;
    geprueft?: boolean;
    gutachten?: boolean;
    erstelltVon?: string;
    status?: SchadenmeldungDto.StatusEnum;
}
export namespace SchadenmeldungDto {
    export type SchadenartEnum = 'MFH_SCHADEN' | 'BOESWILLIGE_BESCHAEDIGUNG' | 'FEUER' | 'DIEBSTAHL' | 'GLASBRUCH' | 'KOLLISION_MIT_FAHRZEUG' | 'KOLLISION_OHNE_FAHRZEUG' | 'MARDERSCHADEN' | 'PARKSCHADEN' | 'TIERSCHADEN' | 'HAGELSCHADEN' | 'SCHNEESCHADEN' | 'UEBERSCHWEMMUNG_HOCHWASSER' | 'ERDRUTSCH_STEINSCHLAG_FELSSTURZ' | 'STURMWIND' | 'NGF' | 'PRIVATHAFTPFLICHT' | 'DIEBSTAHL_AUS_FZ' | 'DIEBSTAHL_FZ';
    export const SchadenartEnum = {
        MFHSCHADEN: 'MFH_SCHADEN' as SchadenartEnum,
        BOESWILLIGEBESCHAEDIGUNG: 'BOESWILLIGE_BESCHAEDIGUNG' as SchadenartEnum,
        FEUER: 'FEUER' as SchadenartEnum,
        DIEBSTAHL: 'DIEBSTAHL' as SchadenartEnum,
        GLASBRUCH: 'GLASBRUCH' as SchadenartEnum,
        KOLLISIONMITFAHRZEUG: 'KOLLISION_MIT_FAHRZEUG' as SchadenartEnum,
        KOLLISIONOHNEFAHRZEUG: 'KOLLISION_OHNE_FAHRZEUG' as SchadenartEnum,
        MARDERSCHADEN: 'MARDERSCHADEN' as SchadenartEnum,
        PARKSCHADEN: 'PARKSCHADEN' as SchadenartEnum,
        TIERSCHADEN: 'TIERSCHADEN' as SchadenartEnum,
        HAGELSCHADEN: 'HAGELSCHADEN' as SchadenartEnum,
        SCHNEESCHADEN: 'SCHNEESCHADEN' as SchadenartEnum,
        UEBERSCHWEMMUNGHOCHWASSER: 'UEBERSCHWEMMUNG_HOCHWASSER' as SchadenartEnum,
        ERDRUTSCHSTEINSCHLAGFELSSTURZ: 'ERDRUTSCH_STEINSCHLAG_FELSSTURZ' as SchadenartEnum,
        STURMWIND: 'STURMWIND' as SchadenartEnum,
        NGF: 'NGF' as SchadenartEnum,
        PRIVATHAFTPFLICHT: 'PRIVATHAFTPFLICHT' as SchadenartEnum,
        DIEBSTAHLAUSFZ: 'DIEBSTAHL_AUS_FZ' as SchadenartEnum,
        DIEBSTAHLFZ: 'DIEBSTAHL_FZ' as SchadenartEnum
    };
    export type StatusEnum = 'EINGESTELLT' | 'ISP_SCORING_ERFOLGREICH' | 'ISP_SCORING_FEHLERHAFT' | 'GELOESCHT';
    export const StatusEnum = {
        EINGESTELLT: 'EINGESTELLT' as StatusEnum,
        ISPSCORINGERFOLGREICH: 'ISP_SCORING_ERFOLGREICH' as StatusEnum,
        ISPSCORINGFEHLERHAFT: 'ISP_SCORING_FEHLERHAFT' as StatusEnum,
        GELOESCHT: 'GELOESCHT' as StatusEnum
    };
}


