<span
  [ngbPopover]="hintTemplate"
  #popover="ngbPopover"
  [autoClose]="false"
  (click)="$event.preventDefault(); $event.stopPropagation(); popover.open()"
  (mouseenter)="popover.open()"
  (mouseleave)="popover.close()"
  triggers="manual"
  placement="right top bottom left"
  container="body">
  <i class="far fa-question-circle ml-2"></i>
</span>

<ng-template #hintTemplate>
  <div class="hint">
    <ng-content></ng-content>
  </div>
</ng-template>
