<div class="slidecontainer">
  <div class="valueContainer">
    <div id="minValue" class="valueLabel min-value-left-margin" [innerText]="minValue"></div>
    <div id="maxValue" class="valueLabel" [innerText]="maxValue"></div>
    <div id="selectedValue" #selectedValue class="sliderValue" [innerText]="value"></div>
  </div>
  <input
    id="mySlider"
    #inputElement
    type="range"
    class="slider"
    [attr.min]="minValue"
    [attr.max]="maxValue"
    [(ngModel)]="value"
    (input)="input($event)" />
</div>
