import { Component } from '@angular/core';
import { BenutzerApiService, BenutzerDto } from '../../core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BenutzerFormComponent } from '../benutzer-form/benutzer-form.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-benutzer',
  templateUrl: './create-benutzer.component.html',
  styleUrls: ['./create-benutzer.component.scss'],
  standalone: true,
  imports: [BenutzerFormComponent],
})
export class CreateBenutzerComponent {
  constructor(
    private benutzerApiService: BenutzerApiService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  createBenutzer(benutzer: BenutzerDto, component: BenutzerFormComponent): void {
    this.benutzerApiService.saveBenutzer(benutzer).subscribe(
      (res) => {
        this.toastr.success(
          this.translate.instant('message.benutzergespeichert', {
            benutzerid: benutzer.email,
          }),
        );
        component.resetForm();
        this.router.navigate(['/benutzerverwaltung']);
      },
      (error) => {
        throw error;
      },
    );
  }
}
