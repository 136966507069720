import { Component, OnInit } from '@angular/core';
import { BenutzerApiService, BenutzerDto, VersicherungDto } from '../../core/api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateWithPrefixPipe } from '../../core/pipes/translate-with-prefix.pipe';
import { RouterLink } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';
import AuthTypEnum = VersicherungDto.AuthTypEnum;
import SpracheEnum = BenutzerDto.SpracheEnum;
import { BenutzerService } from '../../core/benutzer/benutzer.service';
import AnredeEnum = BenutzerDto.AnredeEnum;

@Component({
  selector: 'app-konto-verwaltung',
  templateUrl: './konto-verwaltung.component.html',
  styleUrls: ['./konto-verwaltung.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    NgIf,
    NgSelectModule,
    NgFor,
    RouterLink,
    TranslateWithPrefixPipe,
  ],
})
export class KontoVerwaltungComponent implements OnInit {
  public benutzer: BenutzerDto;
  public isAuthTypeIdentityBroker: boolean = false;
  public sprachen: SpracheEnum[] = Object.entries(SpracheEnum).map(([, enumValue]) => enumValue);
  public anreden: AnredeEnum[] = Object.entries(AnredeEnum).map(([, enumValue]) => enumValue);

  constructor(
    private benutzerService: BenutzerService,
    private benutzerApiService: BenutzerApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.benutzerService.benutzer$.subscribe((currentUser) => {
      this.benutzer = currentUser;
      this.isAuthTypeIdentityBroker = this.benutzer?.versicherung?.authTyp === AuthTypEnum.IDP;
    });
  }

  updateBenutzer(benutzer: BenutzerDto): void {
    this.benutzerApiService.saveBenutzer(benutzer).subscribe(
      (res) => {
        this.toastr.success(
          this.translate.instant('message.benutzergespeichert', {
            benutzerid: benutzer.email,
          }),
        );
      },
      (error) => {
        throw error;
      },
    );
  }

  compareFn(a, b): boolean {
    if (a != undefined && b != undefined) {
      return a.id === b.id;
    }
    return false;
  }
}
