import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { KonversationDto, PostboxApiService, SchadenmeldungDto } from '../core/api';
import { compare, SortableHeaderDirective, SortEvent } from '../core/directives/sortable-header';
import { KonversationService } from './konversation/konversation.service';
import { Router } from '@angular/router';
import { SchadenDetailNavigationSource } from '../schaden-detail/schaden-detail-navigation-source';
import { SchadenDetailService } from '../schaden-detail/schaden-detail.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FullscreenSpinnerComponent } from '../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { NgFor, NgClass, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import StatusEnum = KonversationDto.StatusEnum;

@Component({
  selector: 'app-postbox',
  templateUrl: './postbox.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    FormsModule,
    SortableHeaderDirective,
    NgFor,
    NgClass,
    FullscreenSpinnerComponent,
    DatePipe,
  ],
})
export class PostboxComponent implements OnInit {
  public KonversationStatus: typeof StatusEnum = StatusEnum;
  public konversationen: KonversationDto[];

  public loadArchivedKonversationen = false;
  public loadEigeneOnly = false;

  public loading = false;

  @ViewChildren(SortableHeaderDirective)
  private headers: QueryList<SortableHeaderDirective>;
  private previousSortEvent: SortEvent;

  private readonly STORAGE_KEY_OWN_CONVERSATIONS_ONLY = 'postbox-ownConversationsOnly';
  private readonly STORAGE_KEY_ARCHIVED_CONVERSATIONS = 'postbox-archivedConversations';

  constructor(
    private postboxApiService: PostboxApiService,
    private translate: TranslateService,
    private konversationService: KonversationService,
    private router: Router,
    private schadenDetailService: SchadenDetailService,
  ) {}

  public ngOnInit(): void {
    this.loadPostboxSettings();
    this.loadKonversationen();
  }

  public showSchadenmeldung(schadenmeldung: SchadenmeldungDto) {
    this.schadenDetailService.setSchadenDetails(
      [schadenmeldung],
      SchadenDetailNavigationSource.POSTBOX,
    );
    this.schadenDetailService.selectSchadenDetails(0);
    this.router.navigate(['/schaden-details']);
  }

  public showDetails(konversation: KonversationDto) {
    this.loading = true;
    this.postboxApiService.getNachrichtenForKonversation(konversation.id).subscribe(
      (nachrichten) => {
        this.konversationService.setKonversation(konversation, nachrichten);
        this.router.navigate(['/konversation']);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  public loadKonversationen() {
    this.loading = true;
    this.savePostboxSettings();
    this.postboxApiService.getAll1(this.loadEigeneOnly, this.loadArchivedKonversationen).subscribe(
      (konversationen) => {
        this.konversationen = konversationen;
        if (this.previousSortEvent) {
          this.onSort(this.previousSortEvent);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  public get anyErledigt() {
    return this.konversationen && this.konversationen.some((k) => k.erledigt);
  }

  public erledigen() {
    const konversationenToArchive = this.konversationen.filter((k) => k.erledigt).map((k) => k.id);
    this.loading = true;
    this.postboxApiService
      .archiviereKonversartionen({
        konversationIds: konversationenToArchive,
      })
      .subscribe(
        () => {
          this.loadKonversationen();
        },
        (error) => {
          this.loading = false;
          throw error;
        },
      );
  }

  public onSort(sortEvent: SortEvent) {
    this.previousSortEvent = sortEvent;
    this.headers?.forEach((header) => {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    });

    this.konversationen = this.konversationen?.sort((a, b) => {
      let res = 0;
      switch (sortEvent.column) {
        case 'vonVersicherung':
          res = compare(a.von?.name, b.von?.name);
          break;
        case 'schadenNummer':
          res = compare(a.schadenmeldung?.schadenNummer, b.schadenmeldung?.schadenNummer);
          break;
        case 'status':
          res = compare(
            this.translate.instant('enum.postboxstatus.' + a.status),
            this.translate.instant('enum.postboxstatus.' + b.status),
          );
          break;
        default:
          res = compare(a[sortEvent.column], b[sortEvent.column]);
          break;
      }
      return sortEvent.direction === 'asc' ? res : -res;
    });
  }

  public konversationDeletedSoon(konversation: KonversationDto) {
    return konversation.deletedInDays <= 10;
  }

  private loadPostboxSettings(): void {
    const loadEigeneOnly = localStorage.getItem(this.STORAGE_KEY_OWN_CONVERSATIONS_ONLY);
    const loadArchived = localStorage.getItem(this.STORAGE_KEY_ARCHIVED_CONVERSATIONS);
    if (loadEigeneOnly) {
      this.loadEigeneOnly = JSON.parse(loadEigeneOnly);
    }
    if (loadArchived) {
      this.loadArchivedKonversationen = JSON.parse(loadArchived);
    }
  }

  private savePostboxSettings(): void {
    localStorage.setItem(
      this.STORAGE_KEY_OWN_CONVERSATIONS_ONLY,
      JSON.stringify(this.loadEigeneOnly),
    );
    localStorage.setItem(
      this.STORAGE_KEY_ARCHIVED_CONVERSATIONS,
      JSON.stringify(this.loadArchivedKonversationen),
    );
  }
}
