import { Directive, TemplateRef } from '@angular/core';
import { ConfirmState } from './confirm-state';

@Directive({
  selector: '[appConfirmTemplate]',
  standalone: true,
})
export class ConfirmTemplateDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: ConfirmState) {
    state.template = confirmTemplate;
  }
}
