import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Translate the fahrzeugart. Because it is a varchar2 in the database, we don't know all the possible values.
 * We try to translate it with a normalized value (so 01 is interpreted as 1). If there is no translation, we display the original string.
 */
@Pipe({
  name: 'translateFahrzeugart',
  standalone: true,
})
export class TranslateFahrzeugartPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}

  transform(fahrzeugart?: string): string {
    const normalizedFahrzeugart = this.normalize(fahrzeugart);
    const keyToTranslate = 'enum.fahrzeugartenum.' + normalizedFahrzeugart;
    const translation = this.translate.instant(keyToTranslate);

    return !!translation && translation !== keyToTranslate ? translation : fahrzeugart;
  }

  private normalize(code: string): string {
    return code !== undefined && code !== null && !isNaN(parseInt(code)) ? '' + parseInt(code) : '';
  }
}
