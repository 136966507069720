<div
  class="card mb-30-px"
  [ngClass]="{ 'card-min-height': !collapsed, 'card-collapsed-min-height': collapsed }">
  <div class="card-body">
    <div class="row">
      <div class="col-11">
        <h6>
          <span>{{ label }}</span
          >&nbsp;<i
            class="fas fa-exclamation-triangle"
            *ngIf="erfolgreichBewertet && fahrzeug?.verdaechtigstes"></i>
        </h6>
      </div>

      <div class="col-1" *ngIf="!collapsible"></div>
      <div class="col-1" *ngIf="collapsible">
        <i
          class="far cursor-pointer"
          [ngClass]="{ 'fa-minus-square': !collapsed, 'fa-plus-square': collapsed }"
          (click)="collapsed = !collapsed"></i>
      </div>
    </div>
    <hr />
    <div class="row" *ngIf="collapsed">
      <div class="col-12" *ngIf="fahrzeug?.score !== undefined && fahrzeug?.score !== null">
        <div class="row">
          <label class="col-12">{{ 'label.score' | translate }}: {{ fahrzeug?.score }}</label>
        </div>
      </div>

      <div
        class="col-12"
        *ngIf="fahrzeug?.score === undefined || fahrzeug?.score === null"
        [ngClass]="{
          'text-success': !anreicherungsmeldungFehlerhaft,
          'text-danger': anreicherungsmeldungFehlerhaft
        }">
        <div class="row">
          <label class="col-12" *ngIf="fahrzeug?.anreicherungsmeldung">{{
            fahrzeug?.anreicherungsmeldung | translateWithPrefix: 'enum.anreicherungsmeldung.'
          }}</label>
          <label class="col-12" *ngIf="!fahrzeug?.anreicherungsmeldung">{{
            fahrzeug?.status | translateWithPrefix: 'enum.fahrzeugstatusenum.'
          }}</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!collapsed">
      <div
        class="col-10"
        [ngClass]="{
          'text-success': !anreicherungsmeldungFehlerhaft,
          'text-danger': anreicherungsmeldungFehlerhaft
        }">
        <div class="row">
          <label class="col-12" *ngIf="fahrzeug?.anreicherungsmeldung">{{
            fahrzeug?.anreicherungsmeldung | translateWithPrefix: 'enum.anreicherungsmeldung.'
          }}</label>
          <label class="col-12" *ngIf="!fahrzeug?.anreicherungsmeldung">{{
            fahrzeug?.status | translateWithPrefix: 'enum.fahrzeugstatusenum.'
          }}</label>
        </div>
      </div>
      <div class="col-1">
        <i
          class="fa fa-trash cursor-pointer"
          title="{{ 'tooltip.fahrzeugloeschen' | translate }}"
          (click)="deleteFahrzeug()"
          *ngIf="(rolle$ | async) == Rolle.SVV"></i>
      </div>
      <div class="col-1">
        <i
          class="fa fa-binoculars cursor-pointer"
          title="{{ 'tooltip.neuesuche' | translate }}"
          (click)="neueSuche()"></i>
      </div>
      <div class="col-12">
        <div class="row">
          <label for="fahrzeugScore" class="col-5 col-lg-4" translate="label.score"></label>
          <label id="fahrzeugScore" class="col-7 col-lg-8 font-weight-bold"
            >{{ fahrzeug?.score }}&nbsp;&nbsp;
            <i
              class="fa fa-search cursor-pointer"
              *ngIf="fahrzeug?.hasHistory"
              title="{{ 'label.bewertungsdetails' | translate }}"
              (click)="showBewertungDetails()"></i>
          </label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugStammnummer"
            class="col-5 col-lg-4"
            translate="label.stammnummer"></label>
          <label id="fahrzeugStammnummer" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.stammnummer
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugFahrgestellnummer"
            class="col-5 col-lg-4"
            translate="label.fahrgestellnummer"></label>
          <label id="fahrzeugFahrgestellnummer" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.fahrgestellnummer
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label for="fahrzeugMarkeTyp" class="col-5 col-lg-4" translate="label.marketyp"></label>
          <label id="fahrzeugMarkeTyp" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.markeTyp
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label for="fahrzeugArt" class="col-5 col-lg-4" translate="label.fahrzeugart"></label>
          <label id="fahrzeugArt" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.fzArt | translateFahrzeugart
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugErstIvDatum"
            class="col-5 col-lg-4"
            translate="label.erstivdatum"></label>
          <label id="fahrzeugErstIvDatum" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.erstIVDatum | date: 'dd.MM.yyyy'
          }}</label>
        </div>
      </div>
      <div class="col-12" *ngIf="gegnerischesFahrzeug">
        <div class="row">
          <label class="col-5 col-lg-4" translate="label.landkennzeichen"></label>
          <label class="col-3 col-lg-4 font-weight-bold">{{
            fahrzeug?.land | translateLand
          }}</label>
          <label class="col-4 col-lg-4 font-weight-bold">{{ fahrzeug?.kennzeichen }}</label>
        </div>
      </div>
      <div class="col-12" *ngIf="!gegnerischesFahrzeug">
        <div class="row">
          <label
            for="fahrzeugKennzeichen"
            class="col-5 col-lg-4"
            translate="label.kennzeichen"></label>
          <label id="fahrzeugKennzeichen" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.kennzeichen
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label for="fahrzeugKmStand" class="col-5 col-lg-4" translate="label.kmstand"></label>
          <label id="fahrzeugKmStand" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.kmstand
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugDeckungsart"
            class="col-5 col-lg-4"
            translate="label.deckungsart"></label>
          <label id="fahrzeugDeckungsart" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.deckungsArt | translateDeckungsart
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugSchadensumme"
            class="col-5 col-lg-4"
            translate="label.schadensumme"></label>
          <label id="fahrzeugSchadensumme" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.schadensumme
          }}</label>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <label
            for="fahrzeugRegulierung"
            class="col-5 col-lg-4"
            translate="label.regulierung"></label>
          <label id="fahrzeugRegulierung" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.regulierung | translateRegulierung
          }}</label>
        </div>
      </div>
      <div class="col-12" *ngIf="gegnerischesFahrzeug">
        <div class="row">
          <label
            for="fahrzeugVersicherung"
            class="col-5 col-lg-4"
            translate="label.versicherung"></label>
          <label id="fahrzeugVersicherung" class="col-7 col-lg-8 font-weight-bold">{{
            fahrzeug?.versicherung?.name || fahrzeug?.versicherungsname
          }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
