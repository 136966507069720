import { inject } from '@angular/core';
import { CanDeactivateFn, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmService } from '../confirm-modal/confirm.service';
import { PendingChanges } from './pending-changes.interface';

export const PendingChangesGuard: CanDeactivateFn<PendingChanges> = (
  component: PendingChanges,
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const confirmService = inject(ConfirmService);
  const translateService = inject(TranslateService);

  if (component.hasPendingChanges()) {
    return new Promise<boolean>((resolve) => {
      confirmService
        .confirm({
          title: 'message.ungespeichertedatenwarnung.title',
          message: translateService.instant('message.ungespeichertedatenwarnung'),
          acceptText: 'label.verwerfern',
        })
        .then(
          () => {
            component.cancel();
            resolve(true);
          },
          () => {
            resolve(false);
          },
        );
    });
  }
  return true;
};
