import { Injectable } from '@angular/core';
import { SchadenmeldungDto } from '../core/api';
import { SchadenDetailNavigationSource } from './schaden-detail-navigation-source';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SchadenDetailService {
  private navigationSource: SchadenDetailNavigationSource;
  private meldungenForSchadendetails: SchadenmeldungDto[] = [];
  private selectedSchadendetailIndex: number = 0;

  public constructor(private router: Router) {}

  public setSchadenDetails(
    schadenmeldungen: SchadenmeldungDto[],
    navigationSource: SchadenDetailNavigationSource,
  ) {
    this.meldungenForSchadendetails = schadenmeldungen;
    this.navigationSource = navigationSource;
  }

  public getSelectedSchadenDetail() {
    if (
      this.meldungenForSchadendetails &&
      this.meldungenForSchadendetails.length > this.selectedSchadendetailIndex
    ) {
      return this.meldungenForSchadendetails[this.selectedSchadendetailIndex];
    }
    return undefined;
  }

  public back() {
    if (this.navigationSource === SchadenDetailNavigationSource.TOP_SCORE) {
      this.router.navigate(['/top-score-resultat']);
    } else if (this.navigationSource === SchadenDetailNavigationSource.POSTBOX) {
      this.router.navigate(['/postbox']);
    } else if (this.navigationSource === SchadenDetailNavigationSource.KONVERSATION) {
      this.router.navigate(['/konversation']);
    } else {
      this.router.navigate(['/resultate']);
    }
  }

  public deleteSelectedSchadenmeldung() {
    this.meldungenForSchadendetails.splice(this.selectedSchadendetailIndex, 1);
    if (this.selectedSchadendetailIndex > 0) {
      this.selectedSchadendetailIndex--;
    }
  }

  public updateSelectedSchadenmeldung(updatedSchadenmeldung: SchadenmeldungDto) {
    this.meldungenForSchadendetails.splice(
      this.selectedSchadendetailIndex,
      1,
      updatedSchadenmeldung,
    );
  }

  public hasNext() {
    return (
      this.meldungenForSchadendetails &&
      this.meldungenForSchadendetails.length > this.selectedSchadendetailIndex + 1
    );
  }

  public hasPrevious() {
    return this.meldungenForSchadendetails && this.selectedSchadendetailIndex > 0;
  }

  public next() {
    this.selectedSchadendetailIndex++;
    return this.getSelectedSchadenDetail();
  }

  public previous() {
    this.selectedSchadendetailIndex--;
    return this.getSelectedSchadenDetail();
  }

  public selectSchadenDetails(index: number) {
    this.selectedSchadendetailIndex = index;
  }

  public nextSchadenDetail() {
    this.selectedSchadendetailIndex++;
  }

  public previousSchadenDetail() {
    this.selectedSchadendetailIndex--;
  }
}
