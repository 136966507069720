import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BenutzerApiService, BenutzerDto, VersicherungDto } from '../../core/api';
import { NgForm, FormsModule } from '@angular/forms';
import { ConfirmService } from '../../core/confirm-modal/confirm.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import RollenEnum = BenutzerDto.RollenEnum;
import AnredeEnum = BenutzerDto.AnredeEnum;
import SpracheEnum = BenutzerDto.SpracheEnum;
import { ToastrService } from 'ngx-toastr';
import { Router, RouterLink } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { NgSelectModule } from '@ng-select/ng-select';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import AuthTypEnum = VersicherungDto.AuthTypEnum;
import { BenutzerService } from '../../core/benutzer/benutzer.service';
import { AuthorizationService } from '../../core/authentication/authorization.service';

@Component({
  selector: 'app-benutzer-form',
  templateUrl: './benutzer-form.component.html',
  styleUrls: ['./benutzer-form.component.scss'],
  standalone: true,
  imports: [FormsModule, NgFor, NgIf, NgSelectModule, RouterLink, TranslateModule, AsyncPipe],
})
export class BenutzerFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('benutzerForm', { static: true }) benutzerForm: NgForm;

  @Input() benutzer: BenutzerDto;
  @Input() editModus = false;
  @Input() createMode = false;
  @Output() submitCommand = new EventEmitter<BenutzerDto>();

  public alleVersicherungen: Array<VersicherungDto>;
  public versicherungen: Array<VersicherungDto>;
  public rollen: RollenEnum[] = [];
  public anreden: AnredeEnum[] = Object.entries(AnredeEnum).map(([, enumValue]) => enumValue);
  public sprachen: SpracheEnum[] = Object.entries(SpracheEnum).map(([, enumValue]) => enumValue);
  public rollenDisabled: boolean;
  private currentUser: BenutzerDto;
  private currentRole: RollenEnum;
  private subscriptionUser: Subscription;
  public isAuthTypeIdentityBroker: boolean = false;
  public isSVV = false;

  constructor(
    private benutzerApiService: BenutzerApiService,
    private benutzerService: BenutzerService,
    private authzService: AuthorizationService,
    private confirmService: ConfirmService,
    private toastr: ToastrService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    if (this.benutzer == null) {
      this.benutzer = <BenutzerDto>{};
    }

    this.isAuthTypeIdentityBroker = this.benutzer?.versicherung?.authTyp === AuthTypEnum.IDP;

    this.subscriptionUser = combineLatest([
      this.benutzerService.benutzer$,
      this.authzService.authority$,
      this.benutzerApiService.getVersicherungen(),
    ]).subscribe(([currentUser, currentRole, versicherungen]) => {
      this.currentUser = currentUser;
      this.currentRole = currentRole;
      this.resetAllowedRollen();
      this.isSVV = currentRole === RollenEnum.SVV;

      this.alleVersicherungen = versicherungen;
      this.filterAllowedVersicherungen();

      if (!this.editModus) {
        if (!this.isSVV) {
          this.benutzer.versicherung = currentUser.versicherung;
        }
        this.onVgChangeResetRollen();
      }
    });
  }

  title(): string {
    return this.editModus
      ? this.translate.instant('label.benutzerverwaltung.edit')
      : this.translate.instant('label.benutzerverwaltung.neu');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rollenDisabled = this.checkRollenDisabled();
    if (this.rollenDisabled) {
      this.rollen.push(this.currentRole);
    }
  }

  public filterAllowedVersicherungen(): void {
    if (this.currentRole === RollenEnum.SVV) {
      this.versicherungen = this.alleVersicherungen;
    } else {
      this.versicherungen = this.alleVersicherungen.filter(
        (v) => v.id === this.currentUser.versicherung.id,
      );
    }
  }

  public onVgChangeResetRollen() {
    this.resetAllowedRollen();

    // Reset defaults
    if (this.rollen.length === 1) {
      this.benutzer.rollen = this.rollen;
    } else {
      this.benutzer.rollen = [];
    }
  }

  private resetAllowedRollen(): void {
    let availableRollen = this.getAllowedRolesForCurrentUser();
    this.rollen = this.filterAllowedRolesForSelectedVersicherung(availableRollen);

    // The benutzer may only have roles that are allowed according to the selected VG.
    if (
      !this.benutzer?.rollen ||
      this.benutzer?.rollen.some((rolle) => this.rollen.indexOf(rolle) === -1)
    ) {
      this.benutzer.rollen = null;
    }
  }

  private getAllowedRolesForCurrentUser(): RollenEnum[] {
    if (this.currentRole == RollenEnum.SVV) {
      return [RollenEnum.SVV, RollenEnum.BVM, RollenEnum.VGADMIN, RollenEnum.SACHBEARBEITER];
    } else if (this.currentRole == RollenEnum.VGADMIN) {
      return [RollenEnum.BVM, RollenEnum.VGADMIN, RollenEnum.SACHBEARBEITER];
    } else if (this.currentRole == RollenEnum.BVM) {
      return [RollenEnum.BVM, RollenEnum.SACHBEARBEITER];
    } else {
      return [];
    }
  }

  private filterAllowedRolesForSelectedVersicherung(rolesToFilter: RollenEnum[]): RollenEnum[] {
    if (!this.benutzer?.versicherung?.typ) {
      return rolesToFilter;
    } else if (this.benutzer?.versicherung?.typ == VersicherungDto.TypEnum.SVV) {
      return [RollenEnum.SVV];
    } else {
      return rolesToFilter.filter((rolle) => rolle != RollenEnum.SVV);
    }
  }

  removeBenutzer() {
    this.confirmService
      .confirm({
        title: 'title.benutzerloeschen_warnung',
        message: this.translate.instant('label.benutzerloeschen_warnung', {
          benutzername: this.benutzer.email,
        }),
        acceptText: 'label.loeschen',
      })
      .then(
        () => {
          this.benutzerApiService.deleteBenutzer(this.benutzer.id).subscribe((res) => {
            this.toastr.success(
              this.translate.instant('message.benutzergeloescht', {
                benutzerid: this.benutzer.email,
              }),
            );
            this.router.navigate(['/benutzerverwaltung']);
          });
        },
        () => {
          // nothing to do, but prevent the default error handler from firing
        },
      );
  }

  submitForm() {
    this.submitCommand.emit(this.benutzer);
  }

  resetForm() {
    this.benutzerForm.reset();
  }

  compareFn(a, b): boolean {
    if (a != undefined && b != undefined) {
      return a.id === b.id;
    }
    return false;
  }

  checkRollenDisabled(): boolean {
    if (this.currentUser != undefined && this.benutzer != undefined) {
      return (
        this.currentRole != RollenEnum.SVV &&
        this.editModus &&
        this.currentUser.id == this.benutzer.id
      );
    }
    return false;
  }

  ngOnDestroy(): void {
    if (this.subscriptionUser) {
      this.subscriptionUser.unsubscribe();
    }
  }
}
