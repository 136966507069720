import { Injectable } from '@angular/core';
import { AttachmentDto, KonversationDto, NachrichtDto } from '../../core/api';
import { HttpClient } from '@angular/common/http';
import { executeFileDownload } from '../../core/util/file-save.service';
import { AppConfig } from '../../../app-config';

@Injectable({
  providedIn: 'root',
})
export class KonversationService {
  private nachrichten: NachrichtDto[];
  private konversation: KonversationDto;

  constructor(
    private httpClient: HttpClient,
    private appConfig: AppConfig,
  ) {}

  public setKonversation(konversation: KonversationDto, nachrichten: NachrichtDto[]) {
    this.nachrichten = nachrichten;
    this.konversation = konversation;
  }

  public getNachrichten() {
    return this.nachrichten;
  }

  public getKonversation() {
    return this.konversation;
  }

  public downloadAttachment(
    konversation: KonversationDto,
    nachricht: NachrichtDto,
    attachment: AttachmentDto,
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      // cannot use PostboxApiService because responseType blob has to be set.
      this.httpClient
        .get(
          this.appConfig.backendUrl +
            '/api/postbox/konversationen/' +
            konversation.id +
            '/nachrichten/' +
            nachricht.id +
            '/attachments/' +
            attachment.id,
          {
            observe: 'response' as 'body',
            responseType: 'blob' as 'json',
            withCredentials: true,
          },
        )
        .subscribe({
          next: (res) => {
            executeFileDownload(res, attachment.dateiname);
            resolve();
          },
          error: (error) => {
            resolve();
            throw error;
          },
        });
    });
  }
}
