<div class="container-fluid">
  <header>
    <nav class="navbar navbar-light navbar-expand-md d-flex flex-column">
      <div class="d-flex w-100 align-items-center">
        <a class="navbar-brand mr-auto" [routerLink]="['/abfragen']">
          <img src="assets/images/svvLogo.gif" width="291" height="71" alt="" loading="lazy" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <h2 translate class="application-title d-none d-lg-block">label.applicationtitle</h2>
      </div>

      <div class="navbar-nav ml-auto d-none d-sm-flex align-items-center">
        <!-- In case of a user has multiple roles, let the user choose the aktive rolle by the rollenauswahl view. -->
        <div class="nav-item" *ngIf="(benutzer$ | async)?.rollen.length > 1">
          <select
            id="rollen"
            name="rollen"
            [disabled]="(benutzer$ | async)?.rollen.length === 1"
            [ngModel]="rolle$ | async"
            (ngModelChange)="onAktiveRolleChange($event)">
            <option *ngFor="let rolle of (benutzer$ | async)?.rollen" [value]="rolle">
              {{ rolle | translateRolle }}
            </option>
          </select>
        </div>

        <!-- White text for Language Selection (visible on medium screens and larger) -->
        <ul class="nav-item d-none d-sm-block mb-0">
          <li class="nav-item active language-selection">
            <a (click)="changeLanguage('de')" translate>label.deutsch</a>
            |
            <a (click)="changeLanguage('fr')" translate>label.franzoesisch</a>
          </li>
        </ul>

        <!-- Blue text for Language Selection (visible on small screens) -->
        <ul class="navbar-nav ml-auto d-block d-sm-none blue">
          <li class="nav-item active language-selection">
            <a (click)="changeLanguage('de')" translate>label.deutsch</a>
            |
            <a (click)="changeLanguage('fr')" translate>label.franzoesisch</a>
          </li>
        </ul>
      </div>
    </nav>

    <nav class="navbar navbar-light navbar-expand-md pb-0 app-nav">
      <div class="collapse navbar-collapse justify-content-end row" id="navbarTogglerDemo01">
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-abfragen px-3 py-0"
          [routerLink]="['/abfragen']"
          *ngIf="authorized"
          translate
          >link.abfragen</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-administration cursor-pointer px-3 py-0"
          (click)="goToAdministration()"
          *ngIf="authorized"
          translate
          >link.administration</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-benutzerverwaltung px-3 py-0"
          [routerLink]="['/benutzerverwaltung']"
          *ngIf="authorized"
          translate
          >link.benutzerverwaltung</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-postbox px-3 py-0"
          [routerLink]="['/postbox']"
          *ngIf="authorized"
          translate
          >link.postbox</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-postbox px-3 py-0"
          [routerLink]="['/auswertungen']"
          *ngIf="authorized"
          translate
          >link.auswertungen</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-hilfe px-3 py-0"
          [routerLink]="['/hilfe']"
          *ngIf="authorized"
          translate
          >link.hilfe</a
        >
        <a
          class="col-12 col-md navbar-brand nav-ccinfo-item nav-logout cursor-pointer px-3 py-0"
          (click)="logout()"
          translate
          >link.logout</a
        >
      </div>
    </nav>
  </header>
</div>

<app-full-screen-spinner
  [working]="loading"
  [transparentForDurationMs]="50"></app-full-screen-spinner>
