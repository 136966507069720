import { Component } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hint',
  templateUrl: './hint.component.html',
  standalone: true,
  imports: [NgbPopover],
})
export class HintComponent {}
