import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { BenutzerApiService, BenutzerDto, VersicherungDto } from '../../core/api';
import { compare, SortableHeaderDirective, SortEvent } from '../../core/directives/sortable-header';
import { ConfirmService } from '../../core/confirm-modal/confirm.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateRollePipe } from '../../core/pipes/translate-rolle.pipe';
import { ToastrService } from 'ngx-toastr';
import { FullscreenSpinnerComponent } from '../../core/util/fullscreen-spinner/fullscreen-spinner.component';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, DatePipe, AsyncPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import AuthTypEnum = VersicherungDto.AuthTypEnum;
import { BenutzerService } from '../../core/benutzer/benutzer.service';

@Component({
  selector: 'app-benutzerliste',
  templateUrl: './benutzerliste.component.html',
  styleUrls: ['./benutzerliste.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    SortableHeaderDirective,
    TranslateModule,
    NgFor,
    NgIf,
    RouterLink,
    FullscreenSpinnerComponent,
    DatePipe,
    TranslateRollePipe,
    AsyncPipe,
  ],
})
export class BenutzerlisteComponent implements OnInit {
  public benutzerListe: Array<BenutzerDto>;
  public benutzer$ = this.benutzerService.benutzer$;
  public authTyp = AuthTypEnum;

  @ViewChildren(SortableHeaderDirective)
  private headers: QueryList<SortableHeaderDirective>;

  public loading = false;

  constructor(
    private benutzerService: BenutzerService,
    private benutzerApiService: BenutzerApiService,
    private confirmService: ConfirmService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    this.loadBenutzerlist();
  }

  removeBenutzer(benutzer: BenutzerDto) {
    this.confirmService
      .confirm({
        title: 'title.benutzerloeschen_warnung',
        message: this.translate.instant('label.benutzerloeschen_warnung', {
          benutzername: benutzer.email,
        }),
        acceptText: 'label.loeschen',
      })
      .then(
        () => {
          this.benutzerApiService.deleteBenutzer(benutzer.id).subscribe(() => {
            this.toastr.success(
              this.translate.instant('message.benutzergeloescht', {
                benutzerid: benutzer.email,
              }),
            );
            this.loadBenutzerlist();
          });
        },
        () => {
          // nothing to do, but prevent the default error handler from firing
        },
      );
  }

  loadBenutzerlist() {
    this.loading = true;
    this.benutzerApiService.getAll().subscribe(
      (res) => {
        this.benutzerListe = res;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        throw error;
      },
    );
  }

  public onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    });

    this.benutzerListe = this.benutzerListe.sort((benutzer1, benutzer2) => {
      let res: number;
      switch (sortEvent.column) {
        case 'identityBroker':
          res = compare(
            benutzer1.versicherung.authTyp === this.authTyp.IDP,
            benutzer2.versicherung.authTyp === this.authTyp.IDP,
          );
          break;
        default:
          res = compare(benutzer1[sortEvent.column], benutzer2[sortEvent.column]);
          break;
      }
      return sortEvent.direction === 'asc' ? res : -res;
    });
  }
}
