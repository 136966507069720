import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[scrollToBottom]',
  standalone: true,
})
export class ScrollToBottomDirective implements AfterViewInit {
  private element: HTMLElement;

  constructor(private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngAfterViewInit(): void {
    this.element.scrollTop = this.element.scrollHeight;
  }
}
