import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FahrzeugDto, IndividualSearchResultDto, SchadenmeldungDto } from '../core/api';
import { AbfrageResultatService } from './abfrage-resultat.service';
import { Router } from '@angular/router';
import { compare, SortableHeaderDirective, SortEvent } from '../core/directives/sortable-header';
import { SchadenDetailService } from '../schaden-detail/schaden-detail.service';
import { SchadenDetailNavigationSource } from '../schaden-detail/schaden-detail-navigation-source';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TranslateRegulierungPipe } from '../core/pipes/translate-regulierung.pipe';
import { TranslateSchadenartPipe } from '../core/pipes/translate-schadenart.pipe';
import { TranslateLandPipe } from '../core/pipes/translate-land.pipe';
import { TranslateFahrzeugartPipe } from '../core/pipes/translate-fahrzeugart.pipe';
import { NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
  selector: 'app-abfrage-resultat',
  templateUrl: './abfrage-resultat.component.html',
  styleUrls: ['./abfrage-resultat.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TranslateModule,
    SortableHeaderDirective,
    NgFor,
    DatePipe,
    TranslateSchadenartPipe,
    TranslateRegulierungPipe,
    TranslateFahrzeugartPipe,
    TranslateLandPipe,
  ],
})
export class AbfrageResultatComponent implements OnInit {
  public result: IndividualSearchResultDto;
  public sucheForSchaden = false;
  public tooManyResults = false;
  public rows: DisplayRow[] = [];

  @ViewChildren(SortableHeaderDirective)
  private headers: QueryList<SortableHeaderDirective>;

  constructor(
    private abfrageResultatService: AbfrageResultatService,
    private schadenDetailService: SchadenDetailService,
    private router: Router,
    private translate: TranslateService,
    private translateRegulierungPipe: TranslateRegulierungPipe,
    private translateSchadenartPipe: TranslateSchadenartPipe,
    private translateLandPipe: TranslateLandPipe,
  ) {}

  public ngOnInit(): void {
    this.result = this.abfrageResultatService.getIndividualSearchResult();
    this.sucheForSchaden = this.abfrageResultatService.isSucheForSchaden();

    if (!this.result || this.result.schadenmeldungen.length === 0) {
      this.router.navigate(['/abfragen']);
    } else {
      if (this.result.schadenmeldungen && this.result.schadenmeldungen.length > 100) {
        this.tooManyResults = true;
        this.result.schadenmeldungen.splice(this.result.schadenmeldungen.length - 1, 1);
      }
      this.prepareRowsToDisplay();
    }
  }

  private prepareRowsToDisplay() {
    // find the fahrzeug the user is looking for on every schadenmeldung
    this.rows = [];
    this.result.schadenmeldungen.forEach((schadenmeldung) => {
      this.rows.push({
        schadenmeldung: schadenmeldung,
        gesuchtesFahrzeug: this.findGesuchtesFahrzeug(schadenmeldung),
        fahrzeugRolle: this.findFahrzeugRolle(schadenmeldung),
      });
    });
  }

  public findGesuchtesFahrzeug(schadenmeldung: SchadenmeldungDto): FahrzeugDto {
    if (this.abfrageResultatService.isSucheForSchaden()) {
      return schadenmeldung.versichertesFahrzeug;
    } else {
      const stammnummerSearch = this.abfrageResultatService.getStammnummer();
      const fahrgestellnummerSearch = this.abfrageResultatService.getFahrgestellnummer();

      if (
        this.stammnummerAndFahrgestellnummerMatch(
          schadenmeldung.versichertesFahrzeug,
          stammnummerSearch,
          fahrgestellnummerSearch,
        )
      ) {
        return schadenmeldung.versichertesFahrzeug;
      } else {
        for (let i = 0; i < schadenmeldung.gegnerischeFahrzeuge.length; i++) {
          if (
            this.stammnummerAndFahrgestellnummerMatch(
              schadenmeldung.gegnerischeFahrzeuge[i],
              stammnummerSearch,
              fahrgestellnummerSearch,
            )
          ) {
            return schadenmeldung.gegnerischeFahrzeuge[i];
          }
        }
      }
    }
    return undefined;
  }

  private findFahrzeugRolle(schadenmeldung: SchadenmeldungDto) {
    if (
      this.stammnummerOrFahrgestellnummerMatch(
        schadenmeldung.versichertesFahrzeug,
        this.result.fahrzeugForDisplay,
      )
    ) {
      return 'vnFz';
    } else {
      for (let i = 0; i < schadenmeldung.gegnerischeFahrzeuge.length; i++) {
        if (
          this.stammnummerOrFahrgestellnummerMatch(
            schadenmeldung.gegnerischeFahrzeuge[i],
            this.result.fahrzeugForDisplay,
          )
        ) {
          return 'ugFz';
        }
      }
    }
    return '';
  }

  private stammnummerAndFahrgestellnummerMatch(
    fahrzeug: FahrzeugDto,
    stammnummerSearch: string,
    fahrgestellnummerSearch: string,
  ) {
    return (
      fahrzeug &&
      ((!!fahrzeug.stammnummer && fahrzeug.stammnummer === stammnummerSearch) ||
        (!!fahrzeug.fahrgestellnummer && fahrzeug.fahrgestellnummer === fahrgestellnummerSearch))
    );
  }

  private stammnummerOrFahrgestellnummerMatch(fahrzeug: FahrzeugDto, fahrzeugB: FahrzeugDto) {
    return (
      fahrzeug &&
      fahrzeugB &&
      ((!!fahrzeug.stammnummer &&
        !!fahrzeugB.stammnummer &&
        fahrzeug.stammnummer === fahrzeugB.stammnummer) ||
        (!!fahrzeug.fahrgestellnummer &&
          !!fahrzeugB.fahrgestellnummer &&
          fahrzeug.fahrgestellnummer === fahrzeugB.fahrgestellnummer))
    );
  }

  public displaySchadenDetails(index: number) {
    this.schadenDetailService.setSchadenDetails(
      this.result.schadenmeldungen,
      SchadenDetailNavigationSource.INDIVIDUAL_SEARCH,
    );
    this.schadenDetailService.selectSchadenDetails(index);
    this.router.navigate(['/schaden-details']);
  }

  public onSort(sortEvent: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== sortEvent.column) {
        header.direction = '';
      }
    });

    // we can't sort the schadenmeldungen because there are calculated values (for example fahrzeugRolle) by which we can sort too
    this.rows = this.rows.sort((rowA, rowB) => {
      const schadenmeldungA = rowA.schadenmeldung;
      const schadenmeldungB = rowB.schadenmeldung;
      let res = 0;
      switch (sortEvent.column) {
        case 'versicherung':
          res = compare(schadenmeldungA.versicherung?.name, schadenmeldungB.versicherung?.name);
          break;
        case 'schadenart':
          res = compare(
            this.translateSchadenartPipe.transform(schadenmeldungA.schadenart),
            this.translateSchadenartPipe.transform(schadenmeldungB.schadenart),
          );
          break;
        case 'regulierung':
          res = compare(
            this.translateRegulierungPipe.transform(rowA?.gesuchtesFahrzeug?.regulierung),
            this.translateRegulierungPipe.transform(rowB?.gesuchtesFahrzeug?.regulierung),
          );
          break;
        case 'land':
          res = compare(
            this.translateLandPipe.transform(rowA?.gesuchtesFahrzeug?.land),
            this.translateLandPipe.transform(rowB?.gesuchtesFahrzeug?.land),
          );
          break;
        case 'kennzeichen':
          res = compare(rowA?.gesuchtesFahrzeug?.kennzeichen, rowB?.gesuchtesFahrzeug?.kennzeichen);
          break;
        case 'kmstand':
          res = compare(rowA?.gesuchtesFahrzeug?.kmstand, rowB?.gesuchtesFahrzeug?.kmstand);
          break;
        case 'schadensumme':
          res = compare(
            rowA?.gesuchtesFahrzeug?.schadensumme,
            rowB?.gesuchtesFahrzeug?.schadensumme,
          );
          break;
        case 'fahrzeugRolle':
          res = compare(rowA?.fahrzeugRolle, rowB?.fahrzeugRolle);
          break;
        default:
          res = compare(schadenmeldungA[sortEvent.column], schadenmeldungB[sortEvent.column]);
          break;
      }
      return sortEvent.direction === 'asc' ? res : -res;
    });
    // after sorting the displayed rows, the schadenmeldungen in the store must be sorted the same way, so we can page through them on the detail pages
    this.result.schadenmeldungen = this.result.schadenmeldungen.sort((a, b) => {
      const displayedIndexOfA = this.rows.findIndex((r) => r.schadenmeldung.id === a.id);
      const displayedIndexOfB = this.rows.findIndex((r) => r.schadenmeldung.id === b.id);
      return compare(displayedIndexOfA, displayedIndexOfB);
    });
  }
}
class DisplayRow {
  schadenmeldung: SchadenmeldungDto;
  gesuchtesFahrzeug: FahrzeugDto;
  fahrzeugRolle: string;
}
