<h2>Token Info</h2>
<div class="alert alert-warning" role="alert">
  <strong>Warning!</strong> This is only for debug purposes
</div>

<h3>Keycloak Token</h3>
<div id="accordion">
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#accessToken"
        aria-expanded="false"
        aria-controls="accessToken">
        Access Token
      </button>
    </div>
    <div id="accessToken" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <pre>{{ accessToken | json }}</pre>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#idToken"
        aria-expanded="false"
        aria-controls="idToken">
        ID Token
      </button>
    </div>
    <div id="idToken" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <pre>{{ idToken | json }}</pre>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#userInfo"
        aria-expanded="false"
        aria-controls="userInfo">
        User Info
      </button>
    </div>
    <div id="userInfo" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <pre>{{ userInfo | json }}</pre>
      </div>
    </div>
  </div>
</div>

<h3 class="mt-5">External Token</h3>
<p>Enter info to display the external tokens</p>

<div class="form-group row">
  <label class="col-sm-2 col-form-label" for="authProviderId">Auth Provider ID</label>
  <input id="authProviderId" class="col-sm-4" [(ngModel)]="authProviderId" />
</div>
<p>
  Beispiele: <br />
  AXA: <code>axa</code><br />
  Mobiliar: <code>mobiliar</code><br />
  SVV: <code>svv</code><br />
</p>

<div class="form-group row">
  <label class="col-sm-2 col-form-label" for="userInfoUrl">UserInfo URL</label>
  <input id="userInfoUrl" class="col-sm-4" [(ngModel)]="userInfoUrl" />
</div>
<p>
  Beispiele: <br />
  Azure AD (bsp. SVV, Bedag): <code>https://graph.microsoft.com/oidc/userinfo</code><br />
  AXA: <code>https://login.acc.axa.ch/idp/userinfo.openid</code><br />
</p>

<button class="btn btn-primary" (click)="loadExternal()">Get External Token</button>

<div id="accordionExternal" class="mt-5">
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#accessTokenExternal"
        aria-expanded="false"
        aria-controls="accessToken">
        Access Token
      </button>
    </div>
    <div
      id="accessTokenExternal"
      class="collapse"
      aria-labelledby="headingOne"
      data-parent="#accordionExternal">
      <div class="card-body">
        <pre>{{ accessTokenExternal | json }}</pre>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#idTokenExternal"
        aria-expanded="false"
        aria-controls="idToken">
        ID Token
      </button>
    </div>
    <div
      id="idTokenExternal"
      class="collapse"
      aria-labelledby="headingTwo"
      data-parent="#accordionExternal">
      <div class="card-body">
        <pre>{{ idTokenExternal | json }}</pre>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-sm btn-link collapsed"
        data-toggle="collapse"
        data-target="#userInfoExternal"
        aria-expanded="false"
        aria-controls="userInfo">
        User Info
      </button>
    </div>
    <div
      id="userInfoExternal"
      class="collapse"
      aria-labelledby="headingThree"
      data-parent="#accordionExternal">
      <div class="card-body">
        <pre>{{ userInfoExternal | json }}</pre>
      </div>
    </div>
  </div>
</div>
