import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../../app-config';
import { SafePipe } from '../core/pipes/safe.pipe';

@Component({
  selector: 'app-hilfe',
  templateUrl: './hilfe.component.html',
  styles: ['iframe { height: 68vh; }'],
  standalone: true,
  imports: [SafePipe],
})
export class HilfeComponent {
  constructor(
    private translateService: TranslateService,
    private appConfig: AppConfig,
  ) {}

  public get benutzerhandbuchUrl(): string {
    if (this.translateService.currentLang === 'fr') {
      return this.appConfig.backendUrl + '/hilfe/U200_CC-Info_User_Manual_2024_v2.3_FR.pdf';
    } else {
      return this.appConfig.backendUrl + '/hilfe/U200_CC-Info_User_Manual_2024_v2.3_DE.pdf';
    }
  }
}
