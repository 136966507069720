import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BenutzerApiService, BenutzerDto } from '../api';
import { Observable } from 'rxjs';

export const UserByIdResolver = (route: ActivatedRouteSnapshot): Observable<BenutzerDto> => {
  const benutzerApiService = inject(BenutzerApiService);
  const userId = parseInt(route.params['id']);
  return benutzerApiService.getBenutzer(userId);
};
